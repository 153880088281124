import React from 'react';
import $ from 'jquery';
import MaskedInput from 'react-text-mask';
import { Button, Container, Row, Col, Card, Form, Alert, Spinner, InputGroup, Table } from "react-bootstrap";
import { validCPF, validDate, validEmail, stringToDate } from '../../../prototypes.js';
import { regionaisService, estadoService, formacaoService, cadastroService, financeiroService } from '../../../_services';
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { formatDate, parseDate } from '../../../_helpers';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { handleAuthResponse, handleResponse } from '../../../_helpers/handle-response.js';

toastr.options = {
    positionClass: 'toast-top-center',
    hideDuration: 300,
    timeOut: 3000
};

export function AdminAssociadoForm() {
    const [loading, setLoading] = React.useState(false);
    const [estados, setEstados] = React.useState([]);
    const [formacoes, setFormacoes] = React.useState([]);
    const [regionais, setRegionais] = React.useState([]);
    const [associado, setAssociado] = React.useState({});
    const [pagamentos, setPagamentos] = React.useState([]);

    const navigate = useNavigate();
    const params = useParams();

    React.useEffect(function () {
        getEstados();
        getFormacoes();
        getRegionais();
        if (params.id !== undefined) {
            const ID = params.id;
            cadastroService.getById(ID).then(r => handleAuthResponse(r, navigate))
                .then(data => {
                    Object.keys(data).forEach(key => (  (data[key] === undefined || data[key] === null)) ? data[key] = '' : {});

                    setAssociado(data);
                    setAssociado((a) => {
                        return a = {
                            ...a, dataNcto: formatDate(data.dataNcto)
                            , validade: formatDate(data.validade)
                            , dataReg: formatDate(data.dataReg)
                            , atualizado: formatDate(data.atualizado)
                        }
                    }); 
                }).catch(ex => { 
                    toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
                });

            financeiroService.listByCadastro(ID).then(rr => handleAuthResponse(rr, navigate))
                .then(dd => {
                    setPagamentos(dd);
                });
        }
    }, [params.id]);

    const getEstados = function () {
        estadoService.list().then(data => {
            setEstados(data)
        }).catch((ex) => {
            toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
        });
    }

    const getFormacoes = function () {
        formacaoService.list().then(data => {
            setFormacoes(data)
        }).catch((ex) => {
            toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
        });
    }

    const getRegionais = function () {
        regionaisService.list().then(data => {
            setRegionais(data)
        }).catch((ex) => {
            toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
        });
    }

    const handleChange = function (event) {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        var name = event.target.name;
        setAssociado((a) => {
            return a = { ...a, [name]: value }
        });
    }

    const handleChangeMaskOnlyNumbers = function (event) {
        const value = event.target.value.replace(/[^\d]/g, '');;
        var name = event.target.name;
        setAssociado((a) => {
            return a = { ...a, [name]: value }
        });
    }

    const erroValida = function (event, campo, msg) {
        alert(msg);
        $('html,body').animate({ scrollTop: $('#' + campo).offset().top - 200 }, 'slow');
        document.getElementById(campo).focus();
        event.preventDefault();
        return false;
    }

    const handleSubmit = function (event) {

        event.preventDefault();
        // Valida campos
        var form = document.getElementById('cadastro');
        if (form.checkValidity() === false) {
            event.stopPropagation();
            form.classList.add('was-validated');
            $('.main-panel').animate({ scrollTop: $('.was-validated .form-control:invalid').offset().top - 200 }, 'slow');
            return;
        }

        // data de nascimento
        if (associado.dataNcto.replace(/[^\d]/g, '').length !== 8 || !validDate(associado.dataNcto)) {
            return erroValida(event, 'dataNcto', 'Informe corretamente a Data de Nascimento!');
        }
        // Celular
        if (associado.fone2.length !== 11) {
            return erroValida(event, 'celular', 'Informe corretamente o celular!');
        }

        // CEP
        if (associado.cep.length !== 8) {
            return erroValida(event, 'cep', 'Informe corretamente o CEP!');
        }

        setLoading(true);
        var req = {
            ...associado, 
            validade: associado.validade && parseDate(associado.validade),
            dataNcto: parseDate(associado.dataNcto)
        };
        cadastroService.update(req).then(r => handleResponse(r)).then(res => {
            toastr.success('Dados atualizados com sucesso.');
            setLoading(false);
        }).catch(function () {
            setLoading(false);
            toastr.error('Ocorreu um erro ao salvar os dados. Por favor tente novamente.');
        });
    }

    return (
        associado &&
        <>
            <div className="register-body">

                <div className="register-wrapper">
                    <Container className="vertical-center">
                        <Row>
                            <Col className="ml-auto mr-auto" lg="12">
                                <Card className="card-register">
                                    <Card.Header style={{ color: 'black' }}>
                                        <Card.Title className="register-form-title">Cadastro de Associado</Card.Title>
                                        <div style={{ float: 'right' }}>
                                            <NavLink className="btn btn-primary btn-sm" to="/admin/associados">
                                                Voltar
                                            </NavLink>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Form id="cadastro" name="cadastro" method="POST" noValidate onSubmit={handleSubmit}>
                                            <Row>
                                                <Col className="pr-1" md={7}>
                                                    <label>* Nome Completo:</label>
                                                    <Form.Control type="text" placeholder="Nome" maxLength="60" id="nome" name="nome" required
                                                        value={associado.nome}
                                                        onChange={handleChange}
                                                        style={{ background: 'rgb(255, 255, 255) none repeat scroll 0% 0%' }} />
                                                    <div className="invalid-feedback">
                                                        Por favor informe seu nome!
                                                    </div>
                                                </Col>
                                                <Col className="pr-1" md={5}>
                                                    <label>* CPF:</label>
                                                    <MaskedInput id="cpf" placeholder="CPF" name="cpf" mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]} className="form-control numeric" required
                                                        value={associado.cpf} onChange={handleChangeMaskOnlyNumbers}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                    <div className="invalid-feedback">
                                                        Por favor informe o CPF!
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md={5}>
                                                    <label>* Endereço:</label>
                                                    <input type="text" placeholder="Endereço" className="form-control" maxLength="100" name="ender" id="ender" required
                                                        style={{ background: 'rgb(255, 255, 255) none repeat scroll 0% 0%' }}
                                                        value={associado.ender} onChange={handleChange} />
                                                    <div className="invalid-feedback">
                                                        Por favor preencha o endereço!
                                                    </div>
                                                </Col>
                                                <Col md={2}>
                                                    <label>* Número:</label>

                                                    <input type="text" id="num" name="num" className="form-control numeric" maxLength="8" required aria-describedby="numSm"
                                                        value={associado.num} onChange={handleChange}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} /> &nbsp;
                                                    <div className="invalid-feedback">
                                                        Por favor informe o número do endereço!
                                                    </div>
                                                    <small id="numSm" className="text-muted">Somente números</small>

                                                </Col>
                                                <Col md={5}>
                                                    <label htmlFor="complemento">Complemento:</label>
                                                    <input type="text" className="form-control upper" maxLength="254" placeholder="Complemento"
                                                        name="compl" id="compl" aria-describedby="complSm"
                                                        value={associado.compl} onChange={handleChange} />
                                                    &nbsp;
                                                    <small id="complSm" className="text-muted"> Apto, Sala, Conj., etc... </small>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={5}>
                                                    <label htmlFor="bairro">* Bairro:</label>
                                                    <input type="text" maxLength="50" placeholder="Bairro" className="form-control upper" name="bairro" id="bairro" required
                                                        value={associado.bairro} onChange={handleChange} />
                                                    <div className="invalid-feedback">
                                                        Por favor informe o bairro!
                                                    </div>
                                                </Col>
                                                <Col md={2}>
                                                    <label>* Estado:</label>
                                                    <Form.Select name="uf" id="uf" size="1" className="form-control" required
                                                        value={associado.uf}
                                                        onChange={handleChange}>
                                                        <option value="">Selecione</option>
                                                        {estados.map(e => (
                                                            <option key={e.id} value={e.sigla} >
                                                                {e.sigla}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                    <div className="invalid-feedback">
                                                        Por favor selecione o estado!
                                                    </div>
                                                </Col>
                                                <Col md={5}>
                                                    <label>* Cidade:</label>
                                                    <input type="text" className="form-control" maxLength="100" id="cidade" name="cidade"
                                                        value={associado.cidade} onChange={handleChange} />
                                                    <div className="invalid-feedback">
                                                        Por favor selecione a cidade!
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <label>* CEP:</label>
                                                    <MaskedInput id="cep" placeholder="CEP" name="cep" mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]} className="form-control numeric" required
                                                        value={associado.cep} onChange={handleChangeMaskOnlyNumbers}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} />
                                                    <div className="invalid-feedback">
                                                        Por favor informe o CEP!
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <label htmlFor="pais">* País:</label>

                                                    <input type="text" className="form-control upper" placeholder="País" maxLength="80" id="pais" name="pais" required
                                                        value={associado.pais} onChange={handleChange} />
                                                    <div className="invalid-feedback">
                                                        Por favor informe o país!
                                                    </div>
                                                </Col>
                                                <Col md={5}>
                                                    <label htmlFor="email">* E-Mail:</label>
                                                    <InputGroup className="form-group-no-border">
                                                        <InputGroup.Text>
                                                            <i className="nc-icon nc-email-85" />
                                                        </InputGroup.Text>
                                                        <input type="email" className="form-control lower" placeholder="E-Mail" maxLength="100" id="email" name="email" required
                                                            value={associado.email} onChange={handleChange} />
                                                        <div className="invalid-feedback">
                                                            Por favor informe o e-mail!
                                                        </div>
                                                    </InputGroup>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <label>* Celular:</label>
                                                    <InputGroup className="form-group-no-border">
                                                        <InputGroup.Text>
                                                            <i className="pe-7s-call" />
                                                        </InputGroup.Text>
                                                        <MaskedInput type="text" placeholder="Celular" className="form-control" id="fone2" name="fone2" required aria-describedby="celSm"
                                                            mask={['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                            value={associado.fone2} onChange={handleChangeMaskOnlyNumbers}
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }} /> &nbsp;
                                                        <div className="invalid-feedback">
                                                            Por favor informe o número de celular.
                                                        </div>
                                                    </InputGroup>

                                                    <small id="celSm" className="text-muted">
                                                        Digite XX 0 XXXX-XXXX se for DDD + 8 dígitos
                                                    </small>
                                                </Col>
                                                <Col md={4}>
                                                    <label>* Data de Nascimento:</label>
                                                    <InputGroup className="form-group-no-border">
                                                        <InputGroup.Text>
                                                            <i className="pe-7s-date" />
                                                        </InputGroup.Text>
                                                        <MaskedInput type="text" className="form-control" name="dataNcto" id="dataNcto" required placeholder="01/01/0000"
                                                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                            value={associado.dataNcto} onChange={handleChange}
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }} />
                                                        <div className="invalid-feedback">
                                                            Por favor preencha a data de nascimento.
                                                        </div>
                                                    </InputGroup>

                                                </Col>
                                                <Col md={4}>
                                                    <label>* Sexo:</label>
                                                    <div>
                                                        <Form.Check inline name="sexo" id="masc" onChange={handleChange} value="1" label="Masculino"
                                                            type="radio" checked={String(associado.sexo) === '1'} />
                                                        <Form.Check inline name="sexo" id="fem" onChange={handleChange} value="0" label="Feminino"
                                                            type="radio" checked={String(associado.sexo) === '0'} />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <label>CRF:</label>
                                                    <input className="form-control crf numeric" placeholder="CRF" type="text" maxLength="20" id="crf" name="crf"
                                                        value={associado.crf} onChange={handleChange}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} />
                                                </Col>
                                                <Col md={2}>
                                                    <label> &nbsp; UF: &nbsp; </label>
                                                    <Form.Select name="crf_uf" id="crf_uf" className="form-control"
                                                        value={associado.crf_uf} onChange={handleChange}>
                                                        <option >--</option>
                                                        {estados.map(e => (
                                                            <option key={e.id} value={e.sigla} >
                                                                {e.sigla}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <div className="title"><h4 className="register-form-subtitle">Dados Profissionais</h4></div>
                                                    <label>* Formação Profissional:</label>
                                                    <Form.Select id="formacao" name="formacao" className="form-control" required
                                                        value={associado.formacao} onChange={handleChange}>
                                                        <option value="">Selecione</option>
                                                        {formacoes.map(e => (
                                                            <option key={e.id} value={e.formacao} >
                                                                {e.formacao}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                    <div className="invalid-feedback">
                                                        Por favor selecione a formação.
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={8}>
                                                    <label>Nome da Instituição:</label>
                                                    <input type="text" className="form-control" maxLength="100" id="instituicao" name="instituicao"
                                                        value={associado.instituicao} onChange={handleChange} />
                                                </Col>
                                                <Col md={4}>
                                                    <label>Telefone da Instituição:</label>
                                                    <MaskedInput type="text" className="form-control fone" id="hfone1" name="hfone1"
                                                        mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/,]}
                                                        value={associado.hfone1} onChange={handleChangeMaskOnlyNumbers} aria-describedby="smFoneInst"
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} /> &nbsp;
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <div className="title"><h4 className="register-form-subtitle">Dados Confidenciais</h4></div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}>
                                                    <label>Cadastro ID: </label><br />
                                                    <label className="text-info">{String(associado.idCadastro).padStart(6, '0')}</label>
                                                </Col>
                                                <Col md={2}>
                                                    <label>Senha: </label><br />
                                                    <label className="text-info">{associado.senha}</label>
                                                </Col>
                                                <Col md={2}>
                                                    <label>Cadastrado em:</label><br />
                                                    <label className="text-info">{associado.dataReg}</label>
                                                </Col>
                                                <Col md={2}>
                                                    <label>Atualizado em:</label><br />
                                                    <label className="text-info">{associado.atualizado}</label>
                                                </Col>
                                                <Col md={2}>
                                                    <label>Válido até:</label>
                                                    <input type="text" className="form-control form-control-sm" maxLength="100" id="validade" name="validade"
                                                        value={associado.validade} onChange={handleChange} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <label>Status: </label>
                                                    <Form.Select id="status" name="status" className="form-control"
                                                        value={associado.status} onChange={handleChange}>
                                                        <option value="-1">Cancelado</option>
                                                        <option value="0">Inativo</option>
                                                        <option value="1">Ativo</option>
                                                        <option value="2">Dir. Regional Atual</option>
                                                        <option value="3">Pres. Regional Atual</option>
                                                        <option value="4">Dir. Nacional Atual</option>
                                                        <option value="5">Pres. Nacional Atual</option>
                                                        <option value="6">Administrativo</option>
                                                    </Form.Select>
                                                </Col>
                                                <Col md={4}>
                                                    <label>Regional: </label>
                                                    <Form.Select id="regional" name="regional" className="form-control"
                                                        value={associado.regional} onChange={handleChange}>
                                                        <option value="-1">Nenhuma selecionada</option>
                                                        {regionais.map(e => (
                                                            <option key={e.id} value={String(e.uf).toUpperCase()}>{e.uf} - {e.regional}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Col>
                                                <Col md={4}>
                                                    <label>Nível de Acesso: </label>
                                                    <Form.Select id="nivelAcesso" name="nivelAcesso" className="form-control"
                                                        value={associado.nivelAcesso} onChange={handleChange}>
                                                        <option value="0">Somente Associado</option>
                                                        <option value="3">Somente Regional</option>
                                                        <option value="5">Diretoria</option>
                                                        <option value="6">Administrativo</option>
                                                    </Form.Select>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12}>
                                                    <center>
                                                        <Button id="btSend" name="btSend" className="btn-round btn-fill btn-primary" color="primary" type="submit">
                                                            Atualizar Cadastro
                                                            {loading &&
                                                                <> &nbsp;
                                                                    <Spinner animation="border" role="status" size="sm">
                                                                        <span className="visually-hidden">Carregando...</span>
                                                                    </Spinner> </>}
                                                        </Button>
                                                    </center>
                                                </Col>
                                            </Row>
                                        </Form>
                                        <Card >
                                            <Card.Header>
                                                <Card.Title as="h4"> Histórico de Pagamentos</Card.Title>

                                            </Card.Header>
                                            <Card.Body className="table-responsive px-0">
                                                <Row>
                                                    <Col md={12}>

                                                        <Table className="table-hover table-striped" style={{ fontSize: 'smaller' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th className="border-0">Num.</th>
                                                                    <th className="border-0">Emissão</th>
                                                                    <th className="border-0">Valor</th>
                                                                    <th className="border-0">Vencimento</th>
                                                                    <th className="border-0">Histórico</th>
                                                                    <th className="border-0">Pgto.</th>
                                                                    <th className="border-0">Opções</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    pagamentos.length > 0 &&
                                                                    pagamentos.map(e => (
                                                                        <tr key={e.idFinanceiro}>
                                                                            <td>{e.numDoc}</td>
                                                                            <td>{formatDate(e.dataLct)}</td>
                                                                            <td>{e.valor && e.valor.toFixed(2)}</td>
                                                                            <td>{formatDate(e.vcto)}</td>
                                                                            <td>{e.historico}</td>
                                                                            <td>{formatDate(e.pgto)}</td>
                                                                            <td><Button className="btn-simple p-1 info" to={'/'} >
                                                                                Baixar
                                                                            </Button> | <Button className="btn-simple p-1 info" to={'/'} >
                                                                                    Enviar
                                                                                </Button> </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </Table>

                                                        <center>
                                                            <Button className="btn btn-warning p-1 info" to={'/'} >
                                                                Gerar Boleto para Renovação
                                                            </Button>
                                                        </center>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}