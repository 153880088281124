import React from 'react';
import { Container, Row, Col, Card, Table, Form, Alert, Button, Spinner } from "react-bootstrap";
import { authenticationService, cadastroService, pagseguroService, stripeService } from "../../_services";
import { handleAuthResponse, formatDate, formatFone, formatCPF, parseDateISO } from '../../_helpers';
import { NavLink } from "react-router-dom";
import toastr from 'toastr';

export function PagarAnuidade() {
    const [associado, setAssociado] = React.useState(null);
	const [loading, setLoading] = React.useState(false);

    React.useEffect(function () {
        const currentUser = authenticationService.loggedUser;

        cadastroService.getById(currentUser.id).then(handleAuthResponse).then(u => {
            setAssociado(u);
        }).catch(err => {
            toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
        });

        const script = document.createElement('script');
        script.src = 'https://sdk.mercadopago.com/js/v2';
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    const situacao = function () {
        if (associado.validade) {
            const val = parseDateISO(associado.validade);
            const now = new Date();
            if (val > now) {
                return 'ATIVO - Sua inscrição vence em ' + formatDate(associado.validade);
            }
        }
        return 'VENCIDA';
    }
    const handleSubmit = function (event) {
        event.preventDefault();

        var req = {
            redirectURL: 'https://' + window.location.hostname + '/associados/area',
            idAssociado: associado.idCadastro,
        }
        pagseguroService.createAnuidade(req).then(async (res) => {
            var url = await res.json();
            window.location.href = url;
        }).catch(function () {
            toastr.error('Ocorreu um erro ao salvar os dados. Por favor tente novamente.');
        });
    }

    const handleSubmitStripe = function (event) {
        event.preventDefault();

        setLoading(true);
        var req = {
            redirectURL: 'https://' + window.location.hostname + '/associados/area',
            idAssociado: associado.idCadastro,
        }
        stripeService.createAnuidade(req).then(async (res) => {
            var url = await res.json();
            window.location.href = url;
        }).catch(function () {
            toastr.error('Ocorreu um erro. Por favor tente novamente.');
            setLoading(false);
        });
    }

    return (associado && <>
        <div className="register-body">
            <div className="register-wrapper" style={{ width: '100%' }}>
                <Container className="vertical-center">
                    <Row>
                        <Col className="ml-auto mr-auto" lg="12">
                            <Card className="card-register">
                                <Card.Header style={{ color: 'black' }}>
                                    <Card.Title as="h2" className="text-warning">
                                        Renovação de Inscrição
                                        <div style={{ float: 'right' }}>
                                            <NavLink className="btn btn-primary btn-sm" to="/associados/area">
                                                Voltar
                                            </NavLink>
                                        </div></Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col className="pr-1" md={12}>
                                            <Alert show variant="info">
                                                Antes de renovar sua inscrição, verifique no link 'Atualizar meu Cadastro' e confira seus dados. Corrija o que achar
                                                necessário e após gravar as alterações clique em Histórico de pagamento. O valor para renovação é de R$ 175,00 para
                                                FARMACÊUTICOS PROFISSIONAIS ou R$ 75,00 para EST. GRADUAÇÃO: FARMÁCIA.
                                            </Alert>
                                            <Alert show variant="danger">
                                                <p><b>EST. PÓS-GRADUAÇÃO: FARMÁCIA já são formados portanto, devem pagar como FARMACÊUTICOS PROFISSIONAIS.</b></p>
                                                <p><b>Para EST. GRADUAÇÃO: FARMÁCIA é necessário o envio de cópia de documento de comprovação da matrícula para atendimento@sbrafh.org.br.</b></p>
                                            </Alert>
                                            <Table className=" table-striped small table-small" >
                                                {associado && <>
                                                    <tbody >
                                                        <tr >
                                                            <td>Nome: </td>
                                                            <td>{associado.nome}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>E-mail: </td>
                                                            <td>{associado.email}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Fone: </td>
                                                            <td>{formatFone(associado.fone)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Celular: </td>
                                                            <td>{formatFone(associado.fone2)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Nº de Inscrição: </td>
                                                            <td>{String(associado.idCadastro).padStart(6, '0')}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Valor Anuidade: </td>
                                                            <td>R$ {associado.valor.toFixed(2)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Validade: </td>
                                                            <td>{associado.validade && formatDate(associado.validade)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Situação: </td>
                                                            <td>{situacao()}</td>
                                                        </tr>
                                                    </tbody>
                                                </>}
                                            </Table>
                                            Dados para Cobrança:<br />
                                            <Table className="table-striped small table-small" >
                                                {associado && <>
                                                    <tbody>
                                                        <tr>
                                                            <td>CPF: </td>
                                                            <td>{formatCPF(associado.cpf)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Ender.: </td>
                                                            <td>{associado.ender}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Compl.: </td>
                                                            <td>{associado.compl}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Bairro: </td>
                                                            <td>{associado.bairro}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Cidade: </td>
                                                            <td>{associado.cidade}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>CEP: </td>
                                                            <td>{associado.cep}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Estado: </td>
                                                            <td>{associado.uf}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Valor R$: </td>
                                                            <td>{associado.valor.toFixed(2)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Descrição: </td>
                                                            <td>TAXA ANUIDADE DE ASSOCIADO SBRAFH</td>
                                                        </tr>
                                                    </tbody>
                                                </>}
                                            </Table>
                                        </Col>
                                        <Row>
                                            <Col lg={12}>
                                                <center>
                                                    <Form id="cadastro" name="cadastro" method="POST" noValidate>
                                                        <Alert show variant="success">
                                                            Clique na imagem do PAGSEGURO para efetuar seu pagamento:
                                                            <hr />
                                                            <input type="image" src={require('../../../src/assets/img/pagar.gif')}
                                                                name="submit" alt="Pague com o PagSeguro - é rápido, grátis e seguro!" onClick={handleSubmit} />
                                                        </Alert> 
                                                            <Alert show variant="info"> 
                                                                Ou clique no botão abaixo para pagar via Stripe:
                                                                <hr />
                                                                <Button className="btn-fill btn-success" onClick={handleSubmitStripe}>
                                                                    Pagar via Stripe
                                                                    {loading &&
                                                                    <> &nbsp;
                                                                        <Spinner animation="border" role="status" size="sm">
                                                                            <span className="visually-hidden">Carregando...</span>
                                                                        </Spinner> </>}
                                                                </Button> 
                                                            </Alert> 
                                                    </Form>
                                                </center>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Alert show variant="warning">
                                                    ATENÇÃO: No parcelamento do PAGSEGURO serão aplicados os juros da sua operadora de cartões de crédito.
                                                    Assim que selecionar a bandeira do seu cartão, verifique o valor das parcelas antes de efetuar o seu pagamento.
                                                    Caso não queira pagar os juros da sua operadora, selecione uma parcela ou utilize a opção BOLETO.
                                                </Alert>
                                                <Alert show variant="danger">
                                                    <b>IMPORTANTE: Pagamento com boleto demora em média 2 (dois) dias úteis para liberação do cadastro</b>
                                                </Alert>
                                            </Col>
                                        </Row>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    </>
    );
}