import React, {useCallback} from 'react';
import $ from 'jquery';
import MaskedInput from 'react-text-mask';
import { Button, Container, Row, Col, Card, Form, Alert, Spinner, Modal, InputGroup, Table } from "react-bootstrap"; 
import toastr from 'toastr';
import { validCPF } from '../../../prototypes.js';
import { estadoService, pagseguroService, inscricoesCursosService } from '../../../_services';
import { formatDate, formatMoney, formatCPF, parseDateISO } from '../../../_helpers';
import { ModalConfirm } from '../../../components/Modals';
import { compareAsc } from 'date-fns';

export function FormInscricao({ associado, curso, inscricaoExistente }) {
    const [loading, setLoading] = React.useState(false);
    const [estados, setEstados] = React.useState([]);
    const [origens, setOrigens] = React.useState([]);
    const [valorInscricao, setValorInscricao] = React.useState(9999999.0);
    const [inscricao, setInscricao] = React.useState({
        cel: '',
        cep: '',
        cidade: '',
        bairro: '',
        compl: '',
        cpf: '',
        crf: '',
        curso: '',
        data: '',
        email: '',
        ender: '',
        enderInst: '',
        fila: 0,
        fone: '',
        foneInst: '',
        formacao: '',
        instituicao: '',
        nome: '',
        obs: '',
        origem: '',
        regional: '',
        status: 0,
        tipoInstituicao: '',
        idCadastro: 0,
        uf: '',
        valor: 0.0,
    });
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [showModalSucesso, setShowModalSucesso] = React.useState(false);
    const [modalSucessoMsg, setModalSucessoMsg] = React.useState('');
    const [showPagseguro, setShowPagseguro] = React.useState(false);
 
    React.useEffect(function () {
        getEstados();

        if (inscricaoExistente) {
            setInscricao(inscricaoExistente);
            setValorInscricao(inscricaoExistente.valor);
            document.getElementById('checkbox').setAttribute('checked', 'checked');
            document.getElementById('checkbox2').setAttribute('checked', 'checked');
        } else

        if (associado) {
            Object.keys(associado).forEach(key =>
                (inscricao[key] !== undefined) ?
                    setInscricao((ins) => {
                        return ins = {
                            ...ins,
                            [key]: associado[key],
                        }
                    })
                    : {}
            );

            // -1 = não associado
            var status = -1; 
            if (associado.status >= 0) {
                status = associado.status;
            }
            
            setInscricao(function (ins) {
                return ins = {
                    ...ins,
                    tipo: 'PARTICIPANTE',
                    cel: associado.fone2,
                    status: status,
                };
            });

            if (associado.status > 0) {
                setValorInscricao(curso.valorAssProf);
            } else {
                setValorInscricao(curso.valorNAssProf);
            }
        } 

        return function () {
            document.body.classList.remove("register-page");
        }
    }, []);

    React.useEffect(function () {
        setInscricao(function (ins) {
            return ins = {
                ...ins,
                valor: valorInscricao
                , curso: curso.titulo
                , regional: curso.regional
                , data: curso.data
            };
        });
    }, [inscricao.valor]);

    const getEstados = function () {
        estadoService.list().then(data => {
            setEstados(data)
        }).catch((ex) => {
            console.log(ex);
        });
    }

    const handleChange = function (event) {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        var name = event.target.name;
        setInscricao((a) => {
            return a = { ...a, [name]: value }
        });
    }

    const handleChangeFormacao = function (event) {
        const value = event.target.value;
        const ativo = associado.status > 0;
        var valor = curso.valorNAssProf;
        if (value === 'EST. GRADUAÇÃO: FARMÁCIA') {
            valor = (ativo) ? curso.valorAssEstG : curso.valorNAssEstG;
        } else {
            valor = (ativo) ? curso.valorAssProf : curso.valorNAssProf;
        }
        setValorInscricao(valor);

        setInscricao((ins) => {
            return ins = { ...ins, formacao: value, valor: valor }
        });
    }

    const handleChangeMaskOnlyNumbers = function (event) {
        const value = event.target.value.replace(/[^\d]/g, '');;
        var name = event.target.name;
        setInscricao((a) => {
            return a = { ...a, [name]: value }
        });
    }

    const erroValida = function (event, campo, msg) {
        alert(msg);
        $('html,body').animate({ scrollTop: $('#' + campo).offset().top - 200 }, 'slow');
        document.getElementById(campo).focus();
        event.preventDefault();
        return false;
    }

    const handleSubmit = function (event) {
        event.preventDefault();
        // Valida campos
        var form = document.getElementById('cadastro');
        if (form.checkValidity() === false) {
            event.stopPropagation();
            form.classList.add('was-validated');
            try {
                $('html,body').animate({ scrollTop: $('.was-validated .form-control:invalid').offset().top - 200 }, 'slow');
            } catch { }
            return;
        }

        // Valida o CPF
        if (!validCPF(associado.cpf)) {
            return erroValida(event, 'cpf', 'Informe corretamente o CPF!');
        }

        if (origens.length <= 0) {
            return erroValida(event, 'origem-0', "Como ficou sabendo deste evento?\n\nSelecione pelo menos uma opção");
        }

        setShowConfirm(true);
    }

    const onConfirm = function (ID) {
        setShowConfirm(false);

        const req = {
            ...inscricao,
            origem: origens.join(', '),
            dataReg: new Date().toISOString(),
        };
        setLoading(true);

        // Alterando inscrição
        if (inscricaoExistente) { 
            inscricoesCursosService.update(req).then(function (res) { return res.json(); }).then(function (res) {
                setInscricao(res); 
                sucesso(res);
            }).catch(function () {
                toastr.error('Ocorreu um erro, por favor tente novamente.');
            }).then(function () {
                setLoading(false);
            });
        }
        else {
            inscricoesCursosService.create(req).then(function (res) { return res.json(); }).then(function (res) { 
                setInscricao(res); 
                sucesso(res);
            }).catch(function () {
                toastr.error('Ocorreu um erro, por favor tente novamente.');
            }).then(function () {
                setLoading(false);
            });
        }
    }

    const sucesso = function (inscr) {
        if (valorInscricao > 0) {  
            const hoje = new Date();
            const val = parseDateISO(curso.dataMaxima);
            const res = compareAsc(Date.UTC(hoje.getFullYear(), hoje.getMonth(), hoje.getDate())
                , Date.UTC(val.getFullYear(), val.getMonth(), val.getDate()));
        
            if (res > 0) {
                setModalSucessoMsg(`Prezado(a) ${inscr.nome},\nsua inscrição foi enviada com sucesso!\n\nO pagamento deverá ser feito no local do evento.\n\n\n\nObrigado por participar de mais este evento SBRAFH!`);
            } else {
                setModalSucessoMsg(`Prezado(a) ${inscr.nome},\nsua inscrição foi enviada com sucesso!\n\nClique no ícone do pagseguro no final da tela.\n\n\n\nObrigado por participar de mais este evento SBRAFH!`);

                if (inscr.status < 0) {
                    associado.ender = inscr.ender;
                    associado.compl = inscr.compl;
                    associado.bairro = inscr.bairro;
                    associado.cidade = inscr.cidade;
                    associado.cep = inscr.cep;
                    associado.uf = inscr.uf;
                }

                setShowPagseguro(true);
            }

        } else {

            if (inscr.fila > 0) {
                setModalSucessoMsg(`Prezado(a) ${inscr.nome},\nsua inscrição foi enviada para a fila de espera com sucesso!\n\nAguarde nosso contato, caso hajam desistências.\n\nObrigado por participar de mais este evento SBRAFH!\n\n`);
            } else {
                setModalSucessoMsg(`Prezado(a) ${inscr.nome},\nsua inscrição foi enviada com sucesso!\n\nObrigado por participar de mais este evento SBRAFH!\n\n`);
 
                inscricoesCursosService.confirm(inscr);
            }
        }

        setShowModalSucesso(true);
    }

    const handleChangeOrigem = function (event) {
        var a = origens;
        const value = event.target.value;
        const checked = event.target.checked;
        if (checked) {
            a.push(value);
        } else {
            a = origens.filter(f => { return f !== value; });
        }
        setOrigens(a);
    }

    const criaOrigem = function () {
        const origens = [
            'Email Marketing',
            'Site SBRAFH',
            'Redes Sociais',
            'Palestras',
            'Colega/Amigo',
            'Professor',
            'Outros meios',
        ];
        return (<> <Row>
            {origens.map((o, i) => (
                <Col lg="3" key={i}>
                    <Form.Check inline key={i} type="checkbox" name="origem[]" id={`origem-${i}`}
                        value={o} label={o} onChange={handleChangeOrigem} >
                    </Form.Check>
                </Col>))}
        </Row></>);
    }

    const tipo = function () {   
        if (inscricao.status < 0) return 'NÃO ASSOCIADO';
        else if (inscricao.status === 0) return 'ASSOCIADO INATIVO';
        else if (inscricao.status > 0) return 'ASSOCIADO ATIVO'; 
    }

    const pagseguro = function (event) {
        event.preventDefault();

        var req = {
            redirectURL: 'https://' + window.location.hostname + '/associados/area',
            idInscricao: inscricao.id
        }; 
        pagseguroService.createInscricao(req).then(async (res) => { 
            var url = await res.json();
            window.location.href = url;
        }).catch(function () { 
            toastr.error('Ocorreu um erro. Por favor verifique seus dados e tente novamente.');
        });
    }

    return (<> <div className="register-body">
        <div className="register-wrapper">
            <Container className="vertical-center">
                <Row>
                    <Col className="ml-auto mr-auto" lg="12">
                        <Card className="card-register">
                            <Card.Header style={{ color: 'black' }}>
                                <Card.Title className="register-form-title">
                                    <b>{curso.titulo}</b>
                                </Card.Title>
                                <br/>
                                <Card.Title as="h6" className="register-form-title">
                                    SBRAFH Regional - {curso.regional}
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form id="cadastro" name="cadastro" method="POST" noValidate >
                                    <Row>
                                        <Col lg={12}>
                                            <label>Nome Completo: </label>
                                            <Form.Control type="text" placeholder="Nome" maxLength="60" id="nome" name="nome"
                                                required value={inscricao.nome} onChange={handleChange} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-1" md={4}>
                                            <label>CPF: </label>
                                            <Form.Control type="text" placeholder="CPF" id="cpf" name="cpf" value={formatCPF(inscricao.cpf)} readOnly />
                                        </Col>
                                        <Col lg={4}>
                                            <label>CRF-UF: </label>
                                            <Form.Control type="text" placeholder="CRF" maxLength="30" id="crf" name="crf"  
                                                value={inscricao.crf} onChange={handleChange} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-1" md={4}>
                                            <label>CEP: </label>
                                            <MaskedInput id="cep" placeholder="CEP" name="cep" mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]} className="form-control numeric"
                                                value={inscricao.cep} onChange={handleChangeMaskOnlyNumbers}  
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={8}>
                                            <label>Endereço Completo:  </label>
                                            <Form.Control cols="80" maxLength="59" placeholder="Ex.: Rua São Paulo, 1250" rows="2" as="textarea" id="ender" name="ender"  
                                                value={inscricao.ender} onChange={handleChange}></Form.Control>
                                        </Col>
                                        <Col lg={4}>

                                            <label>Complemento: </label>
                                            <Form.Control type="text" placeholder="Casa, Apto, Sala, Andar, etc..." maxLength="30" id="compl" name="compl"  
                                                value={inscricao.compl} onChange={handleChange} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4}>
                                            <label>Bairro: </label>
                                            <Form.Control type="text" placeholder="Bairro" maxLength="254" id="bairro" name="bairro"  
                                                required value={inscricao.bairro} onChange={handleChange} />
                                        </Col>
                                        <Col lg={4}>
                                            <label>Cidade: </label>
                                            <Form.Control type="text" placeholder="Cidade" maxLength="50" id="cidade" name="cidade" 
                                                required value={inscricao.cidade} onChange={handleChange} />
                                        </Col>
                                        <Col lg={4}>
                                            <label>Estado: </label>
                                            <Form.Select name="uf" id="uf" size="1" className="form-control" required value={inscricao.uf} onChange={handleChange} > 
                                                <option value="">Selecione</option>
                                                {estados.map(e => (
                                                    <option key={e.id} value={e.sigla} >
                                                        {e.sigla} - {e.estado}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <div className="invalid-feedback">
                                                Por favor selecione o estado!
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={8}>
                                            <label>E-mail: </label>
                                            <InputGroup className="form-group-no-border">
                                                <InputGroup.Text> <i className="nc-icon nc-email-85" /> </InputGroup.Text>
                                                <input type="email" className="form-control lower" placeholder="E-Mail" maxLength="100" id="email" name="email" required  
                                                    value={inscricao.email} onChange={handleChange} />
                                                <div className="invalid-feedback">
                                                    Por favor informe corretamente o e-mail!
                                                </div>
                                            </InputGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <label>DDD/Telefone: </label>
                                            <InputGroup className="form-group-no-border">
                                                <InputGroup.Text> <i className="pe-7s-call" /> </InputGroup.Text>
                                                <MaskedInput type="text" placeholder="Fone" className="form-control" id="fone" name="fone"   
                                                    mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    value={inscricao.fone} onChange={handleChangeMaskOnlyNumbers}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }} /> 
                                            </InputGroup>
                                        </Col>
                                        <Col lg={6}>
                                            <label>DDD/Celular: </label>
                                            <InputGroup className="form-group-no-border">
                                                <InputGroup.Text> <i className="pe-7s-call" /> </InputGroup.Text>
                                                <MaskedInput type="text" placeholder="Celular" className="form-control" id="cel" name="cel" required aria-describedby="celSm"  
                                                    mask={['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    value={inscricao.cel} onChange={handleChangeMaskOnlyNumbers}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }} /> &nbsp;
                                                <div className="invalid-feedback">
                                                    Por favor informe o número de celular.
                                                </div>
                                            </InputGroup>
                                            <small id="celSm" className="text-muted">
                                                Ex.: 11 08888-8888 ou 11 98888-8888
                                            </small>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <label>Instituição: </label>
                                            <Form.Control type="text" placeholder="Instituição" maxLength="254" id="instituicao" name="instituicao" 
                                                required value={inscricao.instituicao} onChange={handleChange} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <label>Tipo: </label>
                                            <Form.Select id="tipoInstituicao" name="tipoInstituicao" className="form-control" 
                                                required value={inscricao.tipoInstituicao} onChange={handleChange}>
                                                <option value="">Selecione</option>
                                                <option value="HOSPITAL PÚBLICO DE ENSINO/UNIVERSITÁRIO">HOSPITAL PÚBLICO DE ENSINO/UNIVERSITÁRIO</option>
                                                <option value="HOSPITAL PÚBLICO">HOSPITAL PÚBLICO</option>
                                                <option value="HOSPITAL PRIVADO SEM FINS LUCRATIVOS">HOSPITAL PRIVADO SEM FINS LUCRATIVOS (Santas Casas, etc.)</option>
                                                <option value="OUTROS">OUTROS</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <label>Endereço: </label>
                                            <Form.Control cols="80" placeholder="Ender. Instituição" rows="2" as="textarea" id="enderInst" name="enderInst"  
                                                value={inscricao.enderInst} onChange={handleChange}></Form.Control>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <label>DDD/Telefone(s): </label>
                                            <Form.Control type="text" placeholder="Telefone Instituição" maxLength="254" id="foneInst" name="foneInst"  
                                                value={inscricao.foneInst} onChange={handleChange} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col><hr /></Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            Como ficou sabendo deste Evento? Assinale quantas quiser: <br />
                                            {criaOrigem()}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <hr />
                                    </Row>
                                    <Row>
                                        <Col lg={8}>
                                            <label>Formação Profissional: </label>
                                            <Form.Select id="formacao" name="formacao" className="form-control" 
                                                value={inscricao.formacao} onChange={handleChangeFormacao} required>
                                                <option value='PROFISSIONAL'>PROFISSIONAL</option>
                                                {
                                                    curso.estudantes === 0 &&
                                                    <option value='EST. GRADUAÇÃO: FARMÁCIA'>EST. GRADUAÇÃO: FARMÁCIA</option>
                                                }

                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <Alert variant="light">
                                                <b> Evento: {curso.titulo} <br /></b>
                                            </Alert>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4}>
                                            <Alert variant="light">
                                                <b>Data: {formatDate(curso.data)}</b> </Alert>
                                        </Col>
                                        <Col lg={4}>
                                            <Alert variant="light">
                                                <b>Tipo Inscrição: {tipo()}</b> </Alert>
                                        </Col>
                                        <Col lg={4}>
                                            <Alert variant="light">
                                                <b>Valor da Inscrição R$: {formatMoney(valorInscricao)}</b> </Alert>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <Form.Check inline type="checkbox" name="checkbox" id="checkbox"
                                                label="Eu autorizo o compartilhamento meus dados (nome e e-mail) com os patrocinadores." >
                                            </Form.Check>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <Form.Check inline type="checkbox" name="checkbox2" id="checkbox2"
                                                label="Eu concordo com a Política de Proteção de Dados e Privacidade da Sbrafh." required >
                                            </Form.Check>
                                        </Col>
                                    </Row>
                                    <hr /> 
                                        <Row>
                                            <Col sm={12}>
                                                <center>
                                                    <Button id="btSend" disabled={showPagseguro} name="btSend" className="btn-round btn-fill btn-primary"
                                                         color="primary" onClick={(event) => { handleSubmit(event) }}>
                                                        Enviar Inscrição
                                                        {loading &&
                                                            <> &nbsp;
                                                                <Spinner animation="border" role="status" size="sm">
                                                                    <span className="visually-hidden">Carregando...</span>
                                                                </Spinner> </>}
                                                    </Button>
                                                </center>
                                            </Col>
                                        </Row>  
                                    {
                                        showPagseguro && <>
                                            <Row>
                                                <Col>
                                                    Dados para Cobrança:<br />
                                                    <Table className="table-striped small table-small" >
                                                        {associado && <>
                                                            <tbody>
                                                                <tr>
                                                                    <td>CPF: </td>
                                                                    <td>{formatCPF(associado.cpf)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Ender.: </td>
                                                                    <td>{associado.ender}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Compl.: </td>
                                                                    <td>{associado.compl}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Bairro: </td>
                                                                    <td>{associado.bairro}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Cidade: </td>
                                                                    <td>{associado.cidade}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>CEP: </td>
                                                                    <td>{associado.cep}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Estado: </td>
                                                                    <td>{associado.uf}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Valor R$: </td>
                                                                    <td>{formatMoney(valorInscricao)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Descrição: </td>
                                                                    <td>Inscrição - {curso.titulo}</td>
                                                                </tr>
                                                            </tbody>
                                                        </>}
                                                    </Table>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <center>
                                                        <Alert show variant="success">
                                                            Clique na imagem do PAGSEGURO para efetuar seu pagamento:
                                                            <hr />
                                                            <input id="btnPagseguro" name="btnPagseguro" type="image" src={require('../../../../src/assets/img/pagar.gif')}
                                                                alt="Pague com o PagSeguro - é rápido, grátis e seguro!" onClick={(event) => { pagseguro(event) }} />
                                                        </Alert>
                                                    </center>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Alert show variant="warning">
                                                        ATENÇÃO: No parcelamento do PAGSEGURO serão aplicados os juros da sua operadora de cartões de crédito.
                                                        Assim que selecionar a bandeira do seu cartão, verifique o valor das parcelas antes de efetuar o seu pagamento.
                                                        Caso não queira pagar os juros da sua operadora, selecione uma parcela ou utilize a opção BOLETO.
                                                    </Alert>
                                                    <Alert show variant="danger">
                                                        <b>IMPORTANTE: Pagamento com boleto demora em média 2 (dois) dias úteis para liberação do cadastro</b>
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        </>
                                    }

                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    </div>

        <ModalConfirm backdrop="static" message="Verifique se todos os dados estão corretos. Uma vez enviados, os dados não poderão ser alterados."
            showModal={showConfirm} onHide={() => { setShowConfirm(false) }} onConfirm={onConfirm} />

        <Modal show={showModalSucesso} backdrop="static" onHide={() => { setShowModalSucesso(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>Sucesso</Modal.Title>
            </Modal.Header>
            <Modal.Body><Alert variant="success">{modalSucessoMsg}</Alert></Modal.Body>
            <Modal.Footer>
                <Button variant="info" onClick={() => setShowModalSucesso(false)}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    );
}