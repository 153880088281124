import React from 'react';
import $ from 'jquery';
import MaskedInput from 'react-text-mask';
import { Button, Container, Row, Col, Card, Form, Alert, Spinner, Table } from "react-bootstrap";
import { useSearchParams, NavLink } from 'react-router-dom';
import toastr from 'toastr';
import { format, parseISO, compareAsc } from 'date-fns';
import { validCPF } from '../../../prototypes.js';
import { cadastroService, cursosRegionaisService, inscricoesCursosService } from '../../../_services';
import { formatDate, formatMoney, handleResponse, parseDate, parseDateISO } from '../../../_helpers';
import { FormInscricao } from './FormInscricao';

export function VerificarInscricaoForm() {
    const [loading, setLoading] = React.useState(false);
    const [msgErro, setMsgErro] = React.useState(undefined);
    const [curso, setCurso] = React.useState({});
    const [associado, setAssociado] = React.useState({});
    const [enviar, setEnviar] = React.useState(false);
    const [inscricao, setInscricao] = React.useState(null);
    const [encerradas, setEncerradas] = React.useState(false);
    const [numVagas, setNumVagas] = React.useState(Number.MAX_VALUE);
    const [entrarNaFila, setEntrarNaFila] = React.useState(false);

    const [searchParams] = useSearchParams();

    React.useEffect(function () { 
        const UF = searchParams.get('uf');
        const data = searchParams.get('data');

        if (UF && data) {
            const data2 = parseDate(data);
            const fmt = format(parseISO(data2), 'MM/dd/yyyy');
            var filtro = {
                regional: String(UF).toUpperCase(),
                data: fmt,
                pageIndex: 0,
                pageSize: 1
            };
            cursosRegionaisService.filter(filtro).then((res) => {
                if (!res.data[0]) { return Promise.reject(); }
                setCurso(res.data[0]);
                setEncerradas(inscricoesEncerradas(res.data[0]));
                inscricoesCursosService.vagas({idCurso: res.data[0].id}).then((res) => {
                    setNumVagas(res); 
                })
            }) 
            .catch(function () { 
                setCurso(undefined);
                toastr.error('Evento não encontrado ou ocorreu um erro ao carregar os dados. ');
            });
        } 
        return function () {
            document.body.classList.remove("register-page");
        }
    }, []);
 
    const handleChangeMaskOnlyNumbers = function (fun, event) {
        const value = event.target.value.replace(/[^\d]/g, '');;
        var name = event.target.name;
        fun((a) => {
            return a = { ...a, [name]: value }
        });
    }

    const erroValida = function (event, campo, msg) {
        alert(msg);
        $('html,body').animate({ scrollTop: $('#' + campo).offset().top - 200 }, 'slow');
        document.getElementById(campo).focus();
        event.preventDefault();
        return false;
    }

    const verificar = function (event) {
        event.preventDefault();
        // Valida campos
        var form = document.getElementById('cadastro');
        if (form.checkValidity() === false) {
            event.stopPropagation();
            form.classList.add('was-validated');
            $('html,body').animate({ scrollTop: $('.was-validated .form-control:invalid').offset().top - 200 }, 'slow');
            return;
        }

        // Valida o CPF
        if (!validCPF(associado.cpf)) {
            setMsgErro('Por favor informe corretamente o CPF!');
            return;
        } else {
            setMsgErro(undefined);
        }

        setLoading(true);
        const filtro = { nomeCpf: associado.cpf, pageIndex: 0, pageSize: 1 };
        cadastroService.filter(filtro).then(data => {
            const cadastro = data.data[0];
            // Evento exclusivo para associados
            if (!cadastro && curso.exclusivo > 0) {
                setMsgErro('Você não é associado SBRAFH!\nEste evento é exclusivo para Associados Ativos SBRAFH.');
                setLoading(false);
                return;
            }
            else if (!cadastro) { // Não exclusivo, cadastro não encontrado

                var inscricaoJaExiste = undefined;
                var inscricaoPaga = undefined;
                var inscr = undefined;
                const fmt = format(parseISO(curso.data), 'MM/dd/yyyy');
                var filtro = {
                    regional: String(curso.regional).toUpperCase(),
                    data: fmt,
                    cpf: associado.cpf,
                    pageIndex: 0,
                    pageSize: 1
                };
                inscricoesCursosService.filter(filtro).then((res) => handleResponse(res)).then((res) => {
                    inscricaoJaExiste = res.totalRows > 0 ? true : false;
                    inscricaoPaga = res.data[0] && (res.data[0].pgto != null);
                    inscr = res.data[0];
                }).catch(function () {
                    toastr.error('Ocorreu um erro inesperado, por favor tente novamente mais tarde.');
                }).then(function () {
                    if (inscricaoJaExiste) { // verifica inscrição
                        if (!inscricaoPaga) {
                            // Inscrição existente não paga: alterar
                            setInscricao(inscr);
                            setEnviar(true);
                        } else {
                            setMsgErro('Este CPF já está inscrito neste evento! Caso tenha alguma dúvida ou alterações, entre em contato conosco através do '
                                + 'e-mail <a href="mailto:atendimento@sbrafh.org.br">atendimento@sbrafh.org.br</a>');
                        }
                    } else { // nem inscrição nem cadastro
                        setMsgErro('Desculpe mas seu cadastro não foi localizado! Verifique se digitou corretamente o CPF e em caso positivo, entre em contato com '
                            + '<a href="mailto:atendimento@sbrafh.org.br">atendimento@sbrafh.org.br</a> para que possamos verificar.');
                    }
                    setLoading(false);
                });

                return;
            } else { // Cadastro encontrado
                // Inativo
                if (curso.exclusivo > 0 && cadastro.status < 1) {
                    setMsgErro('Sua inscrição de associado está como INATIVA! Este evento é exclusivo para Associados Ativos SBRAFH. '
                        + 'Qualquer dúvida entre em contato com <a href="mailto:atendimento@sbrafh.org.br">atendimento@sbrafh.org.br</a> para que possamos verificar.');
                    setLoading(false);
                    return;
                }
                // Evento exclusivo para profissionais
                if (curso.estudantes > 0 && !cadastro.formacao.contains('FARMACÊUTICO')) {
                    setMsgErro('Sua inscrição de associado está como ESTUDANTE! Este evento é exclusivo para PROFISSIONAIS. '
                        + 'Qualquer dúvida entre em contato com <a href="mailto:atendimento@sbrafh.org.br">atendimento@sbrafh.org.br</a> para que possamos verificar.');
                    setLoading(false);
                    return;
                }

                setAssociado(cadastro);
                setAssociado((a) => {
                    return a = {
                        ...a, dataNcto: formatDate(associado.dataNcto)
                    }
                });
            }
            setLoading(false);
        }).catch(function () {
            toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
        });
    }

    const validaForm = function (event) {
        var form = document.getElementById('cadastro');
        if (form.checkValidity() === false) {
            event.stopPropagation();
            form.classList.add('was-validated');
            $('html,body').animate({ scrollTop: $('.was-validated .form-control:invalid').offset().top - 200 }, 'slow');
            return false;
        }
        return true;
    }

    const handleSubmit = function (event) {
        event.preventDefault();
        if (!validaForm(event)) return;

        // Valida o CPF
        if (!validCPF(associado.cpf)) {
            return erroValida(event, 'cpf', 'Informe corretamente o CPF!');
        }

        setLoading(true);
    }

    const situacao = function () {
        const hoje = new Date();
        if (associado.status <= 0 || (associado.validade && parseDateISO(associado.validade)) < hoje) { return "INATIVO"; }
        else { return "ATIVO"; }
    }

    const iniciarInscricao = function (event) {
        if (associado.cpf) {
            // CPF válido?
            if (validCPF(associado.cpf)) {
                // Verifica se já está inscrito
                var inscricaoJaExiste = undefined;
                var inscricaoPaga = undefined;
                var inscr = undefined;

                const fmt = format(parseISO(curso.data), 'MM/dd/yyyy');
                var filtro = {
                    regional: String(curso.regional).toUpperCase(),
                    data: fmt,
                    cpf: associado.cpf,
                    pageIndex: 0,
                    pageSize: 1
                };
                inscricoesCursosService.filter(filtro).then((res) => handleResponse(res)).then((res) => {
                    inscricaoJaExiste = res.totalRows > 0 ? true : false;
                    inscricaoPaga = res.data[0] && (res.data[0].pgto != null);
                    inscr = res.data[0];
                }).catch(function () {
                    toastr.error('Ocorreu um erro inesperado, por favor tente novamente mais tarde.');
                }).then(function () {

                    const filtroCad = { nomeCpf: associado.cpf, pageIndex: 0, pageSize: 1 };
                    cadastroService.filter(filtroCad).then(data => {
                        const cadastro = data.data[0];
                        // Evento exclusivo para associados
                        if (!cadastro && curso.exclusivo > 0) {
                            setMsgErro('Você não é associado SBRAFH!\nEste evento é exclusivo para Associados Ativos SBRAFH.');
                            setLoading(false);
                            return Promise.reject(false);
                        }
                        // Inativo
                        if (curso.exclusivo > 0 && cadastro.status < 1) {
                            setMsgErro('Sua inscrição de associado está como INATIVA! Este evento é exclusivo para Associados Ativos SBRAFH. '
                                + 'Qualquer dúvida entre em contato com <a href="mailto:atendimento@sbrafh.org.br">atendimento@sbrafh.org.br</a> para que possamos verificar.');
                            setLoading(false);
                            return Promise.reject(false);
                        }
                        // Evento exclusivo para profissionais
                        if (curso.estudantes > 0 && !cadastro.formacao.contains('FARMACÊUTICO')) {
                            setMsgErro('Sua inscrição de associado está como ESTUDANTE! Este evento é exclusivo para PROFISSIONAIS. '
                                + 'Qualquer dúvida entre em contato com <a href="mailto:atendimento@sbrafh.org.br">atendimento@sbrafh.org.br</a> para que possamos verificar.');
                            setLoading(false);
                            return Promise.reject(false);
                        }

                    }).then(function () {
                        // CPF já inscrito no evento
                        if (inscricaoJaExiste === true) {
                            if (!inscricaoPaga) {
                                // Inscrição existente não paga: alterar
                                setInscricao(inscr);
                                setEnviar(true);
                            } else {
                                setMsgErro('Este CPF já está inscrito neste evento! Caso tenha alguma dúvida ou alterações, entre em contato conosco através do '
                                    + 'e-mail <a href="mailto:atendimento@sbrafh.org.br">atendimento@sbrafh.org.br</a>');
                                return;
                            }
                        } else {
                            setMsgErro(undefined);
                            setEnviar(true);
                        }
                    });


                });
            } else { // CPF inválido
                setMsgErro('Por favor informe corretamente o CPF!');
            }
        } else { // CPF não informado
            if (!validaForm(event)) return;
        }
    }

    const inscricoesEncerradas = function (curso) {
        // Comparar só as datas
        const hoje = new Date();
        const val = parseDateISO(curso.dataMaxima);
        const res = compareAsc(Date.UTC(hoje.getFullYear(), hoje.getMonth(), hoje.getDate())
            , Date.UTC(val.getFullYear(), val.getMonth(), val.getDate()));
        return (res > 0);
    }

    const changeEntrarNaFila = function (event) {
        const value = event.target.checked;
        setEntrarNaFila(value);
    }

    return (enviar ? <> <FormInscricao associado={associado} curso={curso} inscricaoExistente={inscricao} /> </> : curso &&
        <>
            <div className="register-body">
                <div className="register-wrapper">
                    <Container className="vertical-center">
                        <Row>
                            <Col className="ml-auto mr-auto" lg="12">
                                <Card className="card-register">
                                    <Card.Header style={{ color: 'black' }}>
                                        <Card.Title className="register-form-title">
                                            <b>{curso.titulo}</b>
                                        </Card.Title>
                                        <br />
                                        <Card.Title className="register-form-title">
                                            SBRAFH Regional - {curso.regional}
                                        </Card.Title>
                                        <br />

                                        <Alert show={encerradas} variant="danger">
                                            <b>INSCRIÇÕES ONLINE ENCERRADAS!</b>
                                        </Alert>
                                    </Card.Header>
                                    
                                    {encerradas === false && numVagas === 0 &&
                                        <Card.Header>
                                            <Alert variant="warning">
                                                <b>
                                                    As vagas para este evento já foram preenchidas. <br />
                                                    Gostaria de entrar na fila para o caso de haverem desistências?<br />
                                                    Se sim, marque a caixa abaixo lembrando que caso seja necessário algum pagamento,<br />
                                                    este deverá ser feito no local e a prioridade da fila é na ordem de entrada.<br /><br />
                                                </b>
                                                <Row>
                                                    <Col lg={12}>
                                                        <Form.Check inline type="checkbox" name="checkbox2" id="checkbox2"
                                                            value={entrarNaFila} onChange={(event) => { changeEntrarNaFila(event) }}
                                                            label="Sim, desejo entrar na fila de espera por uma vaga">
                                                        </Form.Check>
                                                    </Col>
                                                </Row>

                                            </Alert>
                                        </Card.Header>}

                                    {encerradas === false && (numVagas > 0 || entrarNaFila) &&
                                        <>
                                            <Card.Header>
                                                <Alert show variant="info">
                                                    Informe seu CPF na caixa abaixo para realizar sua inscrição. Caso seja associado Sbrafh, verifique a sua situação
                                                    junto à sociedade antes de efetuar a sua inscrição, no botão Verificar.
                                                </Alert>
                                            </Card.Header>

                                            <Card.Body>
                                                <Form id="cadastro" name="cadastro" method="POST" noValidate onSubmit={handleSubmit}>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Alert show={msgErro !== undefined} variant="danger" dismissible onClose={() => { setMsgErro(undefined); }}>
                                                                <span><b><div dangerouslySetInnerHTML={{ __html: msgErro }}></div> </b></span>
                                                            </Alert>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="pr-1" md={5}>
                                                            <label>CPF:</label>
                                                            <MaskedInput id="cpf" placeholder="CPF" name="cpf" mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]} className="form-control numeric"
                                                                required value={associado.cpf} onChange={(event) => { handleChangeMaskOnlyNumbers(setAssociado, event) }}
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                            />
                                                            <div className="invalid-feedback">
                                                                Por favor informe o CPF!
                                                            </div>
                                                        </Col>
                                                        <Col md={5}>
                                                            <label></label><br />
                                                            <Button id="verificar" name="verificar" className="btn-round  btn-success" color="success" onClick={(event) => { verificar(event); }}>
                                                                Verificar
                                                                {loading &&
                                                                    <> &nbsp;
                                                                        <Spinner animation="border" role="status" size="sm">
                                                                            <span className="visually-hidden">Carregando...</span>
                                                                        </Spinner> </>}
                                                            </Button>
                                                        </Col>
                                                    </Row>

                                                    <hr />
                                                    {associado.idCadastro && <>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <Table responsive className=" table-striped small table-small">
                                                                    <tbody>
                                                                        <tr >
                                                                            <td >Nome: </td>
                                                                            <td colSpan="3">{associado.nome}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>E-mail: </td>
                                                                            <td colSpan="3">{associado.email}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Valor Anuidade: </td>
                                                                            <td colSpan="3">R$ {formatMoney(associado.valor)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Validade: </td>
                                                                            <td colSpan="3">{formatDate(associado.validade)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Situação: </td>
                                                                            <td colSpan="3">{situacao()}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={12}>
                                                                {associado && associado.status === 0 && <>
                                                                    <Alert variant="danger">
                                                                        <b>Sua inscrição de associado está INATIVA.
                                                                            <NavLink className="btn-simple" to="/associados/login"> Clique Aqui para regularizar antes de efetuar a sua inscrição!</NavLink>
                                                                        </b>
                                                                    </Alert>

                                                                    <Alert variant="secondary">
                                                                        ou clique no botão Enviar abaixo para inscrição como associado inativo.
                                                                    </Alert>
                                                                </>
                                                                }
                                                                {associado && associado.status > 0 &&
                                                                    <Alert variant="success">
                                                                        Sua inscrição de associado está ATIVA. Clique no botão enviar abaixo para efetuar a sua inscrição.
                                                                    </Alert>
                                                                }
                                                            </Col>
                                                        </Row> </>}
                                                    <>
                                                        <Row>
                                                            <Col>
                                                                <Alert variant="secondary">
                                                                    Para fazer inscrição neste curso ou consultá-la informe seu CPF no campo acima.
                                                                </Alert>
                                                                <center>
                                                                    <Button id="btSend" name="btSend" className="btn btn-success" color="success" onClick={(event) => { iniciarInscricao(event); }}>
                                                                        Clique aqui para iniciar sua inscrição
                                                                    </Button>
                                                                </center>
                                                            </Col>
                                                        </Row>
                                                    </>

                                                </Form>
                                            </Card.Body>
                                        </>
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}