import React from "react";
import { Card, Table, Container, Row, Col } from "react-bootstrap";
import { relatoriosService } from "../../../_services";
import toastr from 'toastr';

export default function RelatorioAssociadosAtivos() {
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(function () {
    setLoading(true);
    relatoriosService.associadosAtivos().then(res => res.json()).then((res) => {
      setItems(res);
    }).catch((ex) => {
      toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
    }).then(function () {
      setLoading(false);
    });
  }, []);


  function exportarExcel(event) {
    event.preventDefault();
    setLoading(true);
    relatoriosService.associadosAtivosExcel()
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "AssociadosAtivosPorRegionais.xls";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }).catch(() => {
        toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
      }).then(function () {
        setLoading(false);
      });
  }

  return (<>
    <Container fluid>
      <Row>
        <Col md="6">
          {loading &&
            <div className="loading-overlay d-flex justify-content-center align-text-center" >
              <div className="loading-spinner "></div>
              <br />
              <div className="loading-text">Carregando...</div>
            </div>
          }
          <Card className="">
            <Card.Header>
              <Card.Title as="h4">Associados Ativos / Regional</Card.Title>
              <div style={{ float: 'right' }}>
                <input id="btnExcel" name="btnExcel" type="image" src={require('../../../../src/assets/img/excel.png')}
                  style={{ width: '50%', height: '50%' }} title="Exportar para Excel" alt="Exportar para Excel" onClick={(event) => { exportarExcel(event) }} />
              </div>
            </Card.Header>
            <Card.Body >
              <Table responsive className="table-hover table-striped table-small">
                <thead>
                  <tr>
                    <th className="border-0">Regional</th>
                    <th className="border-0">Associados Ativos</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    items.length > 0 &&
                    items.map((e, i) => (
                      <tr key={e.id}>
                        <td>
                          {String(e.uf).toUpperCase()}
                        </td>
                        <td>
                          {e.ativos}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </>
  );
}