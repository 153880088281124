import { environment } from '../environment'; 
export const contatoService = { 
    create, 
}; 

function create(cont) {
    return fetch(`${environment.baseURL}/Contato`, {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(cont)
    });
} 