import { environment } from '../environment';
 
export const formacaoService = {
    list,
}; 

function list () {
    return fetch(`${environment.baseURL}/Formacao`, { method: 'GET', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } })
        .then(async (res) => {
            const data = await res.json();
            if (!res.ok) {
                return Promise.reject(res);
            }
            return data;
        });
}