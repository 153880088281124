import React from 'react';
import $ from 'jquery';
import MaskedInput from 'react-text-mask';
import { Button, Container, Row, Col, Card, Form, Spinner, InputGroup } from "react-bootstrap";
import { authenticationService, eventosRegionaisService, regionaisService, cadastroService } from '../../../_services';
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { formatDate, parseDate, formatMoney } from '../../../_helpers';
import toastr from 'toastr';
import { handleAuthResponse } from '../../../_helpers/handle-response.js';
import { ModalAjuda } from './ModalAjuda';

export function CriarEventoRegional() {
    const [editing, setEditing] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [showAjuda, setShowAjuda] = React.useState(false);
    const [eventoRegional, setEventoRegional] = React.useState({
        regional: '',
        tituloEvento: '',
        tipoEvento: '',
        data: '',
        dataEvento: '',
        cargaHoraria: '10',
        localEvento: '',
        publicoAlvo: '',
        valorAssocProf: '0',
        valorAssocEstG: '0',
        valorNAssocProf: '150',
        valorNAssocEstG: '75',
        cursoVinculado: '',
        vagas: '50',
        responsavel: '',
        palestrante: '',
        descricao: '',
        objetivos: '',
        inscricaoPrevia: '1',
        contato: '',
        obs: '',
        custoEstimado: '',
        recursos: '', 
    });
    
    const [usuario, setUsuario] = React.useState(null);
    const [regionais, setRegionais] = React.useState([]); 

    const navigate = useNavigate();
    const params = useParams();

    React.useEffect(function () {
        const user = authenticationService.loggedUser; 
        cadastroService.getById(user.id).then(r => handleAuthResponse(r, navigate)).then((data) => {
            setUsuario (data); 
        }).catch(() => {  
            toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
        });

        if (params.id !== undefined) {
            const ID = params.id;
            eventosRegionaisService.getById(ID).then(r => handleAuthResponse(r, navigate))
                .then(data => {
                    Object.keys(data).forEach(key => (eventoRegional[key] !== undefined && (data[key] === undefined || data[key] === null)) ? data[key] = '' : {});

                    setEventoRegional(data);
                    setEventoRegional((a) => {
                        return a = { ...a, data: formatDate(data.data)  }
                    });
                    setEditing(true);
                }).catch(ex => {
                    toastr.error('AAAAA');
                });
        }
    }, [params.id]);

    React.useEffect(() => {  
        if (usuario) {
            if (usuario.nivelAcesso > 3) {
                getRegionais();
            } else {
                regionaisService.getByUF(usuario.regional).then(r => handleAuthResponse(r, navigate)).then((res) => { 
                    setRegionais([res]);
                    setEventoRegional((er) => {
                        return er = { ...er, regional: usuario.regional }
                    });
                }).catch(() => {  
                    toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
                });
            }  
        }
    }, [usuario]);

    const getRegionais = function () {
        regionaisService.list().then(data => {
            setRegionais(data); 
        }).catch((ex) => {
            toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
        });
    }

    const handleChange = function (event) {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        var name = event.target.name;
        setEventoRegional((a) => {
            return a = { ...a, [name]: value }
        });
    }

    const handleSubmit = function (event) {

        event.preventDefault();
        // Valida campos
        var form = document.getElementById('cadastro');
        if (form.checkValidity() === false) {
            event.stopPropagation();
            form.classList.add('was-validated');
            $('.main-panel').animate({ scrollTop: $('.was-validated .form-control:invalid').offset().top - 200 }, 'slow');
            return;
        }

        setLoading(true);
        if (editing) {
            var req = {
                ...eventoRegional,
                data: parseDate(eventoRegional.data), 
            }; 
            eventosRegionaisService.update(req).then(r => handleAuthResponse(r, navigate)).then(res => {
                toastr.success('Dados atualizados com sucesso.');
                setLoading(false);
                navigate('/admin/eventos-regionais/');
            }).catch(function () {
                setLoading(false);
                toastr.error('Ocorreu um erro ao salvar os dados. Por favor tente novamente.');
            });
        } else { 
            let req = {
                ...eventoRegional,  
                enviado: null,
                dataReg: new Date().toISOString(),
                data: parseDate(eventoRegional.data)
            };
            eventosRegionaisService.create(req).then(r => handleAuthResponse(r, navigate)).then((res) => {
                toastr.success('Dados salvos com sucesso.');
                setLoading(false);
                navigate('/admin/eventos-regionais/');
            }).catch(function () {
                setLoading(false);
                toastr.error('Ocorreu um erro ao salvar os dados. Por favor tente novamente.');
            });
        }
    }

    return (
        <>
            <ModalAjuda show={showAjuda} scrollable={true} onHide={() => setShowAjuda(false)} />
            <div className="register-body">

                <div className="register-wrapper">
                    <Container className="vertical-center">
                        <Row>
                            <Col className="ml-auto mr-auto" lg="12">
                                <Card className="card-register">
                                    <Card.Header style={{ color: 'black' }}>
                                        <Card.Title className="register-form-title">Formulário Proposta para Eventos Regionais
                                            <Button className="btn-simple" variant="link" onClick={() => setShowAjuda(true)}>Ajuda</Button>
                                            <div style={{ float: 'right' }}>
                                                <NavLink className="btn btn-primary btn-sm" to="/admin/eventos-regionais/">
                                                    Voltar
                                                </NavLink>
                                            </div></Card.Title>

                                    </Card.Header>
                                    <Card.Body>

                                        <Form id="cadastro" name="cadastro" method="POST" noValidate onSubmit={handleSubmit}>
                                            <Row>
                                                <Col className="pr-1" md={12}>
                                                    <label>Nome do Evento:</label>
                                                    <Form.Control type="text" placeholder="Nome" maxLength="254" id="tituloEvento" name="tituloEvento" required
                                                        value={eventoRegional.tituloEvento}
                                                        onChange={handleChange} />
                                                    <div className="invalid-feedback">
                                                        Por favor informe o nome!
                                                    </div>
                                                </Col>
                                            </Row>
                                            {usuario && usuario.nivelAcesso > 3 &&  
                                                    <Row>
                                                        <Col md="12">
                                                            <Form.Group>
                                                                <label>* Regional</label>
                                                                <Form.Select id="regional" name="regional" className="form-control"
                                                                    value={eventoRegional.regional} onChange={handleChange}> 
                                                                        <option value="">Nenhuma</option>  
                                                                    {regionais.map(e => (
                                                                        <option key={e.id} value={String(e.uf).toUpperCase()}>{String(e.uf).toUpperCase()} - {e.regional}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row> 
                                                }
                                            <Row>
                                                <Col className="pr-1" lg={3}>
                                                    <label>Tipo do Evento:</label>
                                                    <Form.Select name="tipoEvento" id="tipoEvento" className="form-control" required value={eventoRegional.tipoEvento}
                                                        onChange={handleChange}>
                                                        <option value=""  >Selecione</option>
                                                        <option value="CICLO DE DEBATES">CICLO DE DEBATES</option>
                                                        <option value="CONFERÊNCIA">CONFERÊNCIA</option>
                                                        <option value="CURSOS">CURSOS</option>
                                                        <option value="FÓRUM">FÓRUM</option>
                                                        <option value="MESA-REDONDA">MESA-REDONDA</option>
                                                        <option value="SEMINÁRIO">SEMINÁRIO</option>
                                                        <option value="SIMPÓSIO">SIMPÓSIO</option>
                                                        <option value="WORKSHOPS">WORKSHOPS</option>
                                                        <option value="OUTROS">OUTROS</option>
                                                    </Form.Select>
                                                    <div className="invalid-feedback">
                                                        Por favor selecione o tipo!
                                                    </div>
                                                </Col>
                                                <Col lg={2}>
                                                    <label>Carga Horária:</label>
                                                    <input type="text" id="cargaHoraria" name="cargaHoraria" className="form-control numeric" maxLength="8" required aria-describedby="numSm"
                                                        value={eventoRegional.cargaHoraria} onChange={handleChange}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} /> &nbsp;
                                                    <div className="invalid-feedback">
                                                        Por favor informe a carga horária!
                                                    </div>
                                                </Col>
                                                <Col lg={2}>
                                                    <label htmlFor="complemento">Vagas:</label>
                                                    <input type="text" id="vagas" name="vagas" className="form-control numeric" maxLength="8" required aria-describedby="numSm"
                                                        value={eventoRegional.vagas} onChange={handleChange}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} /> &nbsp;
                                                    <div className="invalid-feedback">
                                                        Por favor informe o número de vagas!
                                                    </div>
                                                </Col>
                                                <Col lg={2}>
                                                    <label htmlFor="bairro">Data Inicial:</label>
                                                    <InputGroup className="form-group-no-border">
                                                        <InputGroup.Text> <i className="pe-7s-date" /> </InputGroup.Text>
                                                        <MaskedInput type="text" className="form-control" name="data" id="data" required placeholder="01/01/0000"
                                                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                            value={eventoRegional.data} onChange={handleChange}
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }} />
                                                        <div className="invalid-feedback">
                                                            Por favor preencha a data.
                                                        </div>
                                                    </InputGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <label htmlFor="complemento">Necessário inscrição prévia:</label>
                                                    <div>
                                                        <Form.Check inline name="inscricaoPrevia" id="simm" onChange={handleChange} value="1" label="Sim"
                                                            type="radio" checked={String(eventoRegional.inscricaoPrevia) === '1'} />
                                                        <Form.Check inline name="inscricaoPrevia" id="naoo" onChange={handleChange} value="0" label="Não"
                                                            type="radio" checked={String(eventoRegional.inscricaoPrevia) === '0'} />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <label>Data(s)/Horário(s) do Evento </label>
                                                    <Form.Control cols="80" placeholder="(Ex.: 17/05/204 - Das 18:00 às 21:00 (Um linha para cada data/horário)" rows="3" as="textarea" id="dataEvento" name="dataEvento"
                                                        value={eventoRegional.dataEvento} onChange={handleChange}></Form.Control>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <label>Local do Evento </label>
                                                    <Form.Control cols="80" placeholder="(Endereço completo, Bairro, Cidade, Estado, etc...)" rows="2" as="textarea" id="localEvento" name="localEvento"
                                                        value={eventoRegional.localEvento} onChange={handleChange}></Form.Control>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <label>Público-Alvo</label>
                                                    <Form.Control cols="80" placeholder="(Profissionais, Estudantes, Residentes, etc...)" rows="2" as="textarea" id="publicoAlvo" name="publicoAlvo"
                                                        value={eventoRegional.publicoAlvo} onChange={handleChange}></Form.Control>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <label>Valores para inscrição online</label>
                                                </Col>
                                                <Row>
                                                    <Col md={3}>
                                                        <label>Associado Profissional </label>
                                                        <input type="text" id="valorAssocProf" name="valorAssocProf" className="form-control" maxLength="8" required aria-describedby="numSm"
                                                            value={formatMoney(eventoRegional.valorAssocProf)} onChange={handleChange} />
                                                    </Col>
                                                    <Col md={3}>
                                                        <label> Associado Est. Grad. Farmácia</label>
                                                        <input type="text" id="valorAssocEstG" name="valorAssocEstG" className="form-control" maxLength="8" required aria-describedby="numSm"
                                                            value={formatMoney(eventoRegional.valorAssocEstG)} onChange={handleChange} />
                                                    </Col>
                                                    <Col md={3}>
                                                        <label>Não Associado Profissional </label>
                                                        <input type="text" id="valorNAssocProf" name="valorNAssocProf" className="form-control" maxLength="8" required aria-describedby="numSm"
                                                            value={formatMoney(eventoRegional.valorNAssocProf)} onChange={handleChange} />
                                                    </Col>
                                                    <Col md={3}>
                                                        <label> Não Associado Est. Grad. Farmácia</label>
                                                        <input type="text" id="valorNAssocEstG" name="valorNAssocEstG" className="form-control" maxLength="8" required aria-describedby="numSm"
                                                            value={formatMoney(eventoRegional.valorNAssocEstG)} onChange={handleChange} />
                                                    </Col>
                                                </Row>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md={12}>
                                                    <label>Curso, projeto ou setor vinculado:</label>
                                                    <Form.Control type="text" placeholder="Curso, projeto ou setor vinculado" maxLength="254" id="cursoVinculado" name="cursoVinculado"
                                                        value={eventoRegional.cursoVinculado}
                                                        onChange={handleChange} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <label>Responsável pela equipe de realização:</label>
                                                    <Form.Control cols="80" placeholder="(Nome, e-mail e telefone - um em cada linha)" rows="3" as="textarea" id="responsavel" name="responsavel"
                                                        value={eventoRegional.responsavel} onChange={handleChange}></Form.Control>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <label>Informações sobre o(s) palestrante(s):</label>
                                                    <Form.Control cols="80" placeholder="(Nome e mini-currículo se disponível - um em cada linha)" rows="3" as="textarea" id="palestrante" name="palestrante"
                                                        value={eventoRegional.palestrante} onChange={handleChange}></Form.Control>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <label>Descrição do Evento (informações para subsidiar texto para site e imprensa):</label>
                                                    <Form.Control cols="80" placeholder="Descrição" rows="3" as="textarea" id="descricao" name="descricao"
                                                        value={eventoRegional.descricao} onChange={handleChange}></Form.Control>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <label>Objetivos do Evento:</label>
                                                    <Form.Control cols="80" placeholder="Objetivos" rows="2" as="textarea" id="objetivos" name="objetivos"
                                                        value={eventoRegional.objetivos} onChange={handleChange}></Form.Control>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <label>Contato a ser divulgado para mais informações:</label>
                                                    <Form.Control cols="80" placeholder="(Nome, telefone, e-mail, estacionamento (gratuito ou pago)) ..." rows="3" as="textarea" id="contato" name="contato"
                                                        value={eventoRegional.contato} onChange={handleChange}></Form.Control>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <label>Observações Gerais:</label>
                                                    <Form.Control cols="80" placeholder="Observações" rows="2" as="textarea" id="obs" name="obs"
                                                        value={eventoRegional.obs} onChange={handleChange}></Form.Control>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <label>Custo estimado do Evento:</label>
                                                    <Form.Control cols="80" placeholder="(organizar em itens, equipamentos, materiais, etc. - um em cada linha):" rows="3" as="textarea"
                                                        id="custoEstimado" name="custoEstimado"
                                                        value={eventoRegional.custoEstimado} onChange={handleChange}></Form.Control>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <label>FONTES DE RECURSOS:</label>
                                                    <Form.Control cols="80" placeholder="(parcerias, patrocínios, etc. - um em cada linha):" rows="3" as="textarea"
                                                        id="recursos" name="recursos"
                                                        value={eventoRegional.recursos} onChange={handleChange}></Form.Control>
                                                </Col>
                                            </Row>
                                            <hr />

                                            <Row>
                                                <Col sm={12}>
                                                    <center>
                                                        <Button id="btSend" name="btSend" className="btn-round btn-fill btn-primary" color="primary" type="submit">
                                                            Gravar Proposta{loading &&
                                                                <> &nbsp;
                                                                    <Spinner animation="border" role="status" size="sm">
                                                                        <span className="visually-hidden">Carregando...</span>
                                                                    </Spinner> </>}
                                                        </Button>
                                                    </center>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}