import React from 'react';
import $ from 'jquery';
import { Button, Container, Row, Col, Card, Form, Alert, Spinner, InputGroup } from "react-bootstrap";
import { useNavigate, NavLink } from 'react-router-dom';
import toastr from 'toastr';
import { cadastroService, authenticationService } from '../../_services';
import { handleAuthResponse } from '../../_helpers';

export function TrocarSenhaForm() {
	const [loading, setLoading] = React.useState(false);
	const [msgErro, setMsgErro] = React.useState(undefined);
	const [associado, setAssociado] = React.useState({
		senha: '',
		confirme: ''
	});

	const navigate = useNavigate();

	React.useEffect(function () {
		const currentUser = authenticationService.loggedUser;
		cadastroService.getById(currentUser.id).then(r => handleAuthResponse(r, navigate))
			.then(data => {
				Object.keys(data).forEach(key => (associado[key] !== undefined && (data[key] === undefined || data[key] === null)) ? data[key] = '' : {});
				setAssociado(data); 
				setAssociado((a) => {
					return a = {
						...a, 
						senha: '', confirme: ''
					}
				});
			 
			}).catch(ex => {
				toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
				navigate('/associados/area');
			});
	}, [navigate]);

	const handleChange = function (event) {
		const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		var name = event.target.name;
		setAssociado((a) => {
			return a = { ...a, [name]: value }
		});

		
	}

	const handleSubmit = function (event) {
		event.preventDefault();
		// Valida campos
		var form = document.getElementById('cadastro');
		if (form.checkValidity() === false) {
			event.stopPropagation();
			form.classList.add('was-validated');
			$('html,body').animate({ scrollTop: $('.was-validated .form-control:invalid').offset().top - 200 }, 'slow');
			return;
		}

		// Senha 
		if (associado.senha !== associado.confirme) {
			setMsgErro('As duas senhas devem ser iguais!');
			return;
		}

		setLoading(true);

		let req = {
			...associado
		};
		cadastroService.update(req).then((res) => {
			setLoading(false);
			toastr.success('Seus dados foram alterados com sucesso.');
			navigate('/associados/area');
		}).catch(function (err) {
			setLoading(false);
			setMsgErro('Ocorreu um erro ao salvar os dados. Por favor tente novamente.');
		});
	}

	return (
		<>
			<div className="register-body">
				<div className="register-wrapper" >
					<Container className="vertical-center">
						<Row>
							<Col className="ml-auto mr-auto" lg="12">
								<Card className="card-register">
									<Card.Header style={{ color: 'black' }}>
										<Card.Title as="h4" className="register-form-title">
											Alteração de Senha
											<div style={{ float: 'right' }}>
												<NavLink className="btn btn-primary btn-sm" to="/associados/area">
													Voltar
												</NavLink>
											</div>
										</Card.Title>
									</Card.Header>
									<Card.Body>
										<Form id="cadastro" name="cadastro" method="POST" noValidate onSubmit={handleSubmit}>

											<Row>
												<Col md={12}>
													<label>Nova Senha:</label>
													<InputGroup className="form-group-no-border ">
														<InputGroup.Text>
															<i className="nc-icon nc-key-25" />
														</InputGroup.Text>
														<input className="form-control" type="password" maxLength="10" name="senha" id="senha" required aria-describedby="smSenha"
															value={associado.senha} onChange={handleChange} />
														<div className="invalid-feedback">
															Por favor informe uma senha.
														</div>
													</InputGroup>
													&nbsp; <small className="form-text text-muted" id="smSenha">Máximo 10 dígitos (letras e números)</small>

												</Col>
											</Row>
											<Row>
												<Col md={12}>
													<label>Confirme:</label>
													<InputGroup className="form-group-no-border ">
														<InputGroup.Text>
															<i className="nc-icon nc-key-25" />
														</InputGroup.Text>
														<input className="form-control" type="password" maxLength="10" name="confirme" id="confirme" required
															value={associado.confirme} onChange={handleChange} />
														<div className="invalid-feedback">
															Por favor confirme a senha.
														</div>
													</InputGroup>
												</Col>
											</Row>
											<Row>
												<Col md={12}>
													<Alert show={msgErro !== undefined} variant="danger" dismissible onClose={() => { setMsgErro(undefined); }}>
														<span><b> {msgErro} </b></span>
													</Alert>
												</Col>
											</Row>
											<Row>
												<Col><br/></Col>
											</Row>
											<Row>
												<Col sm={12}>
													<center>
														<Button id="btSend" name="btSend" className="btn-round btn-fill btn-warning" color="warning" type="submit">
															Gravar nova senha
															{loading &&
																<> &nbsp;
																	<Spinner animation="border" role="status" size="sm">
																		<span className="visually-hidden">Carregando...</span>
																	</Spinner>
																</>}
														</Button>
													</center>
												</Col>
											</Row>
										</Form>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		</>
	);
}