import React from "react";
import { useLocation, NavLink } from "react-router-dom";
import { cursosRegionaisService, regionaisService } from "../../_services";
import { handleResponse, formatDate } from '../../_helpers';
import { Nav, NavDropdown } from "react-bootstrap";
import { format } from "date-fns";
import toastr from 'toastr';

function DirRegionalSidebar({ color, image, routes, usuario }) {
    const location = useLocation();
    const [regionais, setRegionais] = React.useState([]);
    const [cursosRegionais, setCursosRegionais] = React.useState([]);

    const activeRoute = (routeName) => {
        return location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };

    React.useEffect(function () {
        regionaisService.getByUF(usuario.regional).then(handleResponse).then(data => {
            setRegionais(new Array(data));
        }).catch(() => {
            toastr.error('Erro ao carregar Regionais, por favor tente novamente.');
        });

        cursosRegionaisService.filter({ regional: usuario.regional, pageIndex: 0, pageSize: 10000000 }).then(res => {
            setCursosRegionais(res.data);
        }).catch(() => {
            toastr.error('Erro ao carregar Regionais, por favor tente novamente.');
        });
    }, []);

    return (
        <div className="sidebar" data-image={image} data-color={color}>
            <div className="sidebar-background"  />
            <div className="sidebar-wrapper">
                <div className="logo d-flex align-items-center justify-content-start">
                    <a href="/admin">
                        <div className="simple-text logo-mini mx-1" >
                            <span className="fa fa-wrench" />
                        </div>
                    </a>
                    <a href="/admin" className="simple-text">
                        Administração
                    </a>
                </div>
                <Nav> 
                    <li> <a href="https://webmail-seguro.com.br/sbrafh.org.br" className="nav-link" target="_blank">Webmail</a></li>
                    {routes.map((prop, key) => {
                        if (!prop.redirect && prop.sidebar)
                            return (
                                <li className={activeRoute(prop.layout + prop.path)} key={'l_'+key}    >
                                    <NavLink
                                        to={prop.layout + prop.path}
                                        className="nav-link" key={'n_'+key}
                                    >
                                        <i className={prop.icon} />
                                        <p>{prop.name}</p>
                                    </NavLink>
                                </li>
                            ); 
                    })}

                    {
                        regionais && regionais.map(r => {
                            return (
                                <li key={r.id}>
                                    <NavDropdown title={"Regional - " + String(r.uf).toUpperCase()} id="nav-dropdown" key={'_'+r.id} >
                                        <NavDropdown.Item as={NavLink} eventKey={'1.' + r.id} to={`/admin/associados?uf=${r.uf}`} key={r.id}>
                                            {`Lista de Associados (${String(r.uf).toUpperCase()})`}
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        {cursosRegionais && cursosRegionais.map((rr) => {
                                            return ( 
                                                <NavDropdown.Item key={`evv${rr.id}`} as={NavLink} to={`/admin/cursos-regionais/inscricoes/?uf=${rr.regional}&data=${format(new Date(rr.data), 'MM/dd/yyyy')}`} >
                                                    Evento - {formatDate(rr.data)}
                                                </NavDropdown.Item>
                                           );
                                        })}
                                    </NavDropdown>
                                </li>
                            );
                        })
                    }
                </Nav>
            </div>
        </div>
    );
}

export default DirRegionalSidebar;
