import React from 'react';
import $ from 'jquery';
import MaskedInput from 'react-text-mask';
import { Button, Container, Row, Col, Card, Form, Alert, Spinner, Table } from "react-bootstrap";
import toastr from 'toastr';
import { validCPF } from '../../../prototypes.js';
import { inscricoesCursosService, certificadosService } from '../../../_services';
import { formatDate } from '../../../_helpers';

export function AssociadoCertificados() {
    const [loading, setLoading] = React.useState(false);
    const [msgErro, setMsgErro] = React.useState(undefined);
    const [cpf, setCpf] = React.useState(''); 
    const [certificados, setCertificados] = React.useState([]);
  
    const handleChangeMaskOnlyNumbers = function (event) {
        const value = event.target.value.replace(/[^\d]/g, '');;
        var name = event.target.name;
        setCpf(value);
    }

    const erroValida = function (event, campo, msg) {
        alert(msg);
        $('html,body').animate({ scrollTop: $('#' + campo).offset().top - 200 }, 'slow');
        document.getElementById(campo).focus();
        event.preventDefault();
        return false;
    }

    const handleSubmit = function (event) {
        event.preventDefault();
        // Valida campos
        var form = document.getElementById('cadastro');
        if (form.checkValidity() === false) {
            event.stopPropagation();
            form.classList.add('was-validated');
            $('html,body').animate({ scrollTop: $('.was-validated .form-control:invalid').offset().top - 200 }, 'slow');
            return;
        }

        // Valida o CPF
        if (!validCPF(cpf)) {
            return erroValida(event, 'cpf', 'Informe corretamente o CPF!');
        }

        setLoading(true);
        const filtro = { cpf: cpf };
        inscricoesCursosService.listCertificados(filtro).then(data => {
            if (!data || data.length === 0) {
                setMsgErro('Não foram encontrados certificados para o CPF informado.');
            } else {
                setCertificados(data);
            }
            setLoading(false);
        }).catch(function () {
            toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
            setLoading(false);
        });
    }

    const imprimirCertificado = function (idInscr) {
        setLoading(true);
        certificadosService.create(idInscr).then(res => res.json())
            .then(res => { 
                window.location.href = res.link;
            }).catch((ex) => {
                toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
            }).then(function () {
                setLoading(false);
            });
    }

    return (<>
        <div className="register-body">
            <div className="register-wrapper">
                <Container className="vertical-center">
                    <Row>
                        <Col className="ml-auto mr-auto" lg="12">
                            <Card className="card-register">
                                <Card.Header style={{ color: 'black' }}>
                                    <Card.Title as="h6" className="register-form-title">
                                        Regionais - Certificados
                                    </Card.Title>
                                    <br />
                                    <Alert show variant="info">
                                        Utilize o campo abaixo para emissão de Certificados de participação presencial em eventos regionais Sbrafh e de outros
                                        eventos chancelados pela Sbrafh, transmitidos fora da plataforma oficial Sbrafh EAD.
                                    </Alert>
                                </Card.Header>
                                <Card.Body>
                                    <Form id="cadastro" name="cadastro" method="POST" noValidate onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md={12}>
                                                <Alert show={msgErro !== undefined} variant="danger" dismissible onClose={() => { setMsgErro(undefined); }}>
                                                    <span><b> {msgErro} </b></span>
                                                </Alert>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Alert variant="light"> 
                                                    Se você participou de algum evento nas regionais SBRAFH, digite seu CPF na caixa abaixo para busca.
                                                </Alert>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pr-1" md={3}>
                                                <label>CPF:</label>
                                                <MaskedInput id="cpf" placeholder="CPF" name="cpf" mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]} className="form-control numeric"
                                                    required value={cpf} onChange={handleChangeMaskOnlyNumbers}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                                <div className="invalid-feedback">
                                                    Por favor informe o CPF!
                                                </div>
                                            </Col>
                                            <Col md={5}>
                                                <label></label><br />
                                                <Button id="btSend" name="btSend" className="btn btn-success" color="success" type="submit">
                                                    Enviar
                                                    {loading &&
                                                        <> &nbsp;
                                                            <Spinner animation="border" role="status" size="sm">
                                                                <span className="visually-hidden">Carregando...</span>
                                                            </Spinner> </>}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form> 
                                    {certificados && certificados.length > 0 && <>
                                        <Row>
                                            <Col lg={12}>
                                                <Table responsive className="table-hover table-striped" style={{ fontSize: 'smaller' }}>
                                                    <thead>
                                                        <tr>
                                                            <th className="border-0">Inscrição</th>
                                                            <th className="border-0">Data</th>
                                                            <th className="border-0">Tipo</th>
                                                            <th className="border-0">Evento</th>
                                                            <th className="border-0">Imprimir</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            certificados.length > 0 &&
                                                            certificados.map(e => (
                                                                <tr key={e.id}>
                                                                    <td>{String(e.id).padStart(6, '0')}</td>
                                                                    <td>{formatDate(e.data)}</td>
                                                                    <td>{e.tipo}</td>
                                                                    <td>{e.curso}</td>
                                                                    <td>
                                                                        <Button className="btn btn-success btn-fill" onClick={() => { imprimirCertificado(e.id) }} >
                                                                            Imprimir
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                                <Alert variant="warning">
                                                    Se o certificado não constar na tabela acima, entre em contato com <a href="mailto:atendimento@sbrafh.org.br">atendimento@sbrafh.org.br </a>
                                                     informando seu CPF e de qual evento se refere o certificado para verificação.
                                                </Alert>
                                            </Col>
                                        </Row>
                                    </>} 
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    </>
    );
}