import { environment } from '../environment';
import { authHeader } from '../_helpers';

export const financeiroService = {
    listByCadastro
}; 

function listByCadastro(idCadastro) {
    const url = new URL(`${environment.baseURL}/Financeiro/cadastro/${idCadastro}`); 
    return fetch(url.toString(), {
        method: 'GET',
        headers: authHeader()
    });
}