import { environment } from '../environment';
import { authHeaderForm } from '../_helpers';
 
export const fileService = {
    upload,
}; 

function upload (req) {
    var url = new URL(`${environment.baseURL}/File/upload`);
    //url.search = new URLSearchParams(req);

    const formData = new FormData();

    formData.append("path", req.path);
    formData.append("file", req.file);  

    return fetch(url, { method: 'POST', headers: authHeaderForm(), body: formData });
}