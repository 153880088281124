import React from 'react';
import { Container, Row, Col, Card, Table, Alert } from "react-bootstrap";
import { authenticationService, cadastroService, financeiroService, pagseguroService, stripeService } from "../../_services";
import { handleAuthResponse, formatDate, formatFone, parseDateISO } from '../../_helpers';
import { NavLink, useNavigate } from "react-router-dom";
import toastr from 'toastr';

export function HistoricoPagamentos() {
    const [associado, setAssociado] = React.useState(null);
    const [pagamentos, setPagamentos] = React.useState([]);
    const [pagamentosPagSeguro, setPagamentosPagSeguro] = React.useState([]);
    const [pagamentosStripe, setPagamentosStripe] = React.useState([]);

    const navigate = useNavigate();
    React.useEffect(function () {
        const currentUser = authenticationService.loggedUser;

        cadastroService.getById(currentUser.id).then(handleAuthResponse).then(u => {
            setAssociado(u);
            financeiroService.listByCadastro(currentUser.id).then(rr => handleAuthResponse(rr, navigate))
                .then(dd => {
                    setPagamentos(dd);
                });

            pagseguroService.listByCadastro(currentUser.id).then(rr => handleAuthResponse(rr, navigate))
                .then(dd => {
                    setPagamentosPagSeguro(dd);
                });

            stripeService.listByCadastro(currentUser.id).then(rr => handleAuthResponse(rr, navigate))
                .then(dd => {
                    setPagamentosStripe(dd);
                });

        }).catch(e => {
            toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
        });
    }, [navigate]);

    function status(p) {
        if (p.pgto) {
            return 'PAGO'
        }
        return 'PENDENTE';
    }

    function pgto(p) {
        if (p.pgto) {
            return formatDate(p.pgto);
        }
        return '00/00/0000';
    }

    const situacao = function () {
        if (associado.validade) {
            const val = parseDateISO(associado.validade);
            const now = new Date();
            if (val > now) {
                return 'ATIVO - Sua inscrição vence em ' + formatDate(associado.validade);
            }
        }
        return 'VENCIDA';
    }

    return (associado && <>
        <div className="register-body">
            <div className="register-wrapper" style={{ width: '100%' }}>
                <Container className="vertical-center">
                    <Row>
                        <Col className="ml-auto mr-auto" lg="12">
                            <Card className="card-register">
                                <Card.Header style={{ color: 'black' }}>
                                    <Card.Title as="h2" className="text-warning">
                                        Histórico de Pagamentos
                                        <div style={{ float: 'right' }}>
                                            <NavLink className="btn btn-primary btn-sm" to="/associados/area">
                                                Voltar
                                            </NavLink>
                                        </div>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col className="pr-1" lg={12}>
                                            <Table responsive>
                                                {associado && <>
                                                    <tbody >
                                                        <tr >
                                                            <td style={{ color: 'green' }}>Nome: </td>
                                                            <td>{associado.nome}</td>
                                                            <td style={{ color: 'green' }}>E-mail: </td>
                                                            <td>{associado.email}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ color: 'green' }}>Fone: </td>
                                                            <td>{formatFone(associado.fone)}</td>
                                                            <td style={{ color: 'green' }}>Celular: </td>
                                                            <td>{formatFone(associado.fone2)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ color: 'green' }}>Nº de Inscrição: </td>
                                                            <td>{String(associado.idCadastro).padStart(6, '0')}</td>
                                                            <td style={{ color: 'green' }}>Valor Anuidade: </td>
                                                            <td>R$ {associado.valor.toFixed(2)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ color: 'green' }}>Validade: </td>
                                                            <td>{associado.validade && formatDate(associado.validade)}</td>
                                                            <td style={{ color: 'green' }}>Situação: </td>
                                                            <td>{situacao()}</td>
                                                        </tr>
                                                    </tbody>
                                                </>}
                                            </Table>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <Alert show variant="light">Histórico de Pagamentos PAGSEGURO
                                                <p><b>Caso a transação seja cancelada, o link para pagamento estará disponível novamente em "Pagar Anuidade".</b></p>

                                                <Table responsive className="table-hover table-striped" style={{ fontSize: 'smaller' }}>
                                                    <thead>
                                                        <tr>
                                                            <th className="border-0">Data</th>
                                                            <th className="border-0">Referência</th>
                                                            <th className="border-0">F. Pagamento</th>
                                                            <th className="border-0">Parcelas</th>
                                                            <th className="border-0">Valor</th>
                                                            <th className="border-0">Data Pagamento</th>
                                                            <th className="border-0">Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            pagamentosPagSeguro.length > 0 &&
                                                            pagamentosPagSeguro.map(e => (
                                                                <tr key={e.id}>
                                                                    <td>{formatDate(e.dataReg)}</td>
                                                                    <td>{e.refTrans}</td>
                                                                    <td>{e.formaPgto}</td>
                                                                    <td>{e.parcelas}</td>
                                                                    <td>{e.valor && e.valor.toFixed(2)}</td>
                                                                    <td>{formatDate(e.dataPgto)}</td>
                                                                    <td>{e.status}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Alert> 
                                        </Col>
                                    </Row>
                                    {pagamentosStripe && pagamentosStripe.length > 0 &&
                                    <Row>
                                        <Col lg={12}>
                                            <Alert show variant="light">Histórico de Pagamentos Stripe
                                                <p><b>Caso a transação seja cancelada, o link para pagamento estará disponível novamente em "Pagar Anuidade".</b></p>
                                                <Table responsive className="table-hover table-striped" style={{ fontSize: 'smaller' }}>
                                                    <thead>
                                                        <tr>
                                                            <th className="border-0">Data</th>
                                                            <th className="border-0">Referência</th> 
                                                            <th className="border-0">Valor</th>
                                                            <th className="border-0">Data Pagamento</th>
                                                            <th className="border-0">Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            pagamentosStripe.length > 0 &&
                                                            pagamentosStripe.map(e => (
                                                                <tr key={e.id}>
                                                                    <td>{formatDate(e.dataReg)}</td>
                                                                    <td>{e.refTrans}</td> 
                                                                    <td>{e.valor && e.valor.toFixed(2)}</td>
                                                                    <td>{formatDate(e.dataPgto)}</td>
                                                                    <td>{e.status}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Alert> 
                                        </Col>
                                    </Row>
                                    }
                                    <Row>
                                        <Col lg={12}>
                                            <Alert show variant="light">Histórico de Pagamentos Boleto SBRAFH
                                                <Table responsive className="table-hover table-striped" style={{ fontSize: 'smaller' }}>
                                                    <thead>
                                                        <tr>
                                                            <th className="border-0">Data</th>
                                                            <th className="border-0">Num. Doc.</th>
                                                            <th className="border-0">F. Pagamento</th>
                                                            <th className="border-0">Valor</th>
                                                            <th className="border-0">Data Pagamento</th>
                                                            <th className="border-0">Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            pagamentos.length > 0 &&
                                                            pagamentos.map(e => (
                                                                <tr key={e.idFinanceiro}>
                                                                    <td>{formatDate(e.dataLct)}</td>
                                                                    <td>{e.numDoc}</td>
                                                                    <td>BOLETO SBRAFH</td>
                                                                    <td>{e.valor && e.valor.toFixed(2)}</td>
                                                                    <td>{pgto(e)}</td>
                                                                    <td>{status(e)}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>

                                            </Alert>

                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    </>
    );
}