import { authenticationService } from '../_services';

export function authHeader() { 
    const currentUser = authenticationService.loggedUser;
    if (currentUser && currentUser.token) {
        return { Authorization: `Bearer ${currentUser.token}`, 'Accept': 'application/json', 'Content-Type': 'application/json' };
    } else {
        return { 'Accept': 'application/json', 'Content-Type': 'application/json' };
    }
}

export function authHeaderForm() { 
    const currentUser = authenticationService.loggedUser;
    if (currentUser && currentUser.token) {
        return { Authorization: `Bearer ${currentUser.token}`, 'Accept': 'application/json' };
    } else {
        return { 'Accept': 'application/json'  };
    }
}

export function authHeaderDownload() { 
    const currentUser = authenticationService.loggedUser;
    if (currentUser && currentUser.token) {
        return { Authorization: `Bearer ${currentUser.token}`, 'Accept': 'application/octet-stream', 'Content-Type': 'application/octet-stream' };
    } else {
        return { 'Accept': 'application/octet-stream', 'Content-Type': 'application/octet-stream' };
    }
}