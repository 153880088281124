import React from 'react';
import $ from 'jquery';  
import 'assets/css/header.css';

export function Navbar() {
    React.useEffect(function(){
        // Menu Dropdown Toggle
        if($('.menu-trigger').length){
            $(".menu-trigger").on('click', function() {	
                $(this).toggleClass('active');
                $('.header-area .nav').slideToggle(200);
            });
        }

        // Menu elevator animation
        $('.scroll-to-section a[href*=\\#]:not([href=\\#])').on('click', function() {
            if (window.location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && window.location.hostname === this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    var width = $(window).width();
                    if(width < 991) {
                        $('.menu-trigger').removeClass('active');
                        $('.header-area .nav').slideUp(200);	
                    }				
                    $('html,body').animate({
                        scrollTop: (target.offset().top) - 80
                    }, 700);
                    return false;
                }
            }
        });
        
        const dropdownOpener = $('.main-nav ul.nav .has-sub > a');

        // Open/Close Submenus
        if (dropdownOpener.length) {
            dropdownOpener.each(function () {
                var _this = $(this);

                _this.on('tap click', function (e) {
                    var thisItemParent = _this.parent('li'),
                        thisItemParentSiblingsWithDrop = thisItemParent.siblings('.has-sub');

                    if (thisItemParent.hasClass('has-sub')) {
                        var submenu = thisItemParent.find('> ul.sub-menu');

                        if (submenu.is(':visible')) {
                            submenu.slideUp(450, 'easeInOutQuad');
                            thisItemParent.removeClass('is-open-sub');
                        } else {
                            thisItemParent.addClass('is-open-sub');

                            if (thisItemParentSiblingsWithDrop.length === 0) {
                                thisItemParent.find('.sub-menu').slideUp(400, 'easeInOutQuad', function () {
                                    submenu.slideDown(250, 'easeInOutQuad');
                                });
                            } else {
                                thisItemParent.siblings().removeClass('is-open-sub').find('.sub-menu').slideUp(250, 'easeInOutQuad', function () {
                                    submenu.slideDown(250, 'easeInOutQuad');
                                });
                            }
                        }
                    } 
                    e.preventDefault();
                });
            });
        }  
    }, []);
    return (
        <>
            <header className="header-area header-sticky navbar-only-sm">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="main-nav nav-inicial"> 
                                <a href="/inicial" className="logo">
                                SBRAFH
                                </a>  
                                <ul className="nav nav-inicial">
                                    <li className="scroll-to-section"><a href="http://sbrafh.org.br" className="active">Página Inicial</a></li>  
                                    <li className="has-sub"> 
                                        <a href="/#">Menu</a>
                                        <ul className="sub-menu">
                                            <li><a href="/associados/area">Área do Associado</a></li>
                                            <li className="scroll-to-section"><a href="/associados/novo">Associar-se</a></li>   
                                            <li><a href="/admin">Restrito</a></li>
                                        </ul>
                                    </li>
                                </ul>        
                                <a href="#top" className='menu-trigger'>
                                    <span>Menu</span>
                                </a> 
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}