import { authenticationService } from '../_services/authentication.service';

export function handleResponse(response) {
    if (!response.ok) { 
        return Promise.reject(response);
    }
    return response.json();
}  

export function handleAuthResponse(response, navigate) {
    if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
            authenticationService.logout(); 
            navigate('/associados/login');
            return Promise.reject(response);
        } 
        return Promise.reject(response);
    }  
    return response.json();
}  