import React from 'react';
import { Button, Container, Row, Col, Card, Form, Alert } from "react-bootstrap";
import { authenticationService, cadastroService } from "../../_services";
import { handleAuthResponse } from '../../_helpers';
import { NavLink } from "react-router-dom";

export function RegrasAssociado() {
    const [associado, setAssociado] = React.useState(null);

    React.useEffect(function () {
        const currentUser = authenticationService.loggedUser;
        cadastroService.getById(currentUser.id).then(handleAuthResponse).then(u => {
            setAssociado(u);
        });
    }, []);


    return (<>
        <div className="register-body">
            <div className="register-wrapper" style={{ width: '100%' }}>
                <Container className="vertical-center">
                    <Row>
                        <Col className="ml-auto mr-auto" lg="12">
                            <Card className="card-register">
                                <Card.Header style={{ color: 'black' }}>
                                    <Card.Title as="h2" className="text-warning">
                                        Regras do Associado SBRAFH
                                        <div style={{ float: 'right' }}>
                                            <NavLink className="btn btn-primary btn-sm" to="/associados/area">
                                                Voltar
                                            </NavLink>
                                        </div>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col className="pr-1" md={12}>
                                            <Alert show variant="success">
                                                <p>
                                                    <b>TODO ASSOCIADO DA SBRAFH TEM DIREITO A:</b>
                                                </p>
                                                <ol style={{marginLeft: '40px'}}>
                                                    <li>Enviar e receber informações e colaborar com as atividades relacionadas aos fins e objetivos da SBRAFH;</li>
                                                    <li>Participar das Assembléias Gerais, bem como reuniões e oficinas de trabalho, com objetivo profissional ou científico, organizadas pela SBRAFH e obedecidos os requisitos da convocação;</li>
                                                    <li>Participar e acompanhar a gestão da SBRAFH;</li>
                                                    <li>Participar de iniciativas e programas culturais promovidos pela SBRAFH;</li>
                                                    <li>Votar e ser votado para membro da Diretoria, do Conselho Fiscal e das Comissões Permanentes, em conformidade com o estatuto.</li>
                                                </ol>
                                                <br/>
                                                <p>
                                                    <b>TODO ASSOCIADO SBRAFH TEM O DEVER DE:</b>
                                                </p>
                                                <ol style={{marginLeft: '40px'}}>
                                                    <li>Exercer sua atividade de acordo com a melhor prática existente;</li>
                                                    <li>Colaborar com os propósitos da SBRAFH em todas as ocasiões em que estiver presente, fornecendo dados, publicações ou trabalhos e conhecimentos científicos e profissionais;</li>
                                                    <li>Contribuir com a sustentabilidade da SBRAFH, pagando a taxa anual de manutenção de associado;</li>
                                                    <li>Acatar o estatuto, normas e acordos estabelecidos com outras entidades parceiras e com os órgãos de governo e entidades reguladoras da atividade;</li>
                                                    <li>Desempenhar com eficiência os cargos e comissões que lhe forem confiados.</li>
                                                </ol>
                                            </Alert>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    </>
    );
}