export function trim (s) { return s.replace(/^\s+|\s+$/, ''); };
export function onlyNumbers (s){var Digitos="0123456789";var temp="";var digito="";for(var i=0;i<s.length;i++){digito=s.charAt(i);if(Digitos.indexOf(digito)>=0){temp=temp+digito}}return temp}

export function validCPF (cpf) {

if ( (String(cpf) === '11111111111') 
|| (String(cpf) === '22222222222') 
|| (String(cpf) === '33333333333') 
|| (String(cpf) === '44444444444') 
|| (String(cpf) === '55555555555') 
|| (String(cpf) === '66666666666') 
|| (String(cpf) === '77777777777') 
|| (String(cpf) === '88888888888') 
|| (String(cpf) === '99999999999')
|| (String(cpf) === '00000000000')) {return false;}

var i, len, s;s = onlyNumbers(cpf);len=s.length-2;
if ((len !== 9 && len !== 12)) return false;
var c = s.substr(0,len);var dv = s.substr(len,2);var d1 = 0;
for (i = 0; i < len; i++) {if (len===9) d1 += Number(c.charAt(i) * (10 - i)) ;else d1 += Number (c.charAt(11 - i) * (2 + (i % 8)));}
if (d1 === 0) return false;d1 = 11 - (d1 % 11);if (d1 > 9) d1 = 0;
if (Number(dv.charAt(0)) !== d1) return false;
d1 *= 2;for (i = 0; i < len; i++) {if (len < 12) { d1 += Number(c.charAt(i) * (11-i)); }else { d1 += Number(c.charAt(11 - i) * (2 + ((i + 1) % 8)));}}
d1 = 11 - (d1 % 11);if (d1 > 9) d1 = 0;if (Number(dv.charAt(1)) !== d1) return false;return true;
}
export function validCEP (cep){var len,s;s=cep.onlyNumbers();len=s.length;if(len<8)return false;var p=parseInt(s.substr(0,5));if(p<1000)return false;return true;}
export function formatCEP (cep) {var s = cep.replace(/[' '-./ \t]/,'');if(s.length===8)return s.substr(0,5)+'-'+s.substr(5,3);else return s;}
export function validEmail (email){var valid= /^\S+@\S+\.\S+$/;if(email.length===0)return false;if(!valid.test(email))return(false);return true;}
export function validDate (date) { 
	var temp = date.split('/');
	var dia=parseInt(temp[0]);
	var mes=parseInt(temp[1]);
	var ano=parseInt(temp[2]);

	if((mes===4||mes===6||mes===9||mes===11)&&dia > 30)
		return false;

	else if((ano%4)!==0&&mes===2&&dia>28)
		return false;

	else if((ano%4)===0&&mes===2&&dia>29)
		return false;

	else if ((ano < 1900) || (ano > 2050)) 
		return false;

	else if ((mes > 12) || (dia > 31) || (mes === 0) || (dia === 0))
		return false;

	return true;
}

export function stringToDate(_date,_format,_delimiter)
{
	var formatLowerCase=_format.toLowerCase();
	var formatItems=formatLowerCase.split(_delimiter);
	var dateItems=_date.split(_delimiter);
	var monthIndex=formatItems.indexOf("mm");
	var dayIndex=formatItems.indexOf("dd");
	var yearIndex=formatItems.indexOf("yyyy");
	var month=parseInt(dateItems[monthIndex]);
	month-=1;
	var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
	return formatedDate;
}