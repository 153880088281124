import React, { useCallback } from 'react';
import { Button, Container, Row, Col, Card, Alert, Modal } from "react-bootstrap";
import { authenticationService, cadastroService, noticiasRegionaisService } from "../../_services";
import { handleAuthResponse, parseDateISO, formatDate } from '../../_helpers';
import { NavLink, useNavigate, useParams } from "react-router-dom"; 
import toastr from 'toastr';

export function NoticiaRegional () {
    const [associado, setAssociado] = React.useState(null); 
    const navigate = useNavigate();
	const params = useParams();

    const [noticia, setNoticia] = React.useState({ 
        id: 0,
        titulo: '',  
        texto: '', 
        regional: '',
    });  

    const init = useCallback(() => {
        const currentUser = authenticationService.loggedUser; 
        if (currentUser) {
            cadastroService.getById(currentUser.id).then(r => handleAuthResponse(r, navigate)).then(u => { 
                setAssociado(u); 
            }).catch(e => { 
                authenticationService.logout();
                navigate('/associados/login');
            });
        } else {
            authenticationService.logout();
            navigate('/associados/login');
        }

        if (!params.id) {
			navigate('/associados/area');
		}

        if (params.id !== undefined) {
			const ID = params.id;
 
            noticiasRegionaisService.getById(ID).then(r => handleAuthResponse(r, navigate))
                .then(data => { 
                    setNoticia(data);  
                }).catch(ex => {
                    toastr.error('Ocorreu um erro ao carregar a notícia. Por favor tente novamente.');
                    navigate('/associados/area');
            }); 
		} 
    }, [navigate, params.id]);

    React.useEffect(function () {
       init();
    }, [init]);
  
    return (associado && <>
        <div className="register-body">
            <div className="register-wrapper" style={{ width: '100%' }}>
                <Container className="vertical-center">
                    <Row>
                        <Col className="ml-auto mr-auto" lg="12">
                            <Card className="card-register">
                                <Card.Header style={{ color: 'black' }}>
                                    <Card.Title as="h3" className="text-warning">
                                        {noticia.titulo} 
                                        <div style={{ float: 'right' }}>
                                            <NavLink className="btn btn-primary btn-sm" to="/associados/area">
                                                Voltar
                                            </NavLink>
                                        </div> 
                                    </Card.Title>
                                </Card.Header> 
                                <Card.Body>
                                    
                                    <div dangerouslySetInnerHTML={{__html: noticia.texto}}></div>

                                    <hr/>
                                    <NavLink className="btn-link" to="/associados/area">
                                        Voltar
                                    </NavLink>
                                </Card.Body> 
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    </>
    );
}