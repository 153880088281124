import React from "react";
import { Topbar } from './Topbar';
import { Navbar } from './Navbar';
import { Footer } from './Footer';
import { SectionNoticiasTopo } from "./SectionNoticiasTopo";
import { SectionPrincipal } from './SectionPrincipal';
import { environment } from '../../environment';

import 'assets/css/style.css';  

export function Inicial() {
  const [eventos, setEventos] = React.useState([]);

  React.useEffect(function() {
    fetch(`${environment.baseURL}/Evento`, { method: 'GET', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            .then(async (res) => { 
                if (!res.ok) {
                    return Promise.reject(res);
                }
                const data = await res.json();
                setEventos(data);
            }).catch((ex) => {
                console.log(ex);
            });
  }, []);

  return (
    <>
      <Topbar />
      <Navbar />
      <section style={{height:'100px'}}></section>  
      <section style={{height:'100px'}}></section>  
      <section style={{height:'100px'}}></section>  
      <section style={{height:'100px'}}></section>  
      <section style={{height:'100px'}}></section>  
      <section style={{height:'100px'}}></section>    
      <Footer />
    </>
  );
}
