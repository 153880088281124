import React from "react";
import { Button, Card, Table, Container, Row, Col, Alert, Pagination } from "react-bootstrap";
import { ModalConfirm } from "../../../components/Modals";
import { NavLink } from "react-router-dom";
import { cursosRegionaisService, inscricoesCursosService } from "../../../_services";
import { formatDate } from "../../../_helpers";
import toastr from 'toastr';

export default function IndexCursosRegionais({ props }) {
  const [showConfirm, setShowConfirm] = React.useState(false); 
  const [loading, setLoading] = React.useState(false);
  const [pages, setPages] = React.useState([]);
  const [cursosRegionais, setCursosRegionais] = React.useState([]);   
  const [inscr, setInscr] = React.useState(true);
  
  const [filtro, setFiltro] = React.useState({
    tituloEvento: '',
    regional: '',
    pageIndex: 0,
    pageSize: 20,
    totalRows: 0,
  }); 

  const aplicarFiltros = function () { 
    cursosRegionaisService.filter(filtro).then((res) => {
      filtro.totalPages = (res.totalPages);
      filtro.totalRows = (res.totalRows);
      setCursosRegionais(res.data);
    }).then(() => { 
      setLoading (false); 
      if (inscr) 
      {
        setFiltro((ev) => {
          return ev = { ...ev, pageIndex: 0 }
        });
        loadNumInscr();
        setInscr (false);
      }
     }).catch((ex) => {
      toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
    });
  } 

  const loadNumInscr = function () {
    cursosRegionais.forEach((ce, i) => {
      const r = {
        regional: ce.regional,
        data: ce.data,
      };
      inscricoesCursosService.count(r).then(res => {
        let cursos = [...cursosRegionais];
        cursos[i].inscritos = res;
        setCursosRegionais(cursos); 
      });
    
       new Promise(r => setTimeout(r, 50)); 
    });
  }
  
  React.useEffect (function() {
    loadNumInscr();
  }, [filtro, filtro.pageIndex]);
  
  React.useEffect(function () {
    aplicarFiltros();  
  }, []);

  React.useEffect(function () { 
    let items = [];
    const totalPagesMax = Math.min(filtro.totalPages, filtro.pageIndex + 11);
    items.push(<Pagination.First key="first" disabled={filtro.pageIndex + 1 === 1} onClick={
      () => {
        filtro.pageIndex = 0; 
        aplicarFiltros();
      }} />);
    items.push(<Pagination.Prev key="prev" disabled={filtro.pageIndex + 1 === 1} onClick={
      () => {
        filtro.pageIndex = filtro.pageIndex - 1; 
        aplicarFiltros();
      }} />);
    for (let number = Math.max(1, filtro.pageIndex - 6); number <= totalPagesMax; number++) {
      items.push(
        <Pagination.Item key={number} active={number - 1 === filtro.pageIndex} onClick={
          () => {
            filtro.pageIndex = number - 1; 
            aplicarFiltros();
          }
        }>
          {number}
        </Pagination.Item>,
      );
    }
    items.push(<Pagination.Next key="next" disabled={filtro.pageIndex + 1 === filtro.totalPages || filtro.totalRows === 0} onClick={
      () => {
        filtro.pageIndex = filtro.pageIndex + 1; 
        aplicarFiltros();
      }} />);
    items.push(<Pagination.Last key="last" disabled={filtro.pageIndex + 1 === filtro.totalPages || filtro.totalRows === 0} onClick={
      () => {
        filtro.pageIndex = filtro.totalPages - 1; 
        aplicarFiltros();
      }} />);
    setPages(items);
  }, [filtro.pageIndex, filtro.totalPages]);

  return (
    <>
    <ModalConfirm message="Deseja excluir o registro?" showModal={showConfirm} onHide={() => {setShowConfirm(false)}} />
      <Container fluid>
        <Row>
          <Col md="10">
            <Card className="striped-tabled-with-hover no-transition">
            {loading &&
                <div className="loading-overlay d-flex justify-content-center align-text-center" >
                  <div className="loading-spinner "></div>
                  <br />
                  <div className="loading-text">Carregando...</div>
                </div>
              }
              <Card.Header>
                <Card.Title as="h4">Agenda de Eventos Regionais</Card.Title>
                <div style={{ float: 'right' }}>
                  <NavLink className="btn btn-success btn-sm" to="/admin/cursos-regionais/novo">
                    Novo Registro
                  </NavLink>
                </div>
              </Card.Header>
              <Card.Body className="table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">Data</th>
                      <th className="border-0">Reg.</th>
                      <th className="border-0">Título</th>
                      <th className="border-0">Data Máxima</th>
                      <th className="border-0">Del.</th>
                      <th className="border-0">Inscr.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      cursosRegionais &&
                      cursosRegionais.map((e, i) => (
                        <tr key={e.id}>
                          <td>
                            <NavLink className="btn-simple btn-link p-1 info" to={'/admin/cursos-regionais/' + e.id} >
                              {formatDate(e.data)}
                            </NavLink>
                          </td>
                          <td>{e.regional}</td>
                          <td>{e.titulo}</td>
                          <td>{formatDate(e.dataMaxima)}</td>
                          <td> <Button className="btn-simple btn-link p-1" type="button" variant="danger" onClick={() => {setShowConfirm(true)}} >
                            <i className="pe-7s-close"></i>
                          </Button></td>
                          <td>{e.inscritos}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                <Pagination size="sm">{pages}</Pagination>
              </Card.Body>
            </Card>
            <Alert variant="secondary">
              {
                (cursosRegionais.length === 0) && <>
                  <b>Nenhum resultado encontrado.</b> </>
              }
              {
                (cursosRegionais.length > 0) && <>
                  <b>Exibindo resultados: {filtro.pageSize * (filtro.pageIndex) + 1} a {((filtro.pageIndex) * filtro.pageSize  + Math.min(cursosRegionais.length, filtro.pageSize))} de {filtro.totalRows}.</b> </>
              }
            </Alert>
          </Col>
        </Row>
      </Container>
    </>
  );
}