import { environment } from '../environment';

export const regionaisService = {
    list,
    getByUF
};


function getByUF(UF) {
    return fetch(`${environment.baseURL}/Regionais/uf/${UF}`, { method: 'GET', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } });
}

function list() {
    return fetch(`${environment.baseURL}/Regionais`, { method: 'GET', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } })
        .then(async (res) => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            const data = await res.json();
            return data;
        });
}