
import React from "react";
import { Button, Card, Table, Container, Row, Col, Alert, Pagination } from "react-bootstrap";
import { ModalConfirm } from "../../../components/Modals";
import { NavLink, useNavigate } from "react-router-dom";
import { noticiasRegionaisService, authenticationService, cadastroService } from "../../../_services"; 
import { formatDate } from "../../../_helpers";
import toastr from 'toastr'; 
import { handleAuthResponse, handleResponse } from '../../../_helpers/handle-response.js'; 

export default function IndexNoticiasRegionais() {
  const [showConfirm, setShowConfirm] = React.useState(false); 
  const [pages, setPages] = React.useState([]);
  const [noticiasRegionais, setNoticiasRegionais] = React.useState([]);  
  const [loading, setLoading] = React.useState([]); 
  const [selected, setSelected] = React.useState(undefined); 
  const [usuario, setUsuario] = React.useState(null);

	const navigate = useNavigate();

  const [filtro, setFiltro] = React.useState({
    titulo: '',
    regional: '',
    pageIndex: 0,
    pageSize: 10,
    totalRows: 0,
  });

  const aplicarFiltros = function () {  
    setLoading(true);
    noticiasRegionaisService.filter(filtro).then((res) => {
      setNoticiasRegionais(res.data);

      filtro.totalPages = (res.totalPages);
      filtro.totalRows = (res.totalRows);
       
    }).catch(() => {
      toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
    }).then(function () {
      setLoading(false);
    });
  } 

  React.useEffect(function () {
    if (usuario) {
      setFiltro((f) => {
        return f = { ...f, regional: usuario.regional }
      });  
    }
  }, [usuario]);

  React.useEffect(function () {
    if (usuario) {
      aplicarFiltros(); 
    }
  }, [filtro]);
 
  React.useEffect(function () {
    const user = authenticationService.loggedUser; 
    cadastroService.getById(user.id).then(r => handleAuthResponse(r, navigate)).then((data) => {
        setUsuario (data); 
    }).catch(() => {  
        toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
    }); 
  }, []);

  const getRegional = function (noticia) {
    if (noticia.regional === '') {
      return 'Todas';
    } else {
      return noticia.regional;
    }
  }

  const onConfirmExcluir = function () {
    setShowConfirm(false);
    if (selected) {
        noticiasRegionaisService.remove(selected).then((res) => {
            toastr.success('Notícia excluída com sucesso.');
            aplicarFiltros(); 
        }).catch(() => {
        toastr.error('Ocorreu um erro ao excluir. Por favor tente novamente.');
        }).then(function () {
            setLoading(false);
            setSelected(undefined)
        }); 
    }
  }

  React.useEffect(function () {
    let items = [];
    const totalPagesMax = Math.min(filtro.totalPages, filtro.pageIndex + 11);
    items.push(<Pagination.First key="first" disabled={filtro.pageIndex + 1 === 1} onClick={
      () => {
        filtro.pageIndex = 0; 
        aplicarFiltros();
      }} />);
    items.push(<Pagination.Prev key="prev" disabled={filtro.pageIndex + 1 === 1} onClick={
      () => {
        filtro.pageIndex = filtro.pageIndex - 1; 
        aplicarFiltros();
      }} />);
    for (let number = Math.max(1, filtro.pageIndex - 6); number <= totalPagesMax; number++) {
      items.push(
        <Pagination.Item key={number} active={number - 1 === filtro.pageIndex} onClick={
          () => {
            filtro.pageIndex = number - 1; 
            aplicarFiltros();
          }
        }>
          {number}
        </Pagination.Item>,
      );
    }
    items.push(<Pagination.Next key="next" disabled={filtro.pageIndex + 1 === filtro.totalPages || filtro.totalRows === 0} onClick={
      () => {
        filtro.pageIndex = filtro.pageIndex + 1; 
        aplicarFiltros();
      }} />);
    items.push(<Pagination.Last key="last" disabled={filtro.pageIndex + 1 === filtro.totalPages || filtro.totalRows === 0} onClick={
      () => {
        filtro.pageIndex = filtro.totalPages - 1; 
        aplicarFiltros();
      }} />);
    setPages(items);
  }, [filtro.pageIndex, filtro.totalPages]);

  return (
    <>
    <ModalConfirm message="Deseja excluir a notícia?" showModal={showConfirm} onHide={() => {setShowConfirm(false)}} onConfirm={() => onConfirmExcluir()} />
      <Container fluid>
        <Row>
          <Col md="10">
            <Card className="striped-tabled-with-hover no-transition">
            {loading &&
                <div className="loading-overlay d-flex justify-content-center align-text-center" >
                  <div className="loading-spinner "></div>
                  <br />
                  <div className="loading-text">Carregando...</div>
                </div>
              }
              <Card.Header>
                <Card.Title as="h4">Notícias Regionais</Card.Title>
                <br/>
                <div>
                  <NavLink className="btn btn-success btn-sm" to="/admin/noticias-regionais/novo">
                    Nova Notícia
                  </NavLink>
                </div>
              </Card.Header>
              <Card.Body className="table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">Data</th>
                      <th className="border-0">Reg.</th>
                      <th className="border-0">Título</th> 
                      <th className="border-0">Excluir</th> 
                    </tr>
                  </thead>
                  <tbody>
                    {
                      noticiasRegionais &&
                      noticiasRegionais.map((e, i) => (
                        <tr key={e.id} style={{cursor: 'pointer'}} onClick={() => { navigate('/admin/noticias-regionais/' + e.id); }}>
                          <td style={{width: '80px'}}> 
                              {formatDate(e.data)} 
                          </td>
                          <td style={{width: '80px'}}>{getRegional(e)}</td>
                          <td>{e.titulo}</td> 
                          <td> 
                            <Button className="btn-simple btn-link " type="button" variant="danger" onClick={(event) => {event.stopPropagation(); setShowConfirm(true); setSelected(e.id)}} >
                              <i className="pe-7s-close"></i>
                            </Button>
                          </td> 
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                <Pagination size="sm">{pages}</Pagination>
              </Card.Body>
            </Card>
            <Alert variant="secondary">
              {
                (noticiasRegionais.length === 0) && <>
                  <b>Nenhum resultado encontrado.</b> </>
              }
              {
                (noticiasRegionais.length > 0) && <>
                  <b>Exibindo resultados: {filtro.pageSize * (filtro.pageIndex) + 1} a {((filtro.pageIndex) * filtro.pageSize + Math.min(noticiasRegionais.length, filtro.pageSize))} de {filtro.totalRows}.</b> </>
              }
            </Alert>
          </Col>
        </Row>
      </Container>
    </>
  );
}