import { environment } from '../environment'; 
import { authHeader, authHeaderDownload } from '../_helpers';

export const relatoriosService = {
    associadosAtivos,
    financeiro,
    associadosAtivosExcel,
};

function associadosAtivos() {
    return fetch(`${environment.baseURL}/Relatorios/AssociadosAtivos/`
        , { method: 'GET', headers: authHeader() });
} 

function associadosAtivosExcel() {  
    const url = new URL(`${environment.baseURL}/Relatorios/AssociadosAtivos/excel`);
    return fetch(url.toString(), {
        method: 'GET',
        headers: authHeaderDownload()
    }); 
} 

function financeiro() {
    return fetch(`${environment.baseURL}/Relatorios/Financeiro/`
        , { method: 'GET', headers: authHeader() });
} 