import React from 'react';
import $ from 'jquery';
import { format } from 'date-fns';
import { Button, Container, Row, Col, Card, Form, Spinner } from "react-bootstrap";
import { useNavigate, NavLink, useSearchParams } from 'react-router-dom';
import toastr from 'toastr';
import { validCPF, validEmail } from '../../../prototypes.js';
import { estadoService, cadastroService, cursosRegionaisService } from '../../../_services';
import { formatDate, formatMoney } from '../../../_helpers';
import { inscricoesCursosService } from '../../../_services/inscricoes-cursos.service.js';

export function CriarInscricaoForm() {
    const [loading, setLoading] = React.useState(false);
    const [curso, setCurso] = React.useState({});
    const [estados, setEstados] = React.useState([]);
    const [origens, setOrigens] = React.useState([]);
    const [inscricao, setInscricao] = React.useState({
        cel: '',
        cep: '',
        cidade: '',
        bairro: '',
        compl: '',
        cpf: '',
        crf: '',
        curso: '',
        data: '',
        email: '',
        ender: '',
        enderInst: '',
        fila: 0,
        fone: '',
        foneInst: '',
        formacao: '',
        instituicao: '',
        nome: '',
        obs: '',
        origem: '',
        regional: '',
        status: 0,
        tipo: 'PARTICIPANTE',
        tipoInstituicao: '',
        idCadastro: 0,
        uf: '',
        valor: 0.0,
    });

    const navigate = useNavigate(); 
    const [searchParams] = useSearchParams();

    React.useEffect(function () {
        getEstados();

        const UF = searchParams.get('uf');
        const data = searchParams.get('data');

        if (UF && data) {
            var filtro = {
                regional: String(UF).toUpperCase(),
                data: format(new Date(String(data)), 'MM/dd/yyyy'),
                pageIndex: 0,
                pageSize: 1
            };
            cursosRegionaisService.filter(filtro).then((res) => {
                setCurso(res.data[0]);
            }).catch(function () {
                toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
            });
        }

        return function () {
            document.body.classList.remove("register-page");
        }
    }, [searchParams]);

    React.useEffect(function () {
        setInscricao((i) => {
            return i = {
                ...i
                , curso: curso.titulo
                , data: curso.data
                , regional: curso.regional
            };
        });
    }, [curso]);

    const getEstados = function () {
        estadoService.list().then(data => {
            setEstados(data)
        }).catch((ex) => {
            console.log(ex);
        });
    }

    const handleChange = function (event) {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        var name = event.target.name;
        setInscricao((a) => {
            return a = { ...a, [name]: value }
        });
    }

    const erroValida = function (event, campo, msg) {
        alert(msg);
        $('html,body').animate({ scrollTop: $('#' + campo).offset().top - 200 }, 'slow');
        document.getElementById(campo).focus();
        event.preventDefault();
        return false;
    }

    const handleSubmit = function (event) {
        event.preventDefault();
        // Valida campos
        var form = document.getElementById('cadastro');
        if (form.checkValidity() === false) {
            event.stopPropagation();
            form.classList.add('was-validated');
            $('html,body').animate({ scrollTop: $('.was-validated .form-control:invalid').offset().top - 200 }, 'slow');
            return;
        }

        // Valida o CPF
        if (!validCPF(inscricao.cpf)) {
            return erroValida(event, 'cpf', 'Informe corretamente o CPF!');
        }

        // email
        if (!validEmail(inscricao.email)) {
            return erroValida(event, 'email', 'Informe corretamente o E-Mail!');
        }

        setLoading(true);

        const req = {
            ...inscricao,
            origem: origens.join(', '),
            dataReg: new Date().toISOString(),
        }; 
        inscricoesCursosService.create(req).then((res) => {
            toastr.success('Salvo com sucesso.');
            setLoading(false);
            navigate(-1);
        }).catch(function () {
            setLoading(false);
        });
    }

    const handleChangeOrigem = function (event) {
        var a = origens;
        const value = event.target.value;
        const checked = event.target.checked;
        if (checked) {
            a.push(value);
        } else {
            a = origens.filter(f => { return f !== value; });
        }
        setOrigens(a);
    }

    const criaOrigem = function () {
        const origens = [
            'Email Marketing',
            'Site SBRAFH',
            'Redes Sociais',
            'Palestras',
            'Colega/Amigo',
            'Professor',
            'Outros meios',
        ];
        return (<> <Row>
            {origens.map((o, i) => (
                <Col lg="3" key={i}>
                    <Form.Check inline key={i} type="checkbox" name="origem[]" id={`origem-${i}`}
                        value={o} label={o} onChange={handleChangeOrigem} >
                    </Form.Check>
                </Col>))}
        </Row></>);
    }

    const buscar = function () {
        const cpf = inscricao.cpf;
        if (cpf) {
            setLoading(true);
            cadastroService.filter ({ nomeCpf: cpf, pageIndex: 0, pageSize: 1 }).then(res => {
                const cadastro = res.data[0];
                if (cadastro) {
                    setInscricao((i) => {
                        return i = {
                            ...i,
                            nome: cadastro.nome,
                            ender: cadastro.ender + ', ' + cadastro.num,
                            bairro: cadastro.bairro,
                            cidade: cadastro.cidade,
                            compl: cadastro.compl,
                            email: cadastro.email,
                            fone: cadastro.fone,
                            cel: cadastro.fone2,
                            uf: cadastro.uf,
                            crf: cadastro.crf,
                            instituicao: cadastro.instituicao,
                            cep: cadastro.cep,
                            status: cadastro.status,
                            idCadastro: cadastro.idCadastro,
                        }
                    });
                } else {
                    toastr.error('CPF não encontrado.');
                }
                setLoading (false);
            }).catch(function() {
                toastr.error('Erro');
            });
        }
    }

    return (<>
        <div className="register-body">
            <div className="register-wrapper" style={{ width: '100%' }}>
                <Container className="vertical-center">
                    <Row>
                        <Col className="ml-auto mr-auto" lg="12">
                            <Card className="card-register">
                                <Card.Header style={{ color: 'black' }}>
                                    <Card.Title as="h4">Nova Inscrição - {curso.titulo} - {curso.regional}
                                        <div style={{ float: 'right' }}>
                                            <NavLink className="btn btn-primary btn-sm"
                                                to={`/admin/cursos-regionais/inscricoes/?uf=${searchParams.get('uf')}&data=${searchParams.get('data')}`}>
                                                Voltar
                                            </NavLink>
                                        </div>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form id="cadastro" name="cadastro" method="POST" noValidate onSubmit={handleSubmit}>
                                        <Row>
                                            <Col className="pr-1" md={4}>
                                                <label>CPF: </label>
                                                <Form.Control type="text" placeholder="CPF" maxLength="15" id="cpf" name="cpf"
                                                    required value={inscricao.cpf} onChange={handleChange} />
                                                <div className="invalid-feedback">
                                                    Por favor informe o CPF!
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <label></label> <br />
                                                <Button className="btn" variant="info" onClick={() => { buscar() }}>Buscar  {loading &&
                                                    <> &nbsp;
                                                        <Spinner animation="border" role="status" size="sm">
                                                            <span className="visually-hidden">Carregando...</span>
                                                        </Spinner> </>}</Button>
                                            </Col>
                                            <Col className="pr-1" md={4}>
                                                <label>CEP: </label>
                                                <Form.Control type="text" id="cep" maxLength="12" placeholder="CEP" name="cep" className="form-control"
                                                    value={inscricao.cep} onChange={handleChange}
                                                    onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12}>
                                                <label>Nome: </label>
                                                <Form.Control type="text" placeholder="Nome" maxLength="254" id="nome" name="nome"
                                                    required value={inscricao.nome} onChange={handleChange} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={8}>
                                                <label>Endereço: </label>
                                                <Form.Control type="text" placeholder="Endereço" maxLength="254" id="ender" name="ender"
                                                    required value={inscricao.ender} onChange={handleChange} />
                                            </Col>
                                            <Col lg={4}>
                                                <label>Complemento: </label>
                                                <Form.Control type="text" placeholder="Complemento" maxLength="254" id="compl" name="compl"
                                                    value={inscricao.compl} onChange={handleChange} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={4}>
                                                <label>Bairro: </label>
                                                <Form.Control type="text" placeholder="Bairro" maxLength="254" id="bairro" name="bairro"
                                                    required value={inscricao.bairro} onChange={handleChange} />
                                            </Col>
                                            <Col lg={4}>
                                                <label>Cidade: </label>
                                                <Form.Control type="text" placeholder="Cidade" maxLength="254" id="cidade" name="cidade"
                                                    required value={inscricao.cidade} onChange={handleChange} />
                                            </Col>
                                            <Col lg={4}>
                                                <label>Estado: </label>
                                                <Form.Select name="uf" id="uf" size="1" className="form-control" required value={inscricao.uf} onChange={handleChange}>
                                                    <option value="">Selecione</option>
                                                    {estados.map(e => (
                                                        <option key={e.id} value={e.sigla} >
                                                            {e.sigla} - {e.estado}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                                <div className="invalid-feedback">
                                                    Por favor selecione o estado!
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={8}>
                                                <label>E-mail: </label>
                                                <Form.Control type="text" placeholder="E-mail" maxLength="254" id="email" name="email"
                                                    required value={inscricao.email} onChange={handleChange} />
                                            </Col>
                                            <Col lg={4}>
                                                <label>CRF-UF: </label>
                                                <Form.Control type="text" placeholder="CRF" maxLength="30" id="crf" name="crf"
                                                    value={inscricao.crf} onChange={handleChange} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6}>
                                                <label>DDD/Telefone: </label>
                                                <Form.Control type="text" placeholder="Telefone" maxLength="60" id="fone" name="fone"
                                                    value={inscricao.fone} onChange={handleChange} />
                                            </Col>
                                            <Col lg={6}>
                                                <label>DDD/Celular: </label>
                                                <Form.Control type="text" placeholder="Celular" maxLength="30" id="cel" name="cel"
                                                    required value={inscricao.cel} onChange={handleChange} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12}>
                                                <label>Instituição: </label>
                                                <Form.Control type="text" placeholder="Instituição" maxLength="254" id="instituicao" name="instituicao"
                                                    value={inscricao.instituicao} onChange={handleChange} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12}>
                                                <label>Tipo: </label>
                                                <Form.Select id="tipoInstituicao" name="tipoInstituicao" className="form-control"
                                                    value={inscricao.tipoInstituicao} onChange={handleChange}>
                                                    <option value='HOSPITAL PÚBLICO DE ENSINO/UNIVERSITÁRIO'>HOSPITAL PÚBLICO DE ENSINO/UNIVERSITÁRIO</option>
                                                    <option value='HOSPITAL PÚBLICO'>HOSPITAL PÚBLICO</option>
                                                    <option value='HOSPITAL PRIVADO SEM FINS LUCRATIVOS'>HOSPITAL PRIVADO SEM FINS LUCRATIVOS (Santas Casas, etc.)</option>
                                                    <option value='OUTROS'>OUTROS</option>
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12}>
                                                <label>Endereço: </label>
                                                <Form.Control cols="80" placeholder="Ender. Instituição" rows="2" as="textarea" id="enderInst" name="enderInst"
                                                    value={inscricao.enderInst} onChange={handleChange}></Form.Control>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12}>
                                                <label>DDD/Telefone(s): </label>
                                                <Form.Control type="text" placeholder="Telefone Instituição" maxLength="254" id="foneInst" name="foneInst"
                                                    value={inscricao.foneInst} onChange={handleChange} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col><hr/></Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12}>
                                                Como soube: <br/>
                                                {criaOrigem()}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <hr />
                                        </Row>
                                        <Row>
                                            <Col lg={12}>
                                                Evento: {curso.titulo} <br />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6}>
                                                Data: {formatDate(curso.data)}
                                            </Col>
                                            <Col lg={6}>
                                                Tipo: ASSOCIADO INATIVO
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6}>
                                                <label>Formação: </label>
                                                <Form.Select id="formacao" name="formacao" className="form-control"
                                                    value={inscricao.formacao} onChange={handleChange} required>
                                                    <option value='PROFISSIONAL'>PROFISSIONAL</option>
                                                    <option value='EST. GRADUAÇÃO: FARMÁCIA'>EST. GRADUAÇÃO: FARMÁCIA</option>
                                                </Form.Select>
                                            </Col>
                                            <Col lg={6}>
                                                <label>Valor R$: </label>
                                                <Form.Control type="text" placeholder="Valor" maxLength="254" id="valor" name="valor"
                                                    required value={formatMoney(inscricao.valor)} onChange={handleChange} />
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col sm={12}>
                                                <center>
                                                    <Button id="btSend" name="btSend" className="btn-round btn-fill btn-primary" color="primary" type="submit">
                                                        Enviar Inscrição
                                                        {loading &&
                                                            <> &nbsp;
                                                                <Spinner animation="border" role="status" size="sm">
                                                                    <span className="visually-hidden">Carregando...</span>
                                                                </Spinner> </>}
                                                    </Button>
                                                </center>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row >
                </Container >
            </div >
        </div >
    </>);
}