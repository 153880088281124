import React from 'react';
import $ from 'jquery';
import MaskedInput from 'react-text-mask';
import { Button, Container, Row, Col, Card, Form, Alert, Spinner, InputGroup } from "react-bootstrap";
import { useParams, useNavigate, NavLink } from 'react-router-dom';
import toastr from 'toastr';
import { validCPF, validDate, validEmail } from '../../prototypes.js';
import { estadoService, formacaoService, cadastroService, atuacaoService, authenticationService } from '../../_services';
import { formatDate, parseDate, handleAuthResponse, formatCPF } from '../../_helpers';

export function NovoAssociadoForm() {
	const [editing, setEditing] = React.useState(false);
	const [areas, setAreas] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [estados, setEstados] = React.useState([]);
	const [formacoes, setFormacoes] = React.useState([]);
	const [atuacao, setAtuacao] = React.useState([]);
	const [msgErro, setMsgErro] = React.useState(undefined);
	const [associado, setAssociado] = React.useState({
		nome: '',
		sexo: '1',
		dataNcto: '',
		naturalidade: '',
		cpf: '',
		crf: '',
		crf_uf: '',
		ender: '',
		num: '',
		compl: '',
		cep: '',
		bairro: '',
		cidade: '',
		uf: '',
		pais: '',
		fone: '',
		fone2: '',			// celular
		email: '',
		formacao: '',
		areaAtuacao: '',
		instituicao: '',
		hfone1: '',			// fone inst.
		senha: '',
		confirme: ''
	});

	const navigate = useNavigate();
	const params = useParams();

	React.useEffect(function () {
		getEstados();
		getFormacoes();
		getAreas();

		if (!params.id && authenticationService.loggedUser) {
			navigate('/associados/area');
		}
		if (params.id !== undefined) {
			const ID = params.id;

			if (!authenticationService.loggedUser || (ID !== String(authenticationService.loggedUser.id))) {
				navigate('/associados/area');
			} else {

				cadastroService.getById(ID).then(r => handleAuthResponse(r, navigate))
					.then(data => {
						Object.keys(data).forEach(key => (associado[key] !== undefined && (data[key] === undefined || data[key] === null)) ? data[key] = '' : {});

						setAssociado(data);
						setAssociado((a) => {
							return a = {
								...a, dataNcto: formatDate(data.dataNcto)
							}
						});
						setEditing(true);

					}).catch(ex => {
						toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
						navigate('/associados/area');
					});
			}
		}

		return function () {
			document.body.classList.remove("register-page");
		}
	}, [params.id, navigate]);

	const getAreas = function () {
		atuacaoService.list().then(data => {
			setAreas(data)
		}).catch((ex) => {
			console.log(ex);
		});
	}

	const getEstados = function () {
		estadoService.list().then(data => {
			setEstados(data)
		}).catch((ex) => {
			console.log(ex);
		});
	}

	const getFormacoes = function () {
		formacaoService.list().then(data => {
			setFormacoes(data)
		}).catch((ex) => {
			console.log(ex);
		});
	}

	const handleChange = function (event) {
		const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		var name = event.target.name;
		setAssociado((a) => {
			return a = { ...a, [name]: value }
		});
	}

	const handleChangeMaskOnlyNumbers = function (event) {
		const value = event.target.value.replace(/[^\d]/g, '');;
		var name = event.target.name;
		setAssociado((a) => {
			return a = { ...a, [name]: value }
		});
	}

	const handleChangeAtuacao = function (event) {
		var a = atuacao;
		const value = event.target.value;
		const checked = event.target.checked;
		if (checked) {
			a.push(value);
		} else {
			a = atuacao.filter(f => { return f !== value; });
		}
		setAtuacao(a);
	}

	const erroValida = function (event, campo, msg) {
		alert(msg);
		$('html,body').animate({ scrollTop: $('#' + campo).offset().top - 200 }, 'slow');
		document.getElementById(campo).focus();
		event.preventDefault();
		return false;
	}

	const handleSubmit = function (event) {
		event.preventDefault();
		// Valida campos
		var form = document.getElementById('cadastro');
		if (form.checkValidity() === false) {
			event.stopPropagation();
			form.classList.add('was-validated');
			$('html,body').animate({ scrollTop: $('.was-validated .form-control:invalid').offset().top - 200 }, 'slow');
			return;
		}

		// Valida o CPF
		if (!validCPF(associado.cpf)) {
			return erroValida(event, 'cpf', 'Informe corretamente o CPF!');
		}

		// data de nascimento
		if (associado.dataNcto.replace(/[^\d]/g, '').length !== 8 || !validDate(associado.dataNcto)) {
			return erroValida(event, 'dataNcto', 'Informe corretamente a Data de Nascimento!');
		}
		// Celular
		if (associado.fone2.length !== 11) {
			return erroValida(event, 'fone2', 'Informe corretamente o celular!');
		}

		// CEP
		if (associado.cep.length !== 8) {
			return erroValida(event, 'cep', 'Informe corretamente o CEP!');
		}

		// email
		if (!validEmail(associado.email)) {
			return erroValida(event, 'email', 'Informe corretamente o E-Mail!');
		}

		// Senha 
		if ((!editing) && associado.senha !== associado.confirme) {
			return erroValida(event, 'confirme', 'As duas senhas devem ser iguais!');
		}

		if (associado.fone === '(__) ____-____')
		{
			associado.fone = '';
		}

		setLoading(true);

		if (editing) {
			let req = {
				...associado,
				areaAtuacao: String(atuacao.sort().join('|')),
				dataNcto: parseDate(associado.dataNcto)
			};
			cadastroService.update(req).then((res) => {
				setLoading(false);
				toastr.success('Seus dados foram alterados com sucesso.');
				navigate('/associados/area');
			}).catch(function (err) { 
				setLoading(false);
				setMsgErro('Ocorreu um erro ao salvar os dados. Por favor tente novamente.');
			});
		} else {
			let req = {
				...associado,
				areaAtuacao: String(atuacao.sort().join('|')),
				dataReg: new Date().toISOString(),
				dataNcto: parseDate(associado.dataNcto)
			};
			cadastroService.create(req).then(function (res) {
				if (res.status === 409) {
					setMsgErro(`O CPF ${formatCPF(associado.cpf)} já existe em nossa base de dados! Por favor efetue seu login para alterar seu cadastro.`);
					setLoading(false);
				} else if (res.ok) { 
					const reqLogin = { email: associado.email, senha: associado.senha };
					authenticationService.login(JSON.stringify(reqLogin)).then(() => { 
						setLoading(false); 
						navigate("/associados/area");  
					});
				}
			}).catch(function () {
				setLoading(false);
				setMsgErro('Ocorreu um erro ao salvar os dados. Por favor tente novamente.');
			});
		}
	}

	const criaAtuacao = function () {
		return (<>
			<Row>
				{areas.map((a) => (
					<Col lg="4" sm="4" key={a.id}>
						<Form.Check inline key={a.id} type="checkbox" name="atuacao[]" id={`atuacao-${a.id}`}
							value={a.id} label={a.descricao} onChange={handleChangeAtuacao}   >
						</Form.Check>
					</Col>
				))}
			</Row></>);
	}

	return (
		<>
			<div className="register-body">

				<div className="register-wrapper">
					<Container className="vertical-center">
						<Row>
							<Col className="ml-auto mr-auto" lg="12">
								<Card className="card-register">
									<Card.Header style={{ color: 'black' }}>

										<Card.Title as="h4" className="register-form-title">Cadastro de associados {editing && <><div style={{ float: 'right' }}>
											<NavLink className="btn btn-primary btn-sm" to="/associados/area">
												Voltar
											</NavLink>
										</div></>} </Card.Title>
										{!editing && <>
											<br />
											<p>
												Preencha o cadastro abaixo e após o envio e gravação dos seus dados, clique no link do PAGSEGURO no
												final da tela e escolha uma forma de pagamento. Os valores para associados são: <b>R$ 175,00</b> para Farmacêuticos
												ou <b>R$ 75,00</b>, para Estudantes de Graduação em Farmácia.
											</p>
											<p>
												Após efetuar o pagamento lá no pagseguro, caso tenha pago com cartão de crédito a baixa será imediata desde que a sua operadora aprove.
												Caso tenha optado por pagamento com boleto, o PAGSEGURO irá confirmar em até 2(dois) dias úteis após o pagamento.
											</p>
											Os valores informados acima são válidos a partir da data de confirmação do pagamento até o mês de agosto do ano seguinte. <b> Ex.:</b>

											<ul style={{ marginLeft: '18px' }}>
												<li> O pagamento foi confirmado no dia 31/07/2022. Sua inscrição ficará ATIVA até 31/08/2023;</li>
												<li>O pagamento foi confirmado no dia 15/09/2022. Sua inscrição ficará ATIVA até 31/08/2023;</li>
												<li>O pagamento foi confirmado no dia 30/12/2022. Sua inscrição ficará ATIVA até 31/08/2023;</li>
											</ul>
											<p>
												Caso você seja estudante de Graduação é necessário o envio de cópia de documento de comprovação da matrícula para <a href="mailto:atendimento@sbrafh.org.br">atendimento@sbrafh.org.br</a>, em até 72h da efetivação do cadastro.
												A não apresentação da comprovação solicitada, incorre na suspensão temporária de acesso as áreas restritas do site,
												findando a suspensão quando do recebimento dos comprovantes.
											</p> </>
										}
										{editing && <> <br /> <br />
											<Alert show variant="info">
												<p>Por favor, atualize seus dados para que possamos contactá-lo quando necessário.</p>

												<p>Caso sua inscrição esteja na época de renovação ou ainda não tenha pago a taxa de anuidade, será gerado um boleto
													no valor de <b>R$ 175,00</b> para FARMACÊUTICOS PROFISSIONAIS ou <b>R$ 75,00</b>, para ESTUDANTES DE GRADUAÇÃO - FARMÁCIA.</p>

												<p>Caso você seja <b>ESTUDANTES DE GRADUAÇÃO - FARMÁCIA</b> é necessário o envio de cópia de documento de comprovação da
													matrícula para <a href="mailto:atendimento@sbrafh.org.br">atendimento@sbrafh.org.br</a>, em até 72h da efetivação do cadastro. A não apresentação da comprovação
													solicitada, incorre na suspensão temporária de acesso as áreas restritas do site, findando a suspensão quando do
													recebimento dos comprovantes.</p>
											</Alert>
										</>}
										<h6>Campos marcados com <span style={{ color: 'red' }}>*</span> são obrigatórios.</h6>
										<br />
										{editing && <> <Alert show variant="info"><h6> Quando em edição, os campos Nome e CPF não podem ser alterados, somente através de solicitação por e-mail. </h6></Alert> </>}
									</Card.Header>
									<Card.Body>
										<Form id="cadastro" name="cadastro" method="POST" noValidate onSubmit={handleSubmit}>
											<Row>
												<Col className="pr-1" md={7}>
													<label>* Nome Completo:</label>
													<Form.Control type="text" placeholder="Nome" maxLength="60" id="nome" name="nome"
														required={!editing} readOnly={editing} value={associado.nome}
														onChange={handleChange} />
													<div className="invalid-feedback">
														Por favor informe seu nome!
													</div>
												</Col>
												<Col className="pr-1" md={5}>
													<label>* CPF:</label>
													<MaskedInput id="cpf" placeholder="CPF" name="cpf" mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]} className="form-control numeric"
														required={!editing} readOnly={editing} value={associado.cpf} onChange={handleChangeMaskOnlyNumbers}
														onKeyPress={(event) => {
															if (!/[0-9]/.test(event.key)) {
																event.preventDefault();
															}
														}}
													/>
													<div className="invalid-feedback">
														Por favor informe o CPF!
													</div>
												</Col>
											</Row>
											<Row>
												<Col className="pr-1" md={5}>
													<label>* Endereço:</label>
													<input type="text" placeholder="Endereço" className="form-control" maxLength="100" name="ender" id="ender" required
														value={associado.ender} onChange={handleChange} />
													<div className="invalid-feedback">
														Por favor preencha o endereço!
													</div>
												</Col>
												<Col md={2}>
													<label>* Número:</label>
													<input type="text" id="num" name="num" className="form-control numeric" maxLength="8" required aria-describedby="numSm"
														value={associado.num} onChange={handleChange}
														onKeyPress={(event) => {
															if (!/[0-9]/.test(event.key)) {
																event.preventDefault();
															}
														}} /> &nbsp;
													<div className="invalid-feedback">
														Por favor informe o número do endereço!
													</div>
													<small id="numSm" className="text-muted">Somente números</small>

												</Col>
												<Col md={5}>
													<label htmlFor="compl">Complemento:</label>
													<input type="text" className="form-control upper" maxLength="254" placeholder="Complemento"
														name="compl" id="compl" aria-describedby="complSm"
														value={associado.compl} onChange={handleChange} />
													&nbsp;
													<small id="complSm" className="text-muted"> Apto, Sala, Conj., etc... </small>
												</Col>
											</Row>
											<Row>
												<Col md={4}>
													<label htmlFor="bairro">* Bairro:</label>
													<input type="text" maxLength="50" placeholder="Bairro" className="form-control upper" name="bairro" id="bairro" required
														value={associado.bairro} onChange={handleChange} />
													<div className="invalid-feedback">
														Por favor informe o bairro!
													</div>
												</Col>
												<Col md={3}>
													<label>* Estado:</label>
													<Form.Select name="uf" id="uf" className="form-control" required
														value={associado.uf} onChange={handleChange}>
														<option value="">Selecione</option>
														{estados.map(e => (
															<option key={e.id} value={e.sigla} >
																{e.estado}
															</option>
														))}
													</Form.Select>
													<div className="invalid-feedback">
														Por favor selecione o estado!
													</div>
												</Col>
												<Col md={5}>
													<label>* Cidade:</label>
													<input type="text" maxLength="100" placeholder="Cidade" className="form-control upper" name="cidade" id="cidade" required
														value={associado.cidade} onChange={handleChange} />
													<div className="invalid-feedback">
														Por favor informe a cidade!
													</div>
												</Col>
											</Row>
											<Row>
												<Col md={4}>
													<label>* CEP:</label>
													<MaskedInput id="cep" placeholder="CEP" name="cep" mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]} className="form-control numeric" required
														value={associado.cep} onChange={handleChangeMaskOnlyNumbers}
														onKeyPress={(event) => {
															if (!/[0-9]/.test(event.key)) {
																event.preventDefault();
															}
														}} />
													<div className="invalid-feedback">
														Por favor informe o CEP!
													</div>
												</Col>
												<Col md={3}>
													<label htmlFor="pais">* País:</label>
													<input type="text" className="form-control upper" placeholder="País" maxLength="80" id="pais" name="pais" required
														value={associado.pais} onChange={handleChange} />
													<div className="invalid-feedback">
														Por favor informe o país!
													</div>
												</Col>
												<Col md={5}>
													<label htmlFor="email">* E-Mail:</label>
													<InputGroup className="form-group-no-border">
														<InputGroup.Text> <i className="nc-icon nc-email-85" /> </InputGroup.Text>
														<input type="email" className="form-control lower" placeholder="E-Mail" maxLength="100" id="email" name="email" required
															value={associado.email} onChange={handleChange} />
														<div className="invalid-feedback">
															Por favor informe o e-mail!
														</div>
													</InputGroup>

												</Col>
											</Row>
											<Row>
												<Col md={4}>
													<label> Fone: &nbsp; </label>
													<InputGroup className="form-group-no-border">
														<InputGroup.Text> <i className="pe-7s-call" /> </InputGroup.Text>
														<MaskedInput type="text" placeholder="Fone" className="form-control" id="fone" name="fone"
															mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
															value={associado.fone} onChange={handleChangeMaskOnlyNumbers}
															onKeyPress={(event) => {
																if (!/[0-9]/.test(event.key)) {
																	event.preventDefault();
																}
															}} /> 
													</InputGroup>
												</Col>
												<Col md={4}>
													<label>* Natural de: &nbsp; </label>
													<input type="text" className="form-control" placeholder="Naturalidade" maxLength="100"
														id="naturalidade" name="naturalidade" required
														value={associado.naturalidade} onChange={handleChange} />
													<div className="invalid-feedback">
														Por favor preencha este campo!
													</div>
												</Col>
											</Row>
											<Row>
												<Col md={4}>
													<label>* Celular:</label>
													<InputGroup className="form-group-no-border">
														<InputGroup.Text> <i className="pe-7s-call" /> </InputGroup.Text>
														<MaskedInput type="text" placeholder="Celular" className="form-control" id="fone2" name="fone2" required aria-describedby="celSm"
															mask={['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
															value={associado.fone2} onChange={handleChangeMaskOnlyNumbers}
															onKeyPress={(event) => {
																if (!/[0-9]/.test(event.key)) {
																	event.preventDefault();
																}
															}} /> &nbsp;
														<div className="invalid-feedback">
															Por favor informe o número de celular.
														</div>
													</InputGroup>
													<small id="celSm" className="text-muted">
														Digite XX 0 XXXX-XXXX se for DDD + 8 dígitos
													</small>
												</Col>
												<Col md={4}>
													<label>* Data de Nascimento:</label>
													<InputGroup className="form-group-no-border">
														<InputGroup.Text> <i className="pe-7s-date" /> </InputGroup.Text>
														<MaskedInput type="text" className="form-control" name="dataNcto" id="dataNcto" required placeholder="01/01/0000"
															mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
															value={associado.dataNcto} onChange={handleChange}
															onKeyPress={(event) => {
																if (!/[0-9]/.test(event.key)) {
																	event.preventDefault();
																}
															}} />
														<div className="invalid-feedback">
															Por favor preencha a data de nascimento.
														</div>
													</InputGroup>

												</Col>
												<Col md={4}>
													<label>* Sexo:</label>
													<div>
														<Form.Check inline name="sexo" id="masc" onChange={handleChange} value="1" label="Masculino"
															type="radio" checked={String(associado.sexo) === '1'} />
														<Form.Check inline name="sexo" id="fem" onChange={handleChange} value="0" label="Feminino"
															type="radio" checked={String(associado.sexo) === '0'} />
													</div>
												</Col>
											</Row>
											<Row>
												<Col md={4}>
													<label>CRF:</label>
													<input className="form-control crf numeric" placeholder="CRF" type="text" maxLength="20" id="crf" name="crf"
														value={associado.crf} onChange={handleChange}
														onKeyPress={(event) => {
															if (!/[0-9]/.test(event.key)) {
																event.preventDefault();
															}
														}} />
												</Col>
												<Col md={2}>
													<label> &nbsp; UF: &nbsp; </label>
													<Form.Select name="crf_uf" id="crf_uf" className="form-control"
														value={associado.crf_uf} onChange={handleChange}>
														<option >--</option>
														{estados.map(e => (
															<option key={e.id} value={e.sigla} >
																{e.sigla}
															</option>
														))}
													</Form.Select>
												</Col>
											</Row>

											<Row>
												<Col md={6}>
													<div className="title"><h4 className="register-form-subtitle">Dados Profissionais</h4></div>
													<label>* Formação Profissional:</label>
													<Form.Select id="formacao" name="formacao" className="form-control" required
														value={associado.formacao} onChange={handleChange}>
														<option value="">Selecione</option>
														{formacoes.map(e => (
															<option key={e.id} value={e.formacao} >
																{e.formacao}
															</option>
														))}
													</Form.Select>
													<div className="invalid-feedback">
														Por favor selecione a formação.
													</div>
												</Col>
											</Row>
											<br />
											<Row>
												<Col className="title">
													<h6>Marque nas caixas abaixo suas áreas de atuação</h6>
												</Col>
											</Row>
											<br />
											<Row>
												<Col md={12}>
													{criaAtuacao()}
												</Col>
											</Row>
											<div className="title"><h4 className="register-form-subtitle">Dados Comerciais</h4></div>
											<Row>
												<Col md={6}>
													<label>Nome da Instituição:</label>
													<input type="text" className="form-control" maxLength="100" id="instituicao" name="instituicao"
														value={associado.instituicao} onChange={handleChange} />
												</Col>
												<Col md={4}>
													<label>Telefone da Instituição:</label>
													<MaskedInput type="text" className="form-control" id="hfone1" name="hfone1"
														mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/,]}
														value={associado.hfone1} onChange={handleChangeMaskOnlyNumbers} aria-describedby="smFoneInst"
														onKeyPress={(event) => {
															if (!/[0-9]/.test(event.key)) {
																event.preventDefault();
															}
														}} /> &nbsp;
													<small className="form-text text-muted" id="smFoneInst">Obrigatório para profissionais</small>
												</Col>
											</Row>
											{!editing && <>
												<div className="title"> <h4 className="register-form-subtitle"> Crie uma senha para o seu próximo acesso!</h4></div>
												<Row>
													<Col md={3}>
														<label>* Senha:</label>
														<InputGroup className="form-group-no-border ">
															<InputGroup.Text>
																<i className="nc-icon nc-key-25" />
															</InputGroup.Text>
															<input className="form-control" type="password" maxLength="10" name="senha" id="senha" required aria-describedby="smSenha"
																value={associado.senha} onChange={handleChange} />
															<div className="invalid-feedback">
																Por favor informe uma senha.
															</div>
														</InputGroup>
														&nbsp; <small className="form-text text-muted" id="smSenha">Máximo 10 dígitos (letras e números)</small>

													</Col>
													<Col md={3}>
														<label>* Confirme:</label>

														<InputGroup className="form-group-no-border ">
															<InputGroup.Text>
																<i className="nc-icon nc-key-25" />
															</InputGroup.Text>
															<input className="form-control" type="password" maxLength="10" name="confirme" id="confirme" required
																value={associado.confirme} onChange={handleChange} />
															<div className="invalid-feedback">
																Por favor confirme a senha.
															</div>
														</InputGroup>
													</Col>
												</Row> </>
											}
											<Row>
												<Col md={8}>
													<h3>
														<small className="decl"><b>Declaro que estou ciente que informações falsas importam em responsabilidade
															criminal nos termos do artigo 299 do codigo penal brasileiro e que que devo pagar
															o valor mencionado acima para ativar a minha inscrição e ter direito
															aos benefícios concedidos somente aos associados ativos.</b></small>
													</h3>
												</Col>
											</Row>
											<Row>
												<Col md={12}>
													<Alert show={msgErro !== undefined} variant="danger" dismissible onClose={() => { setMsgErro(undefined); }}>
														<span><b> {msgErro} </b></span>
													</Alert>
												</Col>
											</Row>
											<Row>
												<Col sm={12}>
													<center>
														<Button id="btSend" name="btSend" className="btn-round btn-fill btn-success" color="success" type="submit">
															Enviar meus dados
															{loading &&
																<> &nbsp;
																	<Spinner animation="border" role="status" size="sm">
																		<span className="visually-hidden">Carregando...</span>
																	</Spinner> </>}
														</Button>
													</center>
												</Col>
											</Row>
										</Form>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		</>
	);
}