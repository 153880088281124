import React from 'react';
import $ from 'jquery';  

export function Footer() {
    React.useEffect(function() {
        // Back to top button
        $(window).on('scroll', function () {
            if ($(this).scrollTop() > 100) {
                $('.back-to-top').fadeIn('slow');
            } else {
                $('.back-to-top').fadeOut('slow');
            }
        });
        $('.back-to-top').on('click', function () {
            $('html, body').animate({scrollTop: 0}, 1500, 'easeInOutExpo');
            return false;
        }); 
    }, []);
    return (
        <> 
            <a href="#n" className="btn-top btn-square back-to-top"><i className="fa fa-arrow-up"></i></a>
            <div className="container-fluid bg-dark pt-5 px-sm-3 px-md-5 mt-5">
                <div className="row py-4">
                </div>
            </div>
            <div className="container-fluid py-4 px-sm-3 px-md-5" style={{ background: '#111111' }}>
                <p className="m-0 text-center footer-text"> © 2022 Sociedade Brasileira de Farmácia Hospitalar e Serviços de Saúde - Rua Vergueiro, 1855 - 11. Andar - Vila Mariana CEP 04101-000 - São Paulo/SP
                </p>
            </div>
        </>
    );
}