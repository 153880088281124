import React from 'react';
import { authenticationService } from '../../_services/authentication.service';
import { Button, Container, Row, Col, Card, InputGroup, Form, Alert, Spinner } from "react-bootstrap";
import { useParams, useNavigate } from 'react-router-dom'; 
import {handleAuthResponse} from '../../_helpers';
import { cadastroService } from '../../_services';

export function AssociadoLoginForm() {
	const [msgErro, setMsgErro] = React.useState(undefined);
	const [loading, setLoading] = React.useState(false);
	const [usuario, setUsuario] = React.useState({
		email: '',
		senha: '',
	});

	const params = useParams;
	const navigate = useNavigate();

	React.useEffect(function() {
		if (!params.id && authenticationService.loggedUser) {
			navigate('/associados/area');
		} 
	}, [params.id, navigate]);

	const handleChange = function (event) {
		const value = event.target.value;
		var name = event.target.name;
		setUsuario((state) => {
			return state = { ...usuario, [name]: value }
		});
	}
 
	const handleSubmit = function (event) {
		event.preventDefault();
  
		var form = document.getElementById('myForm');
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
			form.classList.add('was-validated');
			return;
		}
 
		setLoading(true);
		setMsgErro(undefined);

		authenticationService.login(JSON.stringify(usuario)).then(function (res) {  
			cadastroService.getById (res.id).then((r) => {return handleAuthResponse(r, navigate)}).then(usuario => {
				if (usuario.nivelAcesso < 6) {
					navigate ("/associados/area");
				} else {
					navigate ("/admin/");
				}
			});
 
			setLoading(false);
			setMsgErro(undefined);
		}).catch(function (res) {
			if (res.status === 404) {
				setLoading(false);
				setMsgErro('E-mail ou senha inválidos.');
			} else {
				setLoading(false);
				setMsgErro('Erro ao efetuar login. Por favor tente novamente.');
			}
		});
	}
	return (
		<>
			<div className="register-page main" style={{ backgroundImage: "url(" + require("assets/img/login-bg.jpg") + ")" }} >
				<Container className="container-left">
					<Row>
						<Col  style={{marginTop: '160px'}}>
							<div className="login-left">
								<Card className="card-register">
									<div style={{ padding: '0px 0px 70px 60px' }}>
										<div className="col-sm-12">
											<img alt="" src={require("assets/img/sbrafh/logo.png")} border="0" style={{ width: '40%' }} />
											<div className="title" style={{ marginTop: '0px' }}><h4 style={{ marginTop: '0px' }}>Olá, <br /> Bem-vindo de volta!</h4><small>Entre com seus dados de login.</small></div>
										</div> 
										<Row>
											<Col sm={10}>
												<Form id="myForm" name="myForm" method="POST" noValidate onSubmit={handleSubmit}>

													<Alert show={msgErro !== undefined} variant="danger" dismissible onClose={() => setMsgErro(undefined)}>
														<span><b> {msgErro} </b></span>
													</Alert>
													<Row>
														<Col md={12}>


															<label>E-mail</label>
															<InputGroup className="form-group-no-border">
																<InputGroup.Text>
																	<i className="nc-icon nc-email-85" />
																</InputGroup.Text>
																<Form.Control placeholder="E-mail" className="form-control" type="email" name="email" id="email" maxLength="80" required
																	value={usuario.email} onChange={handleChange} />
																<div className="invalid-feedback">
																	Por favor insira um e-mailf válido!
																</div>
															</InputGroup>
														</Col>
													</Row>

													<Row>
														<Col md={12}>

															<label>Senha</label>
															<InputGroup className="form-group-no-border">
																<InputGroup.Text>
																	<i className="nc-icon nc-key-25" />
																</InputGroup.Text>
																<Form.Control placeholder="Senha" className="form-control" type="password" name="senha" id="senha" maxLength="10" required
																	value={usuario.senha} onChange={handleChange} />
																<div className="invalid-feedback">
																	Por favor insira a senha!
																</div>
															</InputGroup>
														</Col>
													</Row>
													<Row>
														<div>
															<small>
																<a className="text-secondary" href="/associados/recuperar-senha">Caso não lembre sua senha, clique aqui.</a>
															</small>
														</div>
													</Row>
													<Row>
														<Col>
														<hr/> 
														</Col>
													</Row>
													<Row>
														<Col md={12}>
															<Button id="btLogin" name="btLogin" className="btn-block" variant="success" type="submit">
																Login
																{loading &&
																	<> &nbsp;
																		<Spinner animation="border" role="status" size="sm">
																			<span className="visually-hidden">Carregando...</span>
																		</Spinner> </>}
															</Button>
														</Col>
													</Row>
												</Form>
											</Col>
										</Row>
									</div>
								</Card>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}