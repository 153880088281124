import { environment } from '../environment';   
import { BehaviorSubject } from 'rxjs';

const currentUserSubject = new BehaviorSubject(JSON.parse(sessionStorage.getItem('assoc')));

export const authenticationService = { 
    login,
    logout,
    get loggedUser() { return currentUserSubject.value }
};

function login(reqBody) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: reqBody
    };

    return fetch(`${environment.baseURL}/Cadastro/Login`, requestOptions)
        .then((user) => {
            if (!user.ok) {
                return Promise.reject(user);
            }
            return user.json().then(f => { 
                sessionStorage.setItem('assoc', JSON.stringify(f)); 
                currentUserSubject.next(f); 
                return f;
            });
        });
}

function logout() {
    sessionStorage.removeItem('assoc');
    sessionStorage.removeItem('firstLogin');
    currentUserSubject.next(null);
}
 