import IndexAssociados from "views/admin/associados/Index";
import { AdminAssociadoForm } from "views/admin/associados/Criar";
import IndexEventosRegionais from "views/admin/eventos-regionais/Index";
import {CriarEventoRegional} from 'views/admin/eventos-regionais/Criar';
import IndexCursosRegionais from "../views/admin/cursos-regionais/Index";
import IndexInscricoesCursos from 'views/admin/cursos-regionais/Inscricoes';
import { CriarCursoRegional } from "../views/admin/cursos-regionais/Criar";
import { InscricaoCursoRegional } from "../views/admin/cursos-regionais/Inscricao";
import { CriarInscricaoForm } from "../views/admin/cursos-regionais/CriarInscricao";
import RelatorioAssociadosAtivos from "../views/admin/relatorios/AssociadosAtivos";
import RelatorioFinanceiro from "../views/admin/relatorios/Financeiro";
import IndexNoticiasRegionais from "../views/admin/noticias-regionais/Index";
import CriarNoticiaRegionalForm from "../views/admin/noticias-regionais/Criar";

const adminDashboardRoutes = [   
  // CADASTRO

  {
    path: "/associados",
    name: "Cadastro de Associados", 
    component: IndexAssociados, 
    sidebar: true,
    layout: "/admin"
  }, 
  {
    path: "/associados/:id",
    name: "Editar Associado", 
    component: AdminAssociadoForm, 
    layout: "/admin"
  }, 
  
  // EVENTOS

  {
    path: "/eventos-regionais",
    name: "Propostas de Eventos Regionais", 
    component: IndexEventosRegionais,
    sidebar: true,
    layout: "/admin"
  }, 
  {
    path: "/eventos-regionais/novo",
    name: "Formulário Proposta para Eventos Regionais", 
    component: CriarEventoRegional,  
    layout: "/admin"
  }, 
  {
    path: "/eventos-regionais/:id",
    name: "Formulário Proposta para Eventos Regionais", 
    component: CriarEventoRegional,  
    layout: "/admin"
  },  
 

  // CURSOS REGIONAIS
  {
    path: "/cursos-regionais/",
    name: "Eventos Regionais", 
    component: IndexCursosRegionais,  
    sidebar: true,
    layout: "/admin"
  },
  {
    path: "/cursos-regionais/:id",
    name: "Eventos Regionais", 
    component: CriarCursoRegional,   
    layout: "/admin"
  },
  {
    path: "/cursos-regionais/novo",
    name: "Eventos Regionais", 
    component: CriarCursoRegional,   
    layout: "/admin"
  },
  {
    path: "/cursos-regionais/inscricoes",
    name: "Inscrições", 
    component: IndexInscricoesCursos,  
    layout: "/admin"
  },  
  {
    path: "/cursos-regionais/inscricao/:id",
    name: "Inscrição", 
    component: InscricaoCursoRegional,  
    layout: "/admin"
  },  
  {
    path: "/cursos-regionais/inscricao/",
    name: "Inscrição", 
    component: CriarInscricaoForm,  
    layout: "/admin"
  },  

  // NOTÍCIAS 
  {
    path: "/noticias-regionais/",
    name: "Notícias Regionais", 
    component: IndexNoticiasRegionais, 
    sidebar: true,
    layout: "/admin"
  },   
  {
    path: "/noticias-regionais/novo",
    name: "Criar Notícia", 
    component: CriarNoticiaRegionalForm,  
    layout: "/admin"
  },
  {
    path: "/noticias-regionais/:id",
    name: "Editar Notícia", 
    component: CriarNoticiaRegionalForm, 
    layout: "/admin"
  }, 

  // RELATÓRIOS 
  {
    path: "/relatorios/associados",
    name: "Associados Ativos", 
    component: RelatorioAssociadosAtivos, 
    sidebar: true,
    layout: "/admin"
  },  
  {
    path: "/relatorios/financeiro",
    name: "Relatório Financeiro", 
    component: RelatorioFinanceiro, 
    sidebar: true,
    layout: "/admin"
  }, 
 
];

export default adminDashboardRoutes;
