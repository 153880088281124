import React from "react";
import MaskedInput from 'react-text-mask';
import { Button, Card, Table, Container, Row, Col, Form, Pagination, Alert } from "react-bootstrap"; 
import { NavLink, useSearchParams } from "react-router-dom";
import { cadastroService, estadoService } from "../../../_services";
import { formatDate, parseDate } from "../../../_helpers";
import toastr from 'toastr'; 

export default function IndexAssociados() { 
  const [pages, setPages] = React.useState([]);  
  const [associados, setAssociados] = React.useState([]);
  const [estados, setEstados] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const filtroInicial = {
    nomeCpf: '',
    inicio: '',
    ate: '',
    uf: '',
    status: '',
    pageIndex: 0,
    pageSize: 50,
    totalRows: 0,
  }; 
  const [filtro, setFiltro] = React.useState(filtroInicial);

  const [searchParams] = useSearchParams();

  React.useEffect(function () {
    getEstados();
  }, []);

  React.useEffect(function () {
    const UF = searchParams.get('uf');
    if (UF) {
      filtro.uf = UF.toUpperCase();
    }
    filtro.pageIndex = 0; 
    aplicarFiltros();
  }, [searchParams, searchParams.get('uf')]);

  const handleChange = function (event) {
    const value = event.target.value;
    var name = event.target.name;
    setFiltro((f) => {
      return f = { ...f, [name]: value }
    });
  }

  const getEstados = function () {
    estadoService.list().then(data => {
      setEstados(data)
    }).catch((ex) => {
      toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
    });
  }

  const status = function (a) {
    if (a.status === 0) { return 'INATIVO'; }
    else if (a.status === -1) { return 'CANCELADO'; }
    else if (a.status === 1) { return 'ATIVO'; }
    else if (a.status === 2) { return 'Ex-Dir. Reg.'; }
    else if (a.status === 3) { return 'Dir. Reg.'; }
    else if (a.status === 4) { return 'Ex-Dir. Nac.'; }
    else if (a.status === 5) { return 'Dir. Nac.'; }
  }

  React.useEffect(function () {
    let items = [];
    const totalPagesMax = Math.min(filtro.totalPages, filtro.pageIndex + 11);
    items.push(<Pagination.First key="first" disabled={filtro.pageIndex + 1 === 1} onClick={
      () => {
        filtro.pageIndex = 0; 
        aplicarFiltros();
      }} />);
    items.push(<Pagination.Prev key="prev" disabled={filtro.pageIndex + 1 === 1} onClick={
      () => {
        filtro.pageIndex = filtro.pageIndex - 1; 
        aplicarFiltros();
      }} />);
    for (let number = Math.max(1, filtro.pageIndex - 6); number <= totalPagesMax; number++) {
      items.push(
        <Pagination.Item key={number} active={number - 1 === filtro.pageIndex} onClick={
          () => {
            filtro.pageIndex = number - 1; 
            aplicarFiltros();
          }
        }>
          {number}
        </Pagination.Item>,
      );
    }
    items.push(<Pagination.Next key="next" disabled={filtro.pageIndex + 1 === filtro.totalPages || filtro.totalRows === 0} onClick={
      () => {
        filtro.pageIndex = filtro.pageIndex + 1; 
        aplicarFiltros();
      }} />);
    items.push(<Pagination.Last key="last" disabled={filtro.pageIndex + 1 === filtro.totalPages || filtro.totalRows === 0} onClick={
      () => {
        filtro.pageIndex = filtro.totalPages - 1; 
        aplicarFiltros();
      }} />);
    setPages(items);
  }, [filtro.pageIndex, filtro.totalPages]);

  const aplicarFiltros = function () {
    setLoading(true);
    const fil = {
      ...filtro,
      inicio: filtro.inicio && parseDate(filtro.inicio),
      ate: filtro.ate && parseDate(filtro.ate),
    };
    cadastroService.filter(fil).then((data) => {
      setAssociados(data.data);
      filtro.totalPages = (data.totalPages);
      filtro.totalRows = (data.totalRows);
    }).catch((ex) => {
      toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
    }).then(function () {
      setLoading(false);
    });
  }

  const btnFiltrarClick = function () {
    filtro.pageIndex = 0;
    aplicarFiltros(); 
  }

  const exportarExcel = function (event) {
    event.preventDefault();
    setLoading(true);
    const filtroReq = {
      ...filtro,
      pageIndex: 0,
      pageSize: 77777,
    };
    cadastroService.exportExcel(filtroReq)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "Associados.xls";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }).catch((ex) => {
        toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
      }).then(function () {
        setLoading(false);
      });
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              {loading &&
                <div className="loading-overlay d-flex justify-content-center align-text-center" >
                  <div className="loading-spinner "></div>
                  <br />
                  <div className="loading-text">Carregando...</div>
                </div>
              }
              <Card.Header>
                <Card.Title as="h4">Lista de Associados
                  <div style={{ float: 'right' }}>
                    <input id="btnExcel" name="btnExcel" type="image" src={require('../../../../src/assets/img/excel.png')}
                      style={{ width: '50%', height: '50%' }} title="Exportar para Excel" alt="Exportar para Excel" onClick={(event) => { exportarExcel(event) }} />
                  </div>
                </Card.Title>
                <Row className="align-items-center">
                  <Col className="pr-1" md={3}>
                    <label>Nome ou CPF:</label>
                    <Form.Control type="text" placeholder="Nome ou CPF" maxLength="60" id="nomeCpf" name="nomeCpf"
                      value={filtro.nomeCpf}
                      onChange={handleChange} 
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          btnFiltrarClick();
                          event.preventDefault();
                        }
                      }}
                      />
                    <div className="invalid-feedback">
                      Por favor informe nome ou CPF!
                    </div>
                  </Col>
                  <Col className="pr-1" md={2}>
                    <label>Início:</label>
                    <MaskedInput type="text" className="form-control" name="inicio" id="inicio" required placeholder="01/01/0000"
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                      value={filtro.inicio} onChange={handleChange}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        } 
                        if (event.key === 'Enter') {
                          btnFiltrarClick();
                          event.preventDefault();
                        } 
                      }} />
                  </Col>
                  <Col className="pr-1" md={2}>
                    <label>Fim:</label>
                    <MaskedInput type="text" className="form-control" name="ate" id="ate" required placeholder="01/01/0000"
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                      value={filtro.ate} onChange={handleChange}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                        if (event.key === 'Enter') {
                          btnFiltrarClick();
                          event.preventDefault();
                        } 
                      }} />
                  </Col>
                  <Col className="pr-1" md={1}>
                    <label>UF:</label>
                    <Form.Select id="uf" name="uf"
                      value={filtro.uf}
                      onChange={handleChange} onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          btnFiltrarClick();
                          event.preventDefault();
                        }
                      }}>
                      <option value="">Todos</option>
                      {estados.map(e => (
                        <option key={e.id} value={e.sigla} >
                          {e.sigla}
                        </option>
                      ))} 
                    </Form.Select>
                  </Col>
                  <Col className="pr-1" md={2}>
                    <label>Status:</label>
                    <Form.Select id="status" name="status"
                      value={filtro.status}
                      onChange={handleChange} onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          btnFiltrarClick();
                          event.preventDefault();
                        }
                      }}>
                      <option value="">Todos</option>
                      <option value="1">Ativo</option>
                      <option value="0">Inativo</option> 
                    </Form.Select>
                  </Col>
                  <Col className="pr-1" md={2}>
                    <label></label> <br />
                    <Button id="btFiltrar" name="btFiltrar" className="btn btn-primary" onClick={() => btnFiltrarClick()} >
                      Buscar
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">Inscrição</th>
                      <th className="border-0">Nome</th>
                      <th className="border-0">Cidade</th>
                      <th className="border-0">UF</th>
                      <th className="border-0">Validade</th>
                      <th className="border-0">Status</th>
                      <th className="border-0">Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      associados &&
                      associados.map(e => (
                        <tr key={e.idCadastro}>
                          <td>
                            <NavLink className="btn-simple btn-link p-1 info" to={'/admin/associados/' + e.idCadastro} >
                              {String(e.idCadastro).padStart(6, '0')}
                            </NavLink>
                          </td>
                          <td>{e.nome}</td>
                          <td>{e.cidade}</td>
                          <td>{e.uf}</td>
                          <td>{formatDate(e.validade)}</td>
                          <td>{status(e)}</td>
                          <td>{e.valor && e.valor.toFixed(2)}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                <Pagination size="sm">{pages}</Pagination>
                <Alert variant="secondary">
                  {
                    (associados.length === 0) && <>
                      <b>Nenhum resultado encontrado.</b> </>
                  }
                  {
                    (associados.length > 0) && <>
                      <b>Exibindo resultados: {filtro.pageSize * (filtro.pageIndex) + 1} a {((filtro.pageIndex) * filtro.pageSize + Math.min(associados.length, filtro.pageSize))} de {filtro.totalRows}.</b> </>
                  }
                </Alert>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}