import React from 'react';
import { Modal, Alert } from 'react-bootstrap';

export function ModalAjuda(props) { 
    return (
        <Modal {...props} 
            size="xl" aria-labelledby="contained-modal-title-vcenter"  >
            <Modal.Header closeButton={true}>
                <Modal.Title id="contained-modal-title-vcenter">
                    Ajuda
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert show variant="info">
                    <table cellpadding="2" cellspacing="2" border="0">
                        <caption>Ajuda para preenchimento do Formulário<br />da Proposta para Eventos Regionais</caption>
                        <tr>
                            <td>
                                <ol>
                                    <li><b>Título do Evento [Tipo: TEXTO]:</b> - Máximo de 255 caracteres sem quebra de linha</li>
                                    <li><b>Tipos de eventos [Tipo: COMBO DE SELEÇÃO]:</b>
                                        <ul>
                                            <li><b>Seminário</b> - Eventos científicos de âmbito menor do que o congresso, tanto em termos de duração (horas a 1 ou 2 dias),
                                                quanto de número de participantes, cobrindo campos de conhecimento mais especializados. Incluem-se nessa classificação:
                                                encontro, simpósio, jornada, colóquio, fórum, reunião.</li>
                                            <li><b>Ciclo de Debates</b> - Encontros sequenciais que visam a discussão de um tema especifico. Inclui: Ciclo de..., Circuito, Semana de...</li>
                                            <li><b>Mesa-redonda</b> - É uma reunião do tipo clássico, preparada e conduzida por um coordenador, que funciona como elemento moderador,
                                                orientando a discussão para que ela se mantenha sempre em torno do tema principal. Os expositores têm um tempo limitado para
                                                apresentar suas idéias e para o debate posterior.</li>
                                            <li><b>Simpósio</b> - É um derivado da mesa-redonda, possuindo como característica o fato de ser de alto nível, com a participação
                                                de aspectos diferentes de determinados assuntos - e sempre com a presença de um coordenador. A diferença fundamental entre
                                                o simpósio e a mesa-redonda é que no primeiro os expositores não debatem entre si os temas apresentados. As perguntas,
                                                respostas e o próprio debate são efetuados diretamente ao participante da platéia.</li>
                                            <li><b>Fórum</b> - Tipo de reunião que tem como objetivo conseguir a efetiva participação de um público numeroso, que deve ser motivado.</li>
                                            <li><b>Conferência</b> - Consiste sempre em duas partes: o auditório e os expositores. Estes podem discorrer sobre um assunto previamente
                                                escolhido e de seu amplo conhecimento. Ao final deste período, respondem a perguntas formuladas pelo auditório. Além de oferecer
                                                aos participantes a absorção de informação prática, dá também a oportunidade de realizar contatos e negócios, além de trocar
                                                experiências com os demais participantes, uma vez que o público de conferências tem bastante familiaridade com o tema abordado.</li>
                                            <li><b>Workshop</b> -  Tem como objetivo detalhar, aprofundar um determinado assunto de maneira mais prática. Normalmente possui um moderador
                                                e um ou dois expositores. A dinâmica da sessão divide-se em três momentos: exposição, discussão em grupos ou equipe e conclusão.</li>
                                            <li><b>Cursos</b> - Consiste no detalhamento de determinado assunto ou conjunto de temas com o foco de \"treinar\" ou \"ensinar a fazer\".
                                                É composto de exposições de pessoas normalmente com formação acadêmica que procuram passar seu conhecimento aos participantes.
                                                O foco está mais na teoria que na prática, porém não a exclui.</li>
                                            <li><b>Outros</b> - Outros eventos não classificados nos itens anteriores.</li>
                                        </ul>
                                    </li>
                                    <li><b>Carga Horária [Tipo NÚMERICO]:</b> - Informe a carga horária total. Ex.: 10</li>
                                    <li><b>Vagas disponíveis</b> - [NÚMERO] - Informe a quantidade de vagas disponíveis. Quando o sistema detectar que a quantidade
                                        de inscrição é igual a quantidade de vagas, o sistema irá bloquear automaticamente novas inscrições.</li>
                                    <li><b>Data(s)/Horário(s):</b> [TEXTO] - Informe no formato dd/mm/aaaa - HH:mm. Use uma linha para cada data/horário</li>
                                    <li><b>Local do Evento:</b> [TEXTO] - Informe o endereço completo, o bairro, a cidade e o estado</li>
                                    <li><b>Público Alvo: </b> Informe o tipo de público a que se destina o evento. Ex.:
                                        Profissionais da Saúde, Farmacêuticos, Enfermeiros, médicos, etc...</li>
                                    <li><b>Valores para inscrição: </b>[NÚMERO] - Informe corretamente os valores consideranto que descontos somente para
                                        EST. GRADUAÇÃO: FARMÁCIA</li>
                                    <li><b>Curso, projeto ou setor vinculado: </b> [TEXTO] - Caso o evento esteja vinculado a algum curso ou projeto</li>
                                    <li><b>Responsável pela equipe de realização :</b> [TEXTO] - Informe o nome, email e telefone da pessoa responsável
                                        pela equipe. Utilize uma linha para cada ítem</li>
                                    <li><b>Informações sobre o(s) palestrante(s): </b> [TEXTO] - Informe o nome e abaixo, o currículo do palestrante se disponível.
                                        Caso tenha mais de um palestrante, deixe uma linha em branco entre eles.</li>
                                    <li><b>Descrição: </b> [TEXTO] - Resumo do evento</li>
                                    <li><b>Objetivos do Evento: </b> [TEXTO] - Quais os objetivos do evento proposto?</li>
                                    <li><b>Contato a ser divulgado: </b> [TEXTO] - Informe o nome, o telefone e email do contato. Informe em outra linha
                                        se o estacionamento é gratuíto ou pago para os participantes.</li>
                                    <li><b>Observações Gerais: </b> [TEXTO] - Caso queira especificar melhor a proposta utilize essa caixa.</li>
                                    <li><b>CUSTO ESTIMADO DO EVENTO: </b>[TEXTO] - Utilize uma linha para cada ítem.</li>
                                    <li><b>FONTES DE RECURSOS: </b>[TEXTO] - Utilize uma linha para cada ítem.</li>
                                    <li><b>ANEXOS: </b>[ARQUIVO] - Utilize essa caixa para envio de fotos, imagens ou arquivos necessários a esta proposta.
                                        Assim que selecionar um arquivo, o sistema irá disponibilizar outra caixa para seleção e assim sucessivamente.</li>
                                </ol>
                            </td>
                        </tr>
                    </table>
                </Alert>
            </Modal.Body> 
        </Modal>
    );
}