import React from 'react'
import { Modal, Button } from "react-bootstrap";
 
const ModalConfirm = ({ showModal, onHide, onConfirm, id, message, backdrop }) => {
    return (
        <Modal show={showModal} backdrop={backdrop} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmação</Modal.Title>
        </Modal.Header>
        <Modal.Body><p className="text-danger">{message}</p></Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onHide}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={() => onConfirm(id) }>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    )
}
 
const ModalMsg = ({ showModal, onHide, onConfirm, id, msgClass, message, title, backdrop }) => {
    return (
        <Modal show={showModal} backdrop={backdrop} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body><div className={msgClass}>{message}</div></Modal.Body>
        <Modal.Footer> 
          <Button variant="primary" onClick={() => onConfirm(id) }>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    )
}
 
export { ModalConfirm, ModalMsg };