import { environment } from '../environment';
import { authHeader } from '../_helpers';

export const cursosRegionaisService = {
    filter,
    getById,
    create,
    update
};

function getById(ID) {
    return fetch(`${environment.baseURL}/CursosRegionais/${ID}`, { method: 'GET', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } });
}

function create(er) {
    return fetch(`${environment.baseURL}/CursosRegionais`, {
        headers: authHeader(),
        method: 'POST',
        body: JSON.stringify(er)
    });
}

function update(req) { 
    return fetch(`${environment.baseURL}/CursosRegionais`, {
        headers: authHeader(),
        method: 'PUT',
        body: JSON.stringify(req)
    });
}

function filter(reqFilters) {
    // Remove (undefined, '', null) dos parâmetros
    var filters = Object.assign({}, reqFilters);
    Object.keys(filters).forEach(key => (filters[key] === undefined || filters[key] === '' || filters[key] === null) 
        ? delete filters[key] : {});

    var url = new URL(`${environment.baseURL}/CursosRegionais/filter`);
    url.search = new URLSearchParams(filters);

    return fetch(url.toString(), {
        method: 'GET',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    }).then(async (res) => {
        const data = await res.json();
        if (!res.ok) {
            return Promise.reject(res);
        }
        return data;
    });
}