import { environment } from '../environment';
import { authHeader, authHeaderDownload } from '../_helpers'; 

export const inscricoesCursosService = {
    filter,
    count,
    update,
    confirm,
    getById,
    create,
    listCertificados,
    vagas,
    exportExcel
};


function getById(ID) {
    return fetch(`${environment.baseURL}/InscricoesCursos/${ID}`, { method: 'GET', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } });
}

function create(ins) {
    return fetch(`${environment.baseURL}/InscricoesCursos`, {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(ins)
    });
}

function update(ins) {  
    return fetch(`${environment.baseURL}/InscricoesCursos`, {
        headers: authHeader(),
        method: 'PUT',
        body: JSON.stringify(ins)
    });
}

function confirm(ins) {  
    return fetch(`${environment.baseURL}/InscricoesCursos/confirm`, {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(ins)
    });
}

function filter(reqFilters) {
    // Remove (undefined, '', null) dos parâmetros
    var filters = Object.assign({}, reqFilters);
    Object.keys(filters).forEach(key => (filters[key] === undefined || filters[key] === '' || filters[key] === null) 
        ? delete filters[key] : {});

    var url = new URL(`${environment.baseURL}/InscricoesCursos/filter`);
    url.search = new URLSearchParams(filters);

    return fetch(url.toString(), {
        method: 'GET',
        headers: authHeader()
    });
}

function count(reqFilters) { 
    var url = new URL(`${environment.baseURL}/InscricoesCursos/count`);
    url.search = new URLSearchParams(reqFilters);

    return fetch(url.toString(), {
        method: 'GET',
        headers: authHeader()
    }).then(async (res) => {
        const data = await res.json();
        if (!res.ok) {
            return Promise.reject(res);
        }
        return data;
    });
}

function vagas (reqFilters) { 
    var url = new URL(`${environment.baseURL}/InscricoesCursos/vagas`);
    url.search = new URLSearchParams(reqFilters); 

    return fetch(url.toString(), {
        method: 'GET',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    }).then(async (res) => {
        const data = await res.json();
        if (!res.ok) {
            return Promise.reject(res);
        }
        return data;
    });
}

function listCertificados(reqFilters) { 
    var url = new URL(`${environment.baseURL}/InscricoesCursos/certificados`);
    url.search = new URLSearchParams(reqFilters);

    return fetch(url.toString(), {
        method: 'GET',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    }).then(async (res) => {
        const data = await res.json();
        if (!res.ok) {
            return Promise.reject(res);
        }
        return data;
    });
}

function exportExcel (reqFilters) { 
    var filters = Object.assign({}, reqFilters);
    Object.keys(filters).forEach(key => (filters[key] === undefined || filters[key] === '' || filters[key] === null) ? delete filters[key] : {});

    var url = new URL(`${environment.baseURL}/InscricoesCursos/excel`);
    url.search = new URLSearchParams(filters);
  
    return fetch(url.toString(), {
        method: 'GET',
        headers: authHeaderDownload()
    });
}