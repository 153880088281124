import { environment } from '../environment'; 
import { authHeader, authHeaderDownload } from '../_helpers';

export const cadastroService = {
    getById,
    create,
    update,
    filter,
    exportExcel,
    recuperarSenha,
};

function getById(ID) {
    return fetch(`${environment.baseURL}/Cadastro/${ID}`, { method: 'GET', headers: authHeader() });
}

function create(assoc) {
    return fetch(`${environment.baseURL}/Cadastro`, {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(assoc)
    });
}

function update(assoc) {
    var req = Object.assign({}, assoc);
    // if (req.validade) {
    //     req.validade = parseDate(req.validade);
    // }
    // if (req.dataNcto) {
    //     req.dataNcto = parseDate(req.dataNcto);
    // }

    return fetch(`${environment.baseURL}/Cadastro`, {
        headers: authHeader(),
        method: 'PUT',
        body: JSON.stringify(req)
    });
}

function filter(reqFilters) {
    // Remove (undefined, '', null) dos parâmetros
    var filters = Object.assign({}, reqFilters);
    Object.keys(filters).forEach(key => (filters[key] === undefined || filters[key] === '' || filters[key] === null) ? delete filters[key] : {});

    var url = new URL(`${environment.baseURL}/Cadastro/filter`);
    url.search = new URLSearchParams(filters);

    // if (filters.inicio) {
    //     filters.inicio = 
    // }

    return fetch(url.toString(), {
        method: 'GET',
        headers: authHeader()
    })
        .then(async (res) => {
            const data = await res.json();
            if (!res.ok) {
                return Promise.reject(res);
            }
            return data;
        });
}

function exportExcel (reqFilters) { 
    var filters = Object.assign({}, reqFilters);
    Object.keys(filters).forEach(key => (filters[key] === undefined || filters[key] === '' || filters[key] === null) ? delete filters[key] : {});

    var url = new URL(`${environment.baseURL}/Cadastro/excel`);
    url.search = new URLSearchParams(filters);
  
    return fetch(url.toString(), {
        method: 'GET',
        headers: authHeaderDownload()
    });
}

function recuperarSenha (reqBody) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: reqBody
    };

    return fetch(`${environment.baseURL}/Cadastro/Recuperar`, requestOptions);
}