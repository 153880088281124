import React from 'react';
import $ from 'jquery';
import { Button, Container, Row, Col, Card, Form, Alert, Spinner } from "react-bootstrap";
import { authenticationService, cadastroService, estadoService, contatoService } from "../../_services";
import { handleAuthResponse } from '../../_helpers';
import { NavLink } from "react-router-dom";
import toastr from 'toastr';

export function AtendimentoAssociado() {
    const [associado, setAssociado] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [estados, setEstados] = React.useState([]);
    const [msgErro, setMsgErro] = React.useState(undefined);
    const [contato, setContato] = React.useState({
        "nome": "",
        "fone": "",
        "email": "",
        "uf": "",
        "assunto": "",
        "mensagem": ""
    });

    const getEstados = function () {
        estadoService.list().then(data => {
            setEstados(data)
        }).catch((ex) => {
            toastr.error('Ocorreu um erro. Por favor atualize a página e tente novamente.');
        });
    }

    React.useEffect(function () {
        const currentUser = authenticationService.loggedUser;

        cadastroService.getById(currentUser.id).then(handleAuthResponse).then(u => {
            setAssociado(u);
        });

        getEstados();
    }, []);

    const handleSubmit = function (event) {
        event.preventDefault();

        var form = document.getElementById('cadastro');
        if (form.checkValidity() === false) {
            event.stopPropagation();
            form.classList.add('was-validated');
            $('.main-panel').animate({ scrollTop: $('.was-validated .form-control:invalid').offset().top - 200 }, 'slow');
            return;
        }

        setLoading(true);
        contatoService.create(contato).then(res => {
            toastr.success('E-mail enviado com sucesso!');
            setLoading(false);
        }).catch(function () {
            setLoading(false);
            toastr.error('Ocorreu um erro. Por favor tente novamente.');
        });
    }

    const handleChange = function (event) {
        const value = event.target.value;
        var name = event.target.name;
        setContato((a) => {
            return a = { ...a, [name]: value }
        });
    }

    return (<>
        <div className="register-body">
            <div className="register-wrapper" style={{ width: '100%' }}>
                <Container className="vertical-center">
                    <Row>
                        <Col className="ml-auto mr-auto" lg="12">
                            <Card className="card-register">
                                <Card.Header style={{ color: 'black' }}>
                                    <Card.Title as="h2" className="text-warning">
                                        Contato
                                        <div style={{ float: 'right' }}>
                                            <NavLink className="btn btn-primary btn-sm" to="/associados/area">
                                                Voltar
                                            </NavLink>
                                        </div>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col className="pr-1" md={12}>
                                            <Alert show variant="info">
                                                A SBRAFH está à sua disposição para o esclarecimento de dúvidas sobre a instituição,
                                                nossos serviços, cursos, congressos, convênios e outros. <br />
                                                <br />
                                                Preencha o formulário abaixo ou se preferir ligue <b>11 5083 4297</b> ou escreva para:  <br />
                                                <b>SBRAFH - Sociedade Brasileira de Farmácia Hospitalar e Serviços de Saúde</b> <br />
                                                <b>Rua Vergueiro, 1855 - 12º Andar - Vila Mariana - CEP 04101-000 - São Paulo/SP</b>
                                            </Alert>

                                        </Col>
                                    </Row>
                                    <Form id="cadastro" name="cadastro" method="POST" noValidate onSubmit={handleSubmit}>
                                        <Row>
                                            <Col className="pr-1" md={12}>
                                                <label>* Nome Completo:</label>
                                                <Form.Control type="text" placeholder="Nome" maxLength="120" id="nome" name="nome"
                                                    onChange={handleChange} required />
                                                <div className="invalid-feedback">
                                                    Por favor informe seu nome!
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pr-1" md={12}>
                                                <label>* E-mail:</label>
                                                <Form.Control type="text" placeholder="E-mail" maxLength="120" id="email" name="email"
                                                    onChange={handleChange} required />
                                                <div className="invalid-feedback">
                                                    Por favor informe seu e-mail!
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pr-1" md={6}>
                                                <label>* DDD/Fone:</label>
                                                <Form.Control type="text" placeholder="Fone" maxLength="20" id="fone" name="fone"
                                                    onChange={handleChange} required />
                                                <div className="invalid-feedback">
                                                    Por favor informe seu telefone!
                                                </div>
                                            </Col>
                                            <Col className="pr-1" md={6}>
                                                <label>Estado:</label>
                                                <Form.Select name="uf" id="uf" size="1" className="form-control"
                                                    value={contato.uf}
                                                    onChange={handleChange}>
                                                    <option value="">Selecione</option>
                                                    {estados.map(e => (
                                                        <option key={e.id} value={e.sigla} >
                                                            {e.estado}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pr-1" md={12}>
                                                <label>* Assunto:</label>
                                                <Form.Control type="text" placeholder="Assunto" maxLength="120" id="assunto" name="assunto"
                                                    onChange={handleChange} required />
                                                <div className="invalid-feedback">
                                                    Por favor informe o assunto!
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pr-1" md={12}>
                                                <label>* Mensagem:</label>
                                                <textarea placeholder="Mensagem" className="form-control" rows="5" maxLength="2000" id="mensagem" name="mensagem"
                                                    onChange={handleChange} required />
                                                <div className="invalid-feedback">
                                                    Por favor preencha a mensagem!
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <hr />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Alert show={msgErro !== undefined} variant="danger" dismissible onClose={() => { setMsgErro(undefined); }}>
                                                    <span><b> {msgErro} </b></span>
                                                </Alert>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <center>
                                                    <Button className="btn btn-success btn-fill  btn-block success" type="submit">
                                                        Enviar
                                                        {loading &&
                                                            <> &nbsp;
                                                                <Spinner animation="border" role="status" size="sm">
                                                                    <span className="visually-hidden">Carregando...</span>
                                                                </Spinner> </>}
                                                    </Button>
                                                </center>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    </>
    );
}