import React from 'react'; 
export function Topbar() {
    const hoje = function() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');  
        var yyyy = today.getFullYear(); 
        return (dd + '/' + mm + '/' + yyyy);
    }
    return (
        <>
            <div className="container-fluid d-none d-lg-block">
                <div className="row align-items-center bg-dark px-lg-5">
                    <div className="col-lg-9">
                        <nav className="topbar navbar navbar-expand-sm bg-dark p-0">
                            <ul className="navbar-nav ml-n2">
                                <li className="nav-item border-right border-secondary">
                                    <small className="nav-link text-body small">{hoje()}</small>
                                </li> 
                                <li className="nav-item border-right border-secondary">
                                    <a className="nav-link text-body small" href="http://sbrafh.org.br">Página Inicial</a>
                                </li>
                                <li className="nav-item border-right border-secondary">
                                    <a className="nav-link text-body small" href="/associados/area">Área do Associado</a>
                                </li>
                                <li className="nav-item border-right border-secondary">
                                    <a className="nav-link text-body small" href="/associados/novo">Associar-se</a>
                                </li>
                                <li className="nav-item border-right border-secondary">
                                    <a className="nav-link text-body small" href="/associados/certificados">Certificados</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-body small" href="/admin">Restrito</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="col-lg-3 text-right d-none d-md-block">
                        <nav className="topbar navbar navbar-expand-sm bg-dark p-0">
                            <ul className="navbar-nav ml-auto mr-n2"> 
                                <li className="nav-item">
                                    <a className="nav-link text-body" href="https://www.facebook.com/sbrafh.oficial/"><small className="fa fa-facebook"></small></a>
                                </li> 
                                <li className="nav-item">
                                    <a className="nav-link text-body" href="https://www.instagram.com/sbrafh.oficial/"><small className="fa fa-instagram"></small></a>
                                </li> 
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="row align-items-center bg-white navbar-only-sm">  
                    <div className="_banner"> </div> 
                </div>
            </div>
        </>
    );
}