import { environment } from '../environment';
import { authHeader } from '../_helpers';

export const certificadosService = {
    create, 
    preview
};
 
function create (idInscr) {
    const url = new URL(`${environment.baseURL}/Certificados/create/${idInscr}`); 
    return fetch(url.toString(), { method: 'GET', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } });
}

function preview (idCurso) {
    const url = new URL(`${environment.baseURL}/Certificados/preview/${idCurso}`); 
    return fetch(url.toString(), { method: 'GET', headers: authHeader() });
}