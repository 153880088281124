import { useParams, useNavigate } from "react-router-dom";


export const environment = {  
    // baseURL: 'https://localhost:7219/api' 
    // baseURL: 'https://sbrafh.herokuapp.com/api'
    baseURL: 'https://api.sbrafh.com.br/api'
}

export function withRouter(Children){
    return(props) => { 
       const match  = {params: useParams()}; 
       const navigate = useNavigate();
       return <Children {...props}  match = {match} navigate = {navigate}/>
   }
 }
