import { environment } from '../environment';
import { authHeader } from '../_helpers';

export const pagseguroService = { 
    listByCadastro,
    createAnuidade,
    createInscricao,
};
  
function listByCadastro(idCadastro) {
    const url = new URL(`${environment.baseURL}/PagSeguro/cadastro/${idCadastro}`); 
    return fetch(url.toString(), {
        method: 'GET',
        headers: authHeader()
    });
}

function createAnuidade(req) {
    const url = new URL(`${environment.baseURL}/PagSeguro/Anuidade/`); 
    return fetch(url.toString(), {
        method: 'POST',
        headers: authHeader(), 
        body: JSON.stringify(req)
    });
}

function createInscricao(req) {
    const url = new URL(`${environment.baseURL}/PagSeguro/Inscricao/`); 
    return fetch(url.toString(), {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }, 
        body: JSON.stringify(req)
    });
}