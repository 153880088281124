import React, { useCallback } from 'react';
import { Button, Container, Row, Col, Card, Alert, Modal } from "react-bootstrap";
import { authenticationService, cadastroService, noticiasRegionaisService } from "../../_services";
import { handleAuthResponse, parseDateISO, formatDate } from '../../_helpers';
import { NavLink, useNavigate } from "react-router-dom";
import { addDays } from 'date-fns';

export function AreaAssociado() {
    const [associado, setAssociado] = React.useState(null);
    const [showModal, setShowModal] = React.useState(false);
    const [noticias, setNoticias] = React.useState(null);
    const navigate = useNavigate();

    const getNoticias = useCallback ((u) => {  
        noticiasRegionaisService.listByRegional(u.uf).then(r => handleAuthResponse(r, navigate)).then(res => {
            setNoticias(res);
        }); 
    }, [navigate]);

    const init = useCallback(() => {
        const currentUser = authenticationService.loggedUser;
        if (currentUser) {
            cadastroService.getById(currentUser.id).then(r => handleAuthResponse(r, navigate)).then(u => {
                setAssociado(u); 
                getNoticias(u);

                if (!sessionStorage.getItem('firstLogin')) {
                    setShowModal(true);
                    sessionStorage.setItem('firstLogin', 1);
                }  
            }).catch(e => {
                authenticationService.logout();
                navigate('/associados/login');
            });
        } else {
            authenticationService.logout();
            navigate('/associados/login');
        }
    }, [navigate, getNoticias]);
   
    React.useEffect(function () {
        init();
    }, [init]);

    const msg = function () {
        const hoje = new Date();
        const limite = addDays(hoje, 31);
        const val = parseDateISO(associado.validade);
        if (val <= limite) {
            if (val < hoje) {
                return (<>Sua anuidade venceu em <b>{formatDate(associado.validade)}.</b> <br />
                    Por favor clique no link de pagamento abaixo para regularização.</>);
            } else if (val === hoje) {
                return (<>Sua anuidade vence hoje. <br /> Por favor clique no link de pagamento abaixo para regularização.</>);
            } else {
                return (<>Sua anuidade vence em <b>{formatDate(associado.validade)}.</b> <br />
                    Por favor clique no link de pagamento abaixo para regularização.</>);
            }
        }
    }

    return (associado && <>
        <div className="register-body">
            <div className="register-wrapper" style={{ width: '100%' }}>
                <Container className="vertical-center">
                    <Row>
                        <Col className="ml-auto mr-auto" lg="12">
                            <Card className="card-register">
                                <Card.Header style={{ color: 'black' }}>
                                    <Card.Title as="h2" className="text-warning">Área do Associado</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col className="pr-1" md={6}>
                                            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={showModal}>
                                                <Modal.Header>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Alert show variant="success">
                                                        <p>
                                                            <b> Olá, {associado.nome}, seja {associado.sexo === 1 ? <>bem-vindo!!!</> : <>bem-vinda!!!</>} </b>
                                                        </p>
                                                        <p>
                                                            {msg()}
                                                        </p>
                                                    </Alert>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button className="btn-fill btn-primary" variant="primary" onClick={() => setShowModal(false)}>OK</Button>
                                                </Modal.Footer>
                                            </Modal>
                                            <p>
                                                Usuário: {associado.nome} <br />
                                                Válido até: {formatDate(associado.validade)}
                                            </p>
                                            <hr />

                                            <br />
                                            <p>
                                                <NavLink className="p-1 info" to={'/associados/atendimento/'} >
                                                    Atendimento ao Associado
                                                </NavLink>
                                            </p>
                                            <p>
                                                <NavLink className="p-1 info" to={'/associados/regras/'} >
                                                    Regras do Associado
                                                </NavLink>

                                            </p>
                                            <p>
                                                <NavLink className="p-1 info" to={`/associados/novo/${associado.idCadastro}`} >
                                                    Atualizar Meu Cadastro
                                                </NavLink>
                                            </p>
                                            <p>
                                                <NavLink className="p-1 info" to={`/associados/trocar`} >
                                                    Trocar Minha Senha
                                                </NavLink>
                                            </p>
                                            <p>
                                                <NavLink className="p-1 info" to={`/associados/renovar`} >
                                                    Pagar Anuidade
                                                </NavLink>
                                            </p>
                                            <p>
                                                <NavLink className="p-1 info" to={`/associados/historico`} >
                                                    Histórico de Pagamentos
                                                </NavLink>
                                            </p>
                                            <br />
                                            <hr />
                                            <p>
                                                <a className="p-1 warning" href="/inicial" onClick={authenticationService.logout} >
                                                    <span className="no-icon">Encerrar minha sessão</span>
                                                </a>
                                            </p>
                                        </Col>
                                        <Col className="pr-1" md={6}>
                                            <p>
                                            </p>
                                            <br />
                                            <br /> 
                                            {noticias && (noticias.length > 0)  &&
                                                <Alert variant="light">  
                                                <p style={{marginBottom: '0rem', marginTop: '0.5rem'}}></p>
                                                     {
                                                        noticias.map(e => (
                                                            
                                                            <p key={e.id}>
                                                                <NavLink  className="p-1" style={{ color: '#FF9500' }} to={'/associados/noticia/' + e.id} >
                                                                    {e.titulo}
                                                                </NavLink>
                                                            </p>
                                                        ))
                                                    } 
                                                </Alert>
                                            }
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    </>
    );
}