import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "bootstrap/scss/bootstrap.scss";
import "assets/css/css.css";
import "assets/css/pe-icon-7-stroke.css";
import 'assets/css/fontawesome.min.css';

import { RequireAuth } from "./components/ProtectedRoute";
import { Topbar } from 'views/inicial/Topbar';
import { Navbar } from 'views/inicial/Navbar';
import { Footer } from 'views/inicial/Footer';
import { Inicial } from "views/inicial/Inicial.js";
import { NovoAssociadoForm } from 'views/associado/Criar.js'
import { AssociadoLoginForm } from 'views/associado/Login.js'
import { AreaAssociado } from 'views/associado/Area.js'
import { AtendimentoAssociado } from 'views/associado/Atendimento.js'
import { RegrasAssociado } from 'views/associado/Regras.js'
import { TrocarSenhaForm } from 'views/associado/TrocarSenha.js'
import { PagarAnuidade } from "./views/associado/PagarAnuidade";
import { HistoricoPagamentos } from "./views/associado/HistoricoPagamentos";
import { VerificarInscricaoForm } from "./views/associado/cursos-regionais/VerificarInscricao";
import { AssociadoCertificados } from "./views/associado/cursos-regionais/Certificados";
import { RecuperarSenhaForm } from "./views/associado/RecuperarSenha";
import { NoticiaRegional } from "./views/associado/NoticiaRegional";

import AdminLayout from "layouts/Admin"; 

const root = ReactDOM.createRoot(document.getElementById('root'));

function AssociadoLayout() {
  return (
    <>
      <Topbar />
      <Navbar />
      <div>
        <Routes>
          <Route path="/novo" element={<NovoAssociadoForm />} />
          <Route path="/area" element={<AreaAssociado />} />
          <Route path="/atendimento" element={<AtendimentoAssociado />} />
          <Route path="/regras" element={<RegrasAssociado />} />
          <Route path="/novo/:id" element={<NovoAssociadoForm />} />
          <Route path="/trocar" element={<TrocarSenhaForm />} />
          <Route path="/renovar" element={<PagarAnuidade />} />
          <Route path="/historico" element={<HistoricoPagamentos />} />
          <Route path="/noticia/:id" element={<NoticiaRegional />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

const wrapInicialNavFooter = function (item) {
  return (
    <>
      <Topbar />
      <Navbar />
      {item}
      <Footer />
    </>
  );
}

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Inicial />} />
      <Route path="/inicial" element={<Inicial />} /> 
      <Route path="/associados/novo" element={wrapInicialNavFooter(<NovoAssociadoForm />)} />
      <Route path="/associados/login" element={wrapInicialNavFooter(<AssociadoLoginForm />)} />
      <Route path="/associados/recuperar-senha" element={wrapInicialNavFooter(<RecuperarSenhaForm />)} />
      <Route path="/inicial/curso/" element={wrapInicialNavFooter(<VerificarInscricaoForm />)} /> 
      <Route path="/associados/certificados" element={wrapInicialNavFooter(<AssociadoCertificados />)} />

      <Route path="/admin/*" element={
        <RequireAuth nivelAcesso={3}>
          <AdminLayout />
        </RequireAuth>
      } />
 
      <Route path="/associados/*" element={
        <RequireAuth>
          <AssociadoLayout />
        </RequireAuth>
      } />
 
    </Routes>
  </BrowserRouter >
);