import React from "react";
import { Button, Card, Table, Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { relatoriosService } from "../../../_services";
import toastr from 'toastr';
import { formatMoney } from "../../../_helpers";

export default function RelatorioFinanceiro() {
  const [items, setItems] = React.useState([]);

  React.useEffect(function () {
    relatoriosService.financeiro().then(res => res.json()).then((res) => {
      setItems(res);
    }).catch((ex) => {
      toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
    });
  }, []);


  function exportarExcel(reqFilters) {
    alert(':)');
  }

  return (<>
    <Container fluid>
      <Row>
        <Col md="6">
          <Card className="">
            <Card.Header>
              <Card.Title as="h4">Relatório Financeiro</Card.Title> 
            </Card.Header>
            <Card.Body >
              <Table responsive className="table-hover table-striped table-small">
                <thead>
                  <tr>
                    <th className="border-0">Regional</th>
                    <th className="border-0">Anuidades</th>
                    <th className="border-0">Inscrições</th>
                    <th className="border-0">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    items.length > 0 &&
                    items.map((e, i) => (
                      <tr key={e.id}>
                        <td>
                          {String(e.regional).toUpperCase()}
                        </td>
                        <td>
                          {formatMoney(e.valorAnuidades)}
                        </td>
                        <td>
                          {formatMoney(e.valorCursos)}
                        </td>
                        <td>
                          {formatMoney(e.total)}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </>
  );
}