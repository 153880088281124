import React from 'react'; 
import { Button, Container, Row, Col, Card, InputGroup, Form, Alert, Spinner } from "react-bootstrap";  
import { cadastroService } from '../../_services';
import { handleResponse } from '../../_helpers';
import MaskedInput from 'react-text-mask';
import { validCPF, validEmail } from '../../prototypes.js';
import toastr from 'toastr';

export function RecuperarSenhaForm() {
    const [msgErro, setMsgErro] = React.useState(undefined);
    const [loading, setLoading] = React.useState(false);
    const [usuario, setUsuario] = React.useState({
        email: '',
        cpf: '',
    }); 

    React.useEffect(function () {

    }, []);

    const handleChange = function (event) {
        const value = event.target.value;
        var name = event.target.name;
        setUsuario((state) => {
            return state = { ...usuario, [name]: value }
        });
    }

    const handleChangeMaskOnlyNumbers = function (event) {
		const value = event.target.value.replace(/[^\d]/g, '');;
		var name = event.target.name;
        setUsuario((state) => {
            return state = { ...usuario, [name]: value }
        });
	}

    const handleSubmit = function (event) {
        event.preventDefault();

        var form = document.getElementById('myForm');
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            form.classList.add('was-validated');
            return;
        }

		// email
        if (!validEmail(usuario.email)) {
			setMsgErro ('Informe corretamente o E-Mail!');
            return;
		}

        // Valida o CPF
		if (!validCPF(usuario.cpf)) {
            setMsgErro("Informe corretamente o CPF!");
            return;
		}

        setLoading(true);
        setMsgErro(undefined);

        cadastroService.recuperarSenha (JSON.stringify(usuario)).then(function (res) { 
            if (res.ok) {
                toastr.success("Seus dados para acesso foram enviados para o email " + usuario.email + ".");
                setLoading(false);
                setMsgErro(undefined);
            } else if (res.status === 404) {
                toastr.warning("Seu cadastro não foi localizado.");
                setLoading(false);
            } else {
                return Promise.reject();
            }
        }).catch(function () { 
            setLoading(false);
            setMsgErro('Ocorreu um erro inesperado. Por favor tente novamente mais tarde.');
        });
    }
    return (
        <>
            <div className="register-page main" style={{ backgroundImage: "url(" + require("assets/img/login-bg.jpg") + ")" }} >
                <Container className="container-left">
                    <Row>
                        <Col style={{ marginTop: '160px' }}>
                            <div className="login-left">
                                <Card className="card-register">
                                    <div style={{ padding: '0px 0px 70px 60px' }}>
                                        <div className="col-sm-12">
                                            <img alt="" src={require("assets/img/sbrafh/logo.png")} border="0" style={{ width: '40%' }} />
                                            <div className="title" style={{ marginTop: '0px' }}>
                                                <h4 style={{ marginTop: '0px' }} className="text-success">Recuperar Senha</h4>
                                                <div className="row">
                                                    <div className="col-10 text-secondary">
                                                    <small>Informe abaixo para qual e-mail devem ser enviados os dados 
                                                    para acesso e seu CPF para que possamos localizar seu cadastro em nosso sistema.</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <br/>
                                        </div>
                                        <Row>
                                            <Col sm={10}>
                                                <Form id="myForm" name="myForm" method="POST" noValidate onSubmit={handleSubmit}>

                                                    <Alert show={msgErro !== undefined} variant="danger" dismissible onClose={() => setMsgErro(undefined)}>
                                                        <span><b> {msgErro} </b></span>
                                                    </Alert>
                                                    <Row>
                                                        <Col md={12}>


                                                            <label>E-mail: </label>
                                                            <InputGroup className="form-group-no-border">
                                                                <InputGroup.Text>
                                                                    <i className="nc-icon nc-email-85" />
                                                                </InputGroup.Text>
                                                                <Form.Control placeholder="E-mail" className="form-control" type="email" name="email" id="email" maxLength="80" required
                                                                    value={usuario.email} onChange={handleChange} />
                                                                <div className="invalid-feedback">
                                                                    Por favor insira um e-mail válido!
                                                                </div>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={12}>

                                                            <label>CPF: </label>
                                                            <InputGroup className="form-group-no-border">
                                                                <InputGroup.Text>
                                                                    <i className="nc-icon nc-credit-card" />
                                                                </InputGroup.Text>
                                                                <MaskedInput id="cpf" placeholder="CPF" name="cpf" mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]} className="form-control numeric"
                                                                    required value={usuario.cpf} onChange={handleChangeMaskOnlyNumbers}
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                />
                                                                <div className="invalid-feedback">
                                                                    Por favor informe o CPF!
                                                                </div>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <hr />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Button id="btRec" name="btRec" className="btn-block" variant="primary" type="submit">
                                                                Recuperar Senha
                                                                {loading &&
                                                                    <> &nbsp;
                                                                        <Spinner animation="border" role="status" size="sm">
                                                                            <span className="visually-hidden">Carregando...</span>
                                                                        </Spinner> </>}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}