import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { authenticationService, cadastroService } from '../_services';
import { handleAuthResponse } from '../_helpers';

export function RequireAuth({ nivelAcesso, children }) {
    const [result, setResult] = React.useState(null);

    React.useEffect(function () {
        const currentUser = authenticationService.loggedUser;
 
        if (currentUser && nivelAcesso) {
            cadastroService.getById(currentUser.id).then(handleAuthResponse).then(u => {
                if (u.nivelAcesso < nivelAcesso) {
                    setResult(<Navigate to="/associados/login" />);
                } else {
                    setResult(children);
                }
            });
        } else if (!currentUser) {
            setResult(<Navigate to="/associados/login" />);
        } else if (currentUser) {
            setResult(children);
        }
    }, []); 
    return (result);
}